import './Home.css'; 
import { Chart, registerables } from 'chart.js';
import VendorQuoteList from "../VendorPages/VendorQuoteList";
import Card from "@mui/material/Card";
import { Box, Divider, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { MailOutlined, } from "@mui/icons-material";
Chart.register(...registerables);

const Home = () => {

    return (
        <div>
            <Stack spacing={3.0} direction={"row"} justifyContent={"center"} padding={5}>
                <Card sx={{ width: '250px', cursor: 'pointer' }}>
                    <Box sx={{ p: 2, display: 'flex' }}>
                        <Stack spacing={0.5} style={{ flexGrow: 1 }}>
                            <Stack direction={"row"} style={{ justifyContent: "space-between" }}>
                                <Typography fontWeight={700} fontSize={16}>New Request</Typography>
                                <IconButton style={{ color: 'blue' }} onClick={() => { }}>
                                    <MailOutlined />
                                </IconButton>
                            </Stack>
                            <Typography fontWeight={700} fontSize={"42px"}>
                                0
                            </Typography>
                            <Stack spacing={1.5} direction={"row"}>
                                <Typography fontSize={12} color={"454F5B"} >0  </Typography>
                                <Typography fontWeight={700} fontSize={"12px"} variant="body2" color="text.secondary"> Today </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Divider />
                </Card>
                <Card sx={{ width: '250px', cursor: 'pointer' }}>
                    <Box sx={{ p: 2, display: 'flex' }}>
                        <Stack spacing={0.5} style={{ flexGrow: 1 }}>
                            <Stack direction={"row"} style={{ justifyContent: "space-between" }}>
                                <Typography fontWeight={700} fontSize={16}>Pending</Typography>
                                <IconButton style={{ color: 'orange' }} onClick={() => { }}>
                                    <MailOutlined />
                                </IconButton>
                            </Stack>
                            <Typography fontWeight={700} fontSize={"42px"}>
                                0
                            </Typography>
                            <Stack spacing={1.5} direction={"row"}>
                                <Typography fontSize={12} >0  </Typography>
                                <Typography fontWeight={700} fontSize={"12px"} variant="body2" color="text.secondary">Milestone Today </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Divider />
                </Card>
                <Card sx={{ width: '250px', cursor: 'pointer' }}>
                    <Box sx={{ p: 2, display: 'flex' }}>
                        <Stack spacing={0.5} style={{ flexGrow: 1 }}>
                            <Stack direction={"row"} style={{ justifyContent: "space-between" }}>
                                <Typography fontWeight={700} fontSize={16}>Completed</Typography>
                                <IconButton style={{ color: 'green' }} onClick={() => { }}>
                                    <MailOutlined />
                                </IconButton>
                            </Stack>
                            <Typography fontWeight={700} fontSize={"42px"}>
                                0
                            </Typography>
                            <Stack spacing={1.5} direction={"row"}>
                                <Typography fontSize={12} >0  </Typography>
                                <Typography fontWeight={700} fontSize={"12px"} variant="body2" color="text.secondary"> Payment Pending </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Divider />
                </Card>
            </Stack>
            <VendorQuoteList />
        </div>
    )
}

export default Home;
