export const vendorEndPoint = {
    GET_ALL: '/api/vendor/all',
    CREATE_REQUEST: '/api/vendor/requestQuote',
    GET_QUOTE_LIST: '/api/vendor/quotes',
    REJECT_QUOTE: '/api/vendor/rejectQuote',
    ACCEPT_QUOTE: '/api/vendor/acceptQuote',
    MY_CLIENT_LIST: '/api/client/find',
    GET_PENDING_QUOTE: '/api/vendor/pendingQuotes',
    ACCEPT_TIMELINE: '/api/vendor/acceptTimeline',
    SEND_TIMELINE: '/api/vendor/sendTimeline',
    DETAILS: '/api/vendor/details',
    UPDATE: '/api/vendor/update',
    FIND_VENDOR: '/api/vendor/findVendor',
    GET_DETAIL_BY_USER: '/api/quote/getDetailByUser',
    GET_SKILLS_LIST: '/api/skills/all',
    DOCUMENTS: "/api/project/documents"

};
