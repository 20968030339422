import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Stack, TextField } from '@mui/material';
import AppToggle from "../AppToggle";

const style = {
    top: 100,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const contentStyle = {
    width: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 4,
    padding: '30px',
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
};

interface MedicalReportUpsertComponentProps {
    value: {
        id? : number,
        enabled: true,
        companyName: string,
        date: string,
        designation: string,
        ifAmbulanceService: string,
        emergencyContact: boolean,
        employeeReport: string,
        ifEmergencyContact: string,
        ifMedicalTeamActions: string,
        ambulanceService: boolean,
        isSick: boolean,
        name: string,
        preCautions: string,
        medicalTeamActions: boolean,
    }
    onSubmit: () => void;

    onChangeCompanyName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangePrecautions: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeName:(event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeIfMedicalTeamActions:(event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeIfEmergencyContact: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeDesignation: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeIfAmbulanceService: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeEmployeeReport:(event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeMedicalTeamActionsToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeAmbulanceServiceToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeEmergencyContactToggle:(event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeSickToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MedicalReportUpsertComponent = ({  value,  onChangeMedicalTeamActionsToggle, onChangeAmbulanceServiceToggle,
                                         onChangeEmergencyContactToggle, onChangeSickToggle, onSubmit,onChangeName,
                                         onChangePrecautions, onChangeCompanyName, onChangeDate, onChangeDesignation,
                                         onChangeEmployeeReport, onChangeIfEmergencyContact, onChangeIfMedicalTeamActions,
                                         onChangeIfAmbulanceService }: MedicalReportUpsertComponentProps) => {
    return (
        <> <Box sx={style}>
                    <Box sx={contentStyle}>

                        <Typography variant="h6" component="h2">
                            {value.id != null ? 'Update Medical Report' : 'Create Medical Report'}
                        </Typography>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
                            <span style={{marginRight: '10px'}}>Ambulance Service</span>
                            <AppToggle handleToggleChange={onChangeAmbulanceServiceToggle}
                                                   isChecked={value.ambulanceService}
                                                   toggleValue={value.ambulanceService}></AppToggle>
                        </div>
                        <TextField multiline={false} minRows={"1"} placeholder="Company Name" name="companyName"
                                   value={value.companyName} onChange={onChangeCompanyName}/>
                        <TextField multiline={false} minRows={"1"} placeholder="Date" name="date" value={value.date}
                                   onChange={onChangeDate}/>
                        <TextField multiline={false} minRows={"1"} placeholder="Designation" name="designation"
                                   value={value.designation} onChange={onChangeDesignation}/>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
                            <span style={{marginRight: '10px'}}>Emergency Contact</span>
                            <AppToggle handleToggleChange={onChangeEmergencyContactToggle} isChecked={value.emergencyContact}
                                                   toggleValue={value.emergencyContact}></AppToggle>
                        </div>
                        <TextField multiline={false} minRows={"1"} placeholder="Employee Report" name="employeeReport"
                                   value={value.employeeReport} onChange={onChangeEmployeeReport}/>
                        <TextField multiline={false} minRows={"1"} placeholder="Ambulance Service Comments"
                                   name="ifAmbulanceService" value={value.ifAmbulanceService}
                                   onChange={onChangeIfAmbulanceService}/>
                        <TextField multiline={false} minRows={"1"} placeholder="Emergency Contact Comments"
                                   name="ifEmergencyContact" value={value.ifEmergencyContact}
                                   onChange={onChangeIfEmergencyContact}/>
                        <TextField multiline={false} minRows={"1"} placeholder="Medical Team Actions Comments"
                                   name="ifMedicalTeamActions" value={value.ifMedicalTeamActions}
                                   onChange={onChangeIfMedicalTeamActions}/>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
                            <span style={{marginRight: '10px'}}>Sick</span>
                            <AppToggle handleToggleChange={onChangeSickToggle} isChecked={value.isSick}
                                                   toggleValue={value.isSick}></AppToggle>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
                            <span style={{marginRight: '10px'}}>Medical Team Actions</span>
                            <AppToggle  handleToggleChange={onChangeMedicalTeamActionsToggle}
                                                      isChecked={value.medicalTeamActions}
                                                      toggleValue={value.medicalTeamActions}></AppToggle>
                        </div>
                        <TextField multiline={false} minRows={"1"} placeholder="Name" name="name" value={value.name}
                                   onChange={onChangeName}/>
                        <TextField multiline={false} minRows={"1"} placeholder="Precautions" name="preCautions"
                                   value={value.preCautions} onChange={onChangePrecautions}/>
                        <Stack direction="row" spacing={4}>
                            <Button style={{backgroundColor: "#3361FF"}} variant="contained"
                                    onClick={onSubmit}>
                                {value.id != null ? 'Update' : 'Create'}
                            </Button>
                        </Stack>
                    </Box>
                </Box>
        </>
    );
};

export default MedicalReportUpsertComponent;