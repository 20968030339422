import React, {useEffect, useState} from 'react';
import {Box, Button, ButtonGroup} from "@mui/material";
import AppTable from "../../../components/Table/index";
import {useClient} from "../../../hooks/useClient";
import {STORAGE_KEY} from "../../../constants";
import Grid from "@mui/material/Grid";
import AddVendorModal from "../../../components/AddVendorModal";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";


interface AddVendorProps {
    address: string;
    enabled: boolean;
    email: string;
    gstNo: string;
    name: string;
    password: string;
    phoneNumber: string;
    username: string;
}

const ClientVendorList = () => {

    const {getClientDetails , clientDetails,createVendor} = useClient();
    const [open, setOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();


    const [value, setValue] = useState<AddVendorProps>({
        address: '',
        email: '',
        enabled: true,
        gstNo: '',
        name: '',
        password: '',
        phoneNumber: '',
        username: '',
    });


    useEffect(() => {
        try {
            const clientId = localStorage.getItem(STORAGE_KEY.UserId);

            getClientDetails({
                clientId: clientId ?? '',
            });
            setOpen(false);
        } catch (error) {
            setOpen(false);
        }
    }, []);

    const onClickCreateVendor = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const onChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            address: event.target.value,
        });
    };
    const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            email: event.target.value,
        });
    };
    const onChangeGstNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            gstNo: event.target.value,
        });
    };
    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            name: event.target.value,
        });
    };
    const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            password: event.target.value,
        });
    };
    const onChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            phoneNumber: event.target.value,
        });
    };
    const onChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            username: event.target.value,
        });
    };

    const onClickConfirm = async () => {
        if(value.password === '' && value.username === '' && value.email === '' && value.phoneNumber === ''){
            enqueueSnackbar("Please enter mandatory field", { variant: 'error',autoHideDuration: 3000 } )
            return true;
        } else if(value.password.length < 8){
            enqueueSnackbar("Password Need at least 8 characters", { variant: 'error',autoHideDuration: 3000 } )
            return true;

        }else if(value.username.length < 5){
            enqueueSnackbar("Username Need at least 5 characters", { variant: 'error',autoHideDuration: 3000 } )
            return true;
        }else {
            try {
                await createVendor({
                    name : value.name,
                    address: value.address,
                    email: value.email,
                    enabled: true,
                    gstNo: value.gstNo,
                    password: value.password,
                    phoneNumber: value.phoneNumber,
                    username: value.username
                }).then(r => {
                    console.log(r);
                    if(r?.response && r?.response?.status === 400){
                        enqueueSnackbar(r?.response?.data, { variant: 'error',autoHideDuration: 3000 } )
                    }else {
                        enqueueSnackbar(r.message, { variant: 'success',autoHideDuration: 3000 } )
                    }
                    window.history.back()
                });
                setOpen(false);
            } catch (e : any) {
                console.log(e)
                setOpen(false);
            }
        }
    };

    return (

        <div>
            <Grid container justifyContent="flex-end" spacing={4}>
                <Grid item>
                    <ButtonGroup variant="text" aria-label="text button group"  sx={{padding: 1}}>
                        <div >
                            <Button style={{ backgroundColor : "#3361FF"}} variant={'text'} onClick={onClickCreateVendor} >
                                Create Vendor
                            </Button>
                        </div>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <AddVendorModal
                    open={ open }
                    value={ value }
                    handleClose={ onCloseModal }
                    onClickPrimary={ onClickConfirm }
                    onChangeAddress={onChangeAddress}
                    onChangeEmail={onChangeEmail}
                    onChangeGstNo={onChangeGstNo}
                    onChangeName={onChangeName}
                    onChangePassword={onChangePassword}
                    onChangePhoneNumber={onChangePhoneNumber}
                    onChangeUserName={onChangeUserName}/>
            </Box>
            <Box sx={{ width: '100%' }}>
    <AppTable listData={clientDetails} hyperlinkPropertyName={'id'} entity={'vendor'} ></AppTable>
        </Box>    </div>

)
}

export default ClientVendorList;
