import React, {useEffect, useState} from 'react';
import {Box, Button, ButtonGroup, Stack, TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useLocation, useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useClient} from "../../hooks/useClient";
import {STORAGE_KEY} from "../../constants";
import MessageSendPopup from "../../components/MessageSendPopup";
import AddQuestionnaireModal from "../../components/AddQuestionnaireModal";
import AppTable from "../../components/Table";


const style = {
    position: 'absolute' as 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    justifyContent: 'center',
};


interface AddQuestionnaireRequestProps {
    question: string,
    status: any,
    enabled: true,
    employeeId: string
}

const QuestionnaireDetails = () => {
    const {getQuestionnaireList,getQuestionnaireDetail,questionnaireList,sendMessageList,messageList, createEmployee ,roleDropdownValues, setRoleDropdownValues, getRoleDropDownValues} = useClient();
    const [open, setOpen] = useState<boolean>(false);
    const location = useLocation();
    const [showPopup, setShowPopup] = useState(false);
    const [test, setTest] = useState('');
    const questionnaireDetails: any = location?.state?.data || {};


    const [value, setValue] = useState<AddQuestionnaireRequestProps>({

        status: undefined,
        enabled: true,
        question: '',
        employeeId: ''
    });
    const handleSendClick = () => {
        setShowPopup(true);
    };
    console.log('test', test);


    const onCloseModal = () => {
        setOpen(false);
        window.history.back();
    };



    useEffect(() => {
        setTest('testing');
        if (questionnaireDetails) {
            setValue({
                ...value,
               employeeId: value.employeeId,
               question: value.question
            });
        }
    }, [questionnaireDetails]);


    useEffect(() => {
        try {
            const id = localStorage.getItem(STORAGE_KEY.UserId);

            getQuestionnaireList();
            messageList(questionnaireDetails.data.id);
            getQuestionnaireDetail(questionnaireDetails.data.id);
            console.log(messageList(questionnaireDetails.data.id));
            console.log("messageList(questionnaireDetails.data.id)");
            setOpen(false);
        } catch (error) {
            setOpen(false);
        }
    }, []);


    // @ts-ignore
    return (
    <div>
        <Grid container justifyContent="flex-end" spacing={4}>
            <Grid item>
                <ButtonGroup variant="text" aria-label="text button group" sx={{padding: 1}}>
                    <div>
                        <div>
                            <button
                                style={{
                                    backgroundColor: "#3361FF",
                                    color: "#fff",
                                    padding: "10px 20px",
                                    margin: "10px 0", // Adjust top and bottom margin as needed
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                                onClick={handleSendClick}
                            >
                                Send  questionnaire
                            </button>
                            {showPopup && <MessageSendPopup onClose={() => setShowPopup(false)}/>}
                        </div>
                    </div>
                </ButtonGroup>
            </Grid>
        </Grid>
        <Box sx={{width: '100%'}}>
            <AppTable listData={sendMessageList} hyperlinkPropertyName={''}
                      entity={'messageList'}></AppTable>
        </Box>
    </div>
)
}

export default QuestionnaireDetails;
