import { on } from 'events';
import React, { createContext, useContext, useState, useMemo } from 'react';

export interface UserProps {
  id: string;
  name: string;
  email: string;
  userName: string;
  role: string;
}

export interface ContextType {
  userData: UserProps;
  setUserData(f: UserProps | ((prev: UserProps) => UserProps)): void;
  onboardingClient: any,
  setOnboardingClient(f: any | ((prev: any) => any)): void;
}

interface UserProviderProps {
  children: React.ReactNode;
}

const UserContext = createContext<ContextType>({} as ContextType);

export default UserContext;

export function useUser() {
  return useContext(UserContext);
}

export const UserProvider = ({
  children,
}: UserProviderProps) => {
  const [userData, setUserData] = useState<UserProps>({
    id: '',
    name: '',
    email: '',
    userName: '',
    role: 'ROLE_VENDOR',
  });

  const [onboardingClient, setOnboardingClient] = useState<string>({} as any);

  const contextValue = useMemo(() => (
    { userData, setUserData, onboardingClient, setOnboardingClient}
  ), [userData, setUserData, onboardingClient, setOnboardingClient]);

  return (
    <UserContext.Provider value={ contextValue }>
      { children }
    </UserContext.Provider>
  );
};
