import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routers from './routers';
import { AppLayout } from './layouts';
import Provider from './provider';

function App() {
  return (
    <div>
      <Router>
        <Provider>
          <AppLayout>
            <Routers />
          </AppLayout>
        </Provider>
      </Router>
    </div>
  );
}

export default App;
