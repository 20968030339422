import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { useLocation } from 'react-router-dom';
import AppTable from "../Table";
import {CardContainer, Tab, TabPanel} from "../index";
import {Grid, Typography} from "@mui/material";
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import {LinkedFunction} from "../../constants/LinkedFunction";
import {metData} from "../../constants/meta";

interface FieldProps {
    detailData? :any;
    enabledEdit?: boolean;
    loading?: boolean;
    onSave? : any;
    entity? :any;

    skipChildren?: string[]
    skipFields?: string[]
}

const DetailView: React.FC<FieldProps> = ( {detailData,enabledEdit,onSave, loading, entity,skipChildren,skipFields}) => {
    const location = useLocation();
    const [fieldValue, setFieldValue] = useState<any | {}>({});
    const [nonPrimitive, setNonPrimitive] = useState<any | {}>({});
    const [tabList, setTabList] = useState<any | {}>();
    const [tabValue, setTabValue] = React.useState<number>(0);
    const [actionButtons, setActionButtons] = React.useState<any>(null);

    useEffect(() => {

            let data = {};
            if (detailData) {
                data = detailData;
            }
            if (location.state != null) {
                data = location.state.data.data;
                setActionButtons(location.state.data.actionButtons);
            }
            setFieldValue(sortPrimitiveFields(data));
            let noPrimitiveData = nonPrimitiveFields(data);
            setNonPrimitive(noPrimitiveData);
            setTabList(Object.keys(noPrimitiveData).filter((ch) => (skipChildren == null || skipChildren.indexOf(ch) === -1)).map((key) => splitCamelCaseAndUppercase(key)))

    }, [detailData]);

    const onChangeTab = (event: React.SyntheticEvent, index: number) => {
        setTabValue(index);
    };



    const renderField = (fieldKey:any, fieldValue: any): JSX.Element => {
        if(entity != null) {
            let metaData = metData[entity];
            let visibleKeys = metaData.filter((f:any) =>Object.keys(fieldValue).filter((m: any) => f.name === m).length > 0);
            if(skipFields != null) {
                console.log(skipFields)
                visibleKeys = visibleKeys.filter((f:any) => skipFields?.indexOf(f.name) === -1);
            }
           return (<Grid container spacing={1}>
                {visibleKeys.map((meta:any) => (
                    <Grid  key={ meta.name } item xs={ 2 } sm={ 2 } lg={ 2 } xl={ 2 }>
                        <TextField
                            label={meta.placeholder}
                            value={fieldValue[meta.name]}
                            onChange={(event => {
                                fieldValue[meta.name] = event.target.value;
                                setFieldValue({...fieldValue});
                            })}
                            InputProps={{ readOnly: !enabledEdit || meta.name === "id"  }}
                            variant={'filled'}
                        />
                    </Grid>
                ))}
            </Grid>)
        }
            return (
                    <Grid container spacing={1}>
                    {Object.keys(fieldValue).filter((f:string) => skipFields == null ||  skipFields.indexOf(f) == -1 ).map((fieldName) => (
                        <Grid  key={ fieldName } item xs={ 2 } sm={ 2 } lg={ 2 } xl={ 2 }>
                            <TextField
                                label={fieldName}
                                value={fieldValue[fieldName]}
                                onChange={(event => {
                                    fieldValue[fieldName] = event.target.value;
                                    setFieldValue({...fieldValue});
                                })}
                                InputProps={{ readOnly: !enabledEdit || fieldName === "id"  }}
                                variant={'filled'}
                            />
                        </Grid>
                    ))}
                    </Grid>

            );
    };
    const renderTabPanel = (fieldKey:any, fieldValue: any): JSX.Element => {
        let value =  nonPrimitive[toCamelCase(tabList[tabValue])];
        if(!Array.isArray(value)) {
            value = [value];
        }
        let entity = toCamelCase(tabList[tabValue]);
       return ( <TabPanel value={ tabValue } index={ tabValue }>
            <AppTable listData={(value as any)} hyperlinkPropertyName="id" entity={entity} enabledEdit={enabledEdit} onUpdate={(val:any, index: any) => value[index] = val}/>
        </TabPanel>)
    };


    const onSaveClick = (data:any) => {
        if(onSave != null) {
            onSave({...fieldValue, ...nonPrimitive});
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' ,padding: '10px', float: 'right'}}>
                <div>
                    {actionButtons && LinkedFunction[actionButtons] ? LinkedFunction[actionButtons](fieldValue) : (<div></div>)}
                    {enabledEdit ?  (<div>
                        <Button style={{ backgroundColor : "#3361FF"}} onClick={onSaveClick} disabled={loading}>
                            {loading ? 'Loading...' : 'Save'}
                        </Button>
                    </div>) : (<div></div>)}
                </div>

                {/* You can style the buttons container as needed */}
            </div>
            { Object.keys(fieldValue).length > 0 ? (
                renderField(null, fieldValue)
            ) : (
                <Typography>No data Found</Typography>
            ) }
            <Tab data={ tabList } value={ tabValue } onChangeTab={ onChangeTab } />
            { Object.keys(nonPrimitive).length > 0 ? (
                renderTabPanel(null, nonPrimitive)
                ) : (
                <Typography>No data Found</Typography>
            ) }
        </div>
    );
};

export default DetailView;


function sortPrimitiveFields(jsonStr: any): any {
    // Step 1: Parse the JSON object
    const data: { [key: string]: any } = jsonStr;

    // Step 2: Separate primitive and non-primitive fields
    const primitiveFields: { [key: string]: any } = {};
    const nonPrimitiveFields: { [key: string]: any } = {};
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            if (typeof data[key] === 'string' || typeof data[key] === 'number' || typeof data[key] === 'boolean' || data[key] === null) {
                primitiveFields[key] = data[key];
            } else {
                nonPrimitiveFields[key] = data[key];
            }
        }
    }

    // Step 3: Sort primitive fields by key
    const sortedPrimitiveFields: { [key: string]: any } = {};
    Object.keys(primitiveFields)
        .sort()
        .forEach((key) => {
            sortedPrimitiveFields[key] = primitiveFields[key];
        });

    // Step 4: Reconstruct the JSON object
    const sortedJsonObject = { ...sortedPrimitiveFields, ...nonPrimitiveFields };

    return sortedPrimitiveFields
}


function nonPrimitiveFields(jsonStr: any): any {
    // Step 1: Parse the JSON object
    const data: { [key: string]: any } = jsonStr;

    // Step 2: Separate primitive and non-primitive fields
    const primitiveFields: { [key: string]: any } = {};
    const nonPrimitiveFields: { [key: string]: any } = {};
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            if (typeof data[key] === 'string' || typeof data[key] === 'number' || typeof data[key] === 'boolean' || data[key] === null) {
                primitiveFields[key] = data[key];
            } else {
                nonPrimitiveFields[key] = data[key];
            }
        }
    }

    // Step 3: Sort primitive fields by key
    const sortedPrimitiveFields: { [key: string]: any } = {};
    Object.keys(primitiveFields)
        .sort()
        .forEach((key) => {
            sortedPrimitiveFields[key] = primitiveFields[key];
        });

    // Step 4: Reconstruct the JSON object
    const sortedJsonObject = { ...sortedPrimitiveFields, ...nonPrimitiveFields };
    return nonPrimitiveFields
}


export function splitCamelCaseAndUppercase(str:any) {
    const words = str.split(/(?=[A-Z])/); // Split based on uppercase letters (camel case)
    const upperCaseWords = words.map((word:any) => word.toUpperCase());
    return upperCaseWords.join(' ');
}

export function toCamelCase(str:any) {
    if(str == null) return  '';
    const words = str.split(' ');
    const camelCaseWords = words.map((word:any, index:number) =>
        index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return camelCaseWords.join('');
}
