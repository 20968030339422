export enum QuoteStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    TIMELINE_SENT = 'TIMELINE_SENT',
    TIMELINE_ACCEPTED = 'TIMELINE_ACCEPTED',
    TIMELINE_REJECTED = 'TIMELINE_REJECTED',
}

export enum UserRole {
    ROLE_CLIENT = 'ROLE_CLIENT',
    ROLE_VENDOR = 'ROLE_VENDOR',
    ROLE_ADMIN = 'ROLE_ADMIN',
    CLIENT_EMPLOYEE = 'CLIENT_EMPLOYEE',
}