import { useCallback } from "react";
import { AxiosInstance } from "axios";
import getApiConfig from "../config/apiConfig";
import { authEndPoint } from "../constants/EndPoint";
import { STORAGE_KEY } from "../constants";
import { useUser } from "../provider/UserProvider";

export interface LoginProps {
  username: string;
  password: string;
}

export interface SendOtp {
  clientId: number;
  mobileNumber: string;
}

export interface VerifyOtp {
  clientId: number;
  mobileNumber: string;
  otp: string;
}
export const useAuth = () => {
  const apiConfig: AxiosInstance = getApiConfig();
  const { userData, onboardingClient, setOnboardingClient, setUserData } =
    useUser();

  const login = useCallback(
    async (payload: LoginProps) => {
      try {
        const response = await apiConfig.post(authEndPoint.LOGIN, payload);
        if (response.data) {
          if (typeof window !== "undefined") {
            return handlePostLogin(response);
          }
        }
      } catch (e) {
        return e;
      }
    },
    [apiConfig, userData, setUserData]
  );

  function handlePostLogin(response: any) {
    if (typeof window !== "undefined") {
      const data = JSON.stringify(response?.data?.access_token);
      localStorage.setItem(STORAGE_KEY.AuthToken, data);
      const role = response?.data?.authorities
        .map((item: any) => item?.authority)
        .join(", ");
      localStorage.setItem(STORAGE_KEY.UserDetails, JSON.stringify(role));
      localStorage.setItem(
        STORAGE_KEY.UserId,
        JSON.stringify(response?.data?.id)
      );
      localStorage.setItem(
        STORAGE_KEY.ClientId,
        JSON.stringify(response?.data?.clientId)
      );
      localStorage.setItem(
        STORAGE_KEY.RoleName,
        JSON.stringify(response?.data?.roleName)
      );
      setUserData({
        ...userData,
        role: role,
      });
      return role;
    }
  }

  const sendOtp = useCallback(
    async (payload: SendOtp) => {
      try {
        const response = await apiConfig.post(authEndPoint.SEND_OTP, payload);
        return response.data;
      } catch (e) {
        return e;
      }
    },
    [apiConfig, userData, setUserData]
  );

  const verifyOtp = useCallback(
    async (payload: VerifyOtp) => {
      try {
        const response = await apiConfig.post(authEndPoint.VERIFY_OTP, payload);
        return handlePostLogin(response);
      } catch (e) {
        return e;
      }
    },
    [apiConfig, userData, setUserData]
  );

  const getClientByOnboardingCode = useCallback(
    async (onboardingCode: string) => {
      try {
        const response = await apiConfig.get(
          authEndPoint.CLINET_BY_ONBOARDING + onboardingCode
        );
        if (response.data) {
          setOnboardingClient(response.data);
          return response.data;
        }
      } catch (e) {
        return e;
      }
    },
    [apiConfig, userData, setUserData]
  );

  return {
    login,
    getClientByOnboardingCode,
    sendOtp,
    verifyOtp,
  };
};
