import React, {useEffect} from 'react';
import {useVendor} from "../../../hooks/useVendor";
import VendorQuotesLayout from '../../../layouts/VendorLayouts/VendorQuoteLayout';

const VendorQuoteList = () => {

    const {vendorQuoteList, fetchVendorQuotesList} = useVendor();

    const acceptRequest = async () => {
    }

    const rejectRequest = async () => {
    }

    useEffect(() => {
        fetchVendorQuotesList();
    }, [fetchVendorQuotesList]);

    return (
        <div>
            <VendorQuotesLayout
                onClickAccept={acceptRequest}
                onClickReject={rejectRequest}
                vendorQuotesList={vendorQuoteList || []}
            />
        </div>

    )
}

export default VendorQuoteList;
