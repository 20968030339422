import React from 'react';
import Box from '@mui/material/Box';
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import {AntSwitch} from 'components/Switch';


const ProjectQuestionnaire = ({questionnaire, questList, onQuestChange, idValueMap}: any) => {

    function calculateValue(expresion:any, id: any){
        try {
            Object.keys(idValueMap)?.map(x=> {
                expresion = expresion.replaceAll(x, idValueMap[x]?? x)
            });
            let value =  eval(expresion);
            idValueMap[id] = value;
            return value;
        }
        catch (e) {
            return expresion;
        }
    }

    function renderActionCompontents(m: any) {

        if (m == null) return (<div></div>);
        if (m.type === "INPUT") {
            return (<TextField  placeholder={m.placeholder} fullWidth={true} variant="outlined" label={m.placeholder}
                               name={m.name} value={questionnaire[m.name]} style={{marginTop: 10}}
                               onChange={(e) => {
                                   questionnaire[m.name] = e.target.value;
                                   idValueMap[m.id] = e.target.value
                                   onQuestChange(questionnaire,idValueMap)
                               }}/>)
        }if (m.type === "Calculated") {
            return (<TextField  placeholder={m.placeholder} fullWidth={true} variant="outlined" label={m.placeholder}
                               name={m.name} value={calculateValue(m.calculated, m.id)} style={{marginTop: 10}}
                               onChange={(e) => {
                                   questionnaire[m.name] = e.target.value;
                                   idValueMap[m.id] = e.target.value
                                   onQuestChange(questionnaire,idValueMap);
                               }}/>)
        }
        if (m.type === "BOOL") {
            return (<div style={{paddingTop: 10}}>
                <Typography>{m.name}</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>{Object.keys(m.decision)[0]}</Typography>
                    <AntSwitch value={questionnaire[m.name]} inputProps={{'aria-label': 'ant design'}}
                               onChange={(e) => {
                                   questionnaire[m.name] = e.target.checked ? Object.keys(m.decision)[0] : Object.keys(m.decision)[1];
                                   idValueMap[m.id] = e.target.value
                                   onQuestChange(questionnaire,idValueMap)
                               }}/>
                    <Typography>{Object.keys(m.decision)[1]}</Typography>
                </Stack>
                <div style={{marginLeft: 10, marginTop: 15}}>
                    {m.decision[questionnaire[m.name]] && m.decision[questionnaire[m.name]][0].decision && Array.isArray(Object.values(m.decision[questionnaire[m.name]][0].decision)) && Object.values(m.decision[questionnaire[m.name]][0].decision)?.map((x: any) => {
                        return renderActionCompontents(x[0])
                    })}
                </div>
            </div>)
        }
        if (m.type === "DROPDOWN") {
            return (<div style={{marginTop: 10}}>
                <FormControl fullWidth style={{width: '400px'}}>
                    <InputLabel id="demo-simple-select-label">{m.placeholder}</InputLabel>
                    <Select
                        fullWidth={true}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={questionnaire[m.name]}
                        label="m.placeHolder"
                        onChange={(e: any) => {
                            questionnaire[m.name] = e.target.value;
                            idValueMap[m.id] = e.target.value
                            onQuestChange(questionnaire,idValueMap)
                        }}
                    >
                        {m.decision && Object.values(m.decision)?.map((m: any) => {
                            console.log(m.decision, questionnaire[m.name]);
                            return <MenuItem value={m[0].name}>{m[0].name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <div style={{marginLeft: 10, marginTop: 15}}>
                    {m.decision[questionnaire[m.name]] && m.decision[questionnaire[m.name]][0].decision && Array.isArray(Object.values(m.decision[questionnaire[m.name]][0].decision)) && Object.values(m.decision[questionnaire[m.name]][0].decision)?.map((x: any) => {
                        console.log(x);
                        return renderActionCompontents(x[0])
                    })}
                </div>
            </div>)
        }
        return (<div></div>);
    }

    function renderChild() {
        let questions = questList?.filter((x: any) => x.id == questionnaire["templateId"]);
        if (questions?.length > 0) {
            let metaList = questions[0].config.meta;
            {
                return (<div>
                    {metaList?.map((m: any) => {
                        return <Grid key={m.name} item xs={6} sm={6} lg={6} xl={6}>
                            {renderActionCompontents(m)}
                        </Grid>
                    })}
                </div>)
            }
        }
        return (<div></div>);
    }

    return (<Box sx={{cursor: 'pointer', bgcolor: 'background.paper', p: 4,}}>
        <Typography variant="h6" component="h2" paddingBottom={2}>
            {'Questionnaire'}
        </Typography>
        <FormControl fullWidth style={{width: '400px'}}>
            <InputLabel id="demo-simple-select-label">Choose Template</InputLabel>
            <Select
                fullWidth={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={questionnaire["templateId"]}
                label="m.placeHolder"
                onChange={(e: any) => {
                    questionnaire["templateId"] = e.target.value;
                    onQuestChange(questionnaire);
                }}
            >
                {questList && questList.map((m: any) => {
                    return <MenuItem value={m.id}>{m.name}</MenuItem>
                })}
            </Select>
        </FormControl>
        {renderChild()}
    </Box>);
};

export default ProjectQuestionnaire;