import React, { useEffect, useState } from 'react';
import { QuotesLayout } from '../../layouts';
import { QuotesListProps, useVendor } from '../../hooks/useVendor';
import { QuoteRequestProps } from '../../components/QuoteCardContainer';
import { useTimelineForm } from '../../hooks/useTimelineForm';
import { TimelineInputData } from '../../components/AddMoreTimeline';
import { convertToMilliSeconds } from '../../utils/convertMilliSeconds';

const VendorQuotes = () => {

  const initialState = {
    amount: '',
    description: '',
  }

  const {
    vendorPendingQuoteList, vendorQuoteList, fetchVendorPendingQuotesList, fetchVendorQuotesList, acceptRequest, rejectRequest, sendTimeline
  } = useVendor();
  const [timelineValue, onChangeTimelineForm, resetForm] = useTimelineForm(initialState);

  const [open, setOpen] = useState<boolean>(false);
  const [selectedQuote, setSelectedQuote] = useState<QuotesListProps>();
  const [timelineInput, setTimelineValue] = useState<TimelineInputData[]>([{ amount: "", description: "", title: "", startDate: null, endDate: null }]);

  useEffect(() => {
    fetchVendorPendingQuotesList();
    fetchVendorQuotesList();
  }, [fetchVendorPendingQuotesList, fetchVendorQuotesList]);

  const acceptClientRequest = async (event: QuoteRequestProps) => {
    try {
      await acceptRequest({
        description: event.description,
        quoteId: event.id,
      });
      fetchVendorPendingQuotesList();
      fetchVendorQuotesList();
    } catch (error) {
      console.log(error);
    }
  }

  const rejectClientRequest = async (event: QuoteRequestProps) => {
    try {
      await rejectRequest({
        description: event.description,
        quoteId: event.id,
      });
      fetchVendorPendingQuotesList();
      fetchVendorQuotesList();
    } catch (error) {
      console.log(error);
    }
  }

  const onChangeTimeline = (index: number, field: keyof TimelineInputData, value: any) => {
    const newTimelineData = [...timelineInput];
    newTimelineData[index][field] = value;
    setTimelineValue(newTimelineData);
  };
  

  const onClickAddMore = () => {
    setTimelineValue([...timelineInput, { amount: "", description: "", title: "", startDate: null, endDate: null }]);
  };

  const onClickSendTimeline = (event: QuotesListProps) => {
    setSelectedQuote(event);
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const onClickConfirmTimeline = async () => {
    const formatTimeline = timelineInput?.map(item => ({
      ...item,
      amount: parseInt(item.amount),
      startDate: convertToMilliSeconds(item?.startDate),
      endDate: convertToMilliSeconds(item?.endDate)
    }));
    if (selectedQuote) {
      await sendTimeline({
        amount: +timelineValue.amount,
        description: timelineValue.description,
        quoteId: selectedQuote?.id,
        vendorId: selectedQuote?.vendor?.id,
        timelines: formatTimeline,
      });
      setOpen(false);
      resetForm();
      fetchVendorPendingQuotesList();
      fetchVendorQuotesList();
    }
  };

  return (
    <div>
      <QuotesLayout
        open={ open }
        value={ timelineValue }
        pendingData={ vendorPendingQuoteList || [] }
        allQuoteData={ vendorQuoteList || [] }
        timelineInput={ timelineInput }
        onChangeTimeline={ onChangeTimeline }
        onClickAddMore={ onClickAddMore }
        onChange={ onChangeTimelineForm }
        onCloseModal={ onCloseModal }
        onClickConfirmTimeline={ onClickConfirmTimeline }
        onClickSendTimeline={ onClickSendTimeline }
        onClickAccept={ acceptClientRequest }
        onClickReject={ rejectClientRequest } />
    </div>
    
  )
}

export default VendorQuotes;