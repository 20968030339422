import React from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import colobten from "../../assets/images/colobten.jpeg";
import "./Login.css";

interface OnboardingLayoutProps {
  phoneNumber: string;
  otp: string;
  onChangePhonenNumber: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOtp: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onVerifyOtp: () => void;
  onSendOtp: () => void;
  isOtpSent: boolean;
  clientId?: number;
}

const OnboardingLayout = ({
  phoneNumber,
  clientId,
  otp,
  onSendOtp,
  onChangeOtp,
  onChangePhonenNumber,
  onVerifyOtp,
  isOtpSent,
}: OnboardingLayoutProps) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            flexDirection: "column",
            minHeight: "100Vh",
          }}
        >
          <img src={colobten} alt="Colobten" className="login-logo-img" />
          <TextField
            inputProps={{ maxLength: 10 }}
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={onChangePhonenNumber}
          />
          {isOtpSent && (
            <TextField
              type="otp"
              placeholder="OTP"
              value={otp}
              onChange={onChangeOtp}
            />
          )}
          {clientId && !isOtpSent && (
            <Button
              sx={{ width: 250 }}
              style={{ width: 200, backgroundColor: "#3361FF" }}
              onClick={onSendOtp}
              disabled={phoneNumber.length < 10}
            >
              Send OTP
            </Button>
          )}
          {clientId && isOtpSent && (
            <Button
              sx={{ width: 250 }}
              style={{ width: 200, backgroundColor: "#3361FF" }}
              disabled={phoneNumber.length < 10 || otp.length < 4}
              onClick={onVerifyOtp}
            >
              Verify
            </Button>
          )}
          {clientId == null && <Typography> Invalid Link</Typography>}
        </Box>
      </Grid>
    </Grid>
  );
};

export default OnboardingLayout;
