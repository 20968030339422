import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Stack, TextField } from '@mui/material';
import {UserRole} from "../../constants/enum";
import {useUser} from "../../provider/UserProvider";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
};

interface AddWorkPermitModalProps {
    open: boolean;
    value: {
        name: string;
        endDate: any;
        startDate: any;
        createdBy: number;
        createdDate: number;
        enabled: boolean;
        status: any;
    }
    onClickPrimary: () => void;
    handleClose: () => void;

    onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeStartDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeEndDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddWorkPermitModal = ({ open, value,  onClickPrimary, handleClose,onChangeName,onChangeStartDate,onChangeEndDate }: AddWorkPermitModalProps) => {
    const {userData} = useUser();
    console.log(userData.role)
    return (
        <Modal
            open={ open }
            onClose={ handleClose }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} width={400}>
                {userData.role  === UserRole.ROLE_CLIENT && <Typography id="modal-modal-title" variant="h6" component="h2">
                    Create Work Permit
                </Typography>}
                <TextField multiline={false} minRows={"1"} placeholder="Name" name="name" value={ value.name } onChange={ onChangeName } />
                <TextField multiline={false} minRows={"1"} placeholder="Start Date" name="startDate" value={ value.startDate } onChange={ onChangeStartDate } />
                <TextField multiline={false} minRows={"1"} placeholder="End Date" name="endDate" value={ value.endDate } onChange={ onChangeEndDate } />

                <Stack direction="row" spacing={ 4 } >
                    <Button style={{ backgroundColor : "#3361FF"}}  variant="outlined" onClick={ handleClose }>Cancel</Button>
                    <Button style={{ backgroundColor : "#3361FF"}}  variant="contained" onClick={ onClickPrimary }>Create</Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default AddWorkPermitModal;
