// QRPage.tsx
import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import QRCode from 'react-qr-code';
import { useLocation } from 'react-router-dom';

const Qr = () => {

    const [qrValue, setQrvalue] = React.useState<string>('');

    const location = useLocation();

    useEffect(() => {
        if (location.state != null) {
            setQrvalue(location.state.data.data);
            setTimeout(() => {
            window.print();
            }, 1000);
        }
    }, [location.state]);


    return (
        <Box sx={{ width: 300, height: '90vh', display: 'flex', justifyContent: 'center', margin: 'auto' }} >
            <QRCode
                size={100}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={qrValue}
                viewBox={`0 0 100 256`}
            />
        </Box>
    );
};

export default Qr;