import Vendor from "./Vendor";
import Login from "./Login";
import SignUp from "./Signup";
import Home from "./Home";
import VendorDetails from "./VendorDetails";
import VendorQuotes from "./VendorQuotes";
import AccessDenied from "./AccessDenied";
import ProjectPage from "./Project";
import DetailView from "components/DetailView";
import ProfilePage from "./Profile";
import ClientQuotes from "./ClientPages/ClientQuotes";

export {
    Vendor,
    Login,
    SignUp,
    Home,
    VendorDetails,
    VendorQuotes,
    AccessDenied,
    ProjectPage,
    ClientQuotes,
    DetailView,
    ProfilePage,
}
