// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected-card {
    background-color: #ADD8E6;
}

.unselected-card {
    background-color: white;
}

.customOutlined{
    border-color: orange;
}

.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-title {
    font-size: 1.5rem;
    margin-bottom: 8px;
}

.card-content {
    font-size: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Project/ProjectStyle.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,wCAAwC;AAC5C;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".selected-card {\n    background-color: #ADD8E6;\n}\n\n.unselected-card {\n    background-color: white;\n}\n\n.customOutlined{\n    border-color: orange;\n}\n\n.card {\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    padding: 16px;\n    margin: 16px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.card-title {\n    font-size: 1.5rem;\n    margin-bottom: 8px;\n}\n\n.card-content {\n    font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
