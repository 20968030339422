// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-img{
    /* styles.css or any other CSS file */
    width: 100px;
    height: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Topbar/Topbar.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".logo-img{\n    /* styles.css or any other CSS file */\n    width: 100px;\n    height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
