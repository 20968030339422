import {useCallback, useEffect, useState} from 'react';
import {AxiosInstance} from 'axios';
import getApiConfig from '../config/apiConfig';
import {clientEndPoint, vendorEndPoint} from '../constants/EndPoint';
import {QuotesListProps} from './useVendor';
import {STORAGE_KEY} from "../constants";
import {UserRole} from "../constants/enum";
import {useUser} from "../provider/UserProvider";

export interface ClientData {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  phoneNumber: string;
  enabled: boolean;
  lastPasswordResetDate: string;
  authorities?: {
      authority: string;
  }[];
}

export interface TimelineRequestProps {
  quoteId: string;
}

export interface GetClientDetailsProps {
  clientId: string;
}
export interface CreateVendorRequestProps {
  address: string,
  email: string,
  enabled: true,
  gstNo: string,
  name: string,
  password: string,
  phoneNumber: string,
  username: string
}

export interface CreateEmployeeRequestProps {
  roleId?: number,
  id?: number,
  address: string,
  clientId: string,
  dob: string,
  email: string,
  enabled: true,
  gender: string,
  gstNo: string,
  name: string,
  password: string,
  phoneNumber: string,
  userId: number,
  username: string,
  vendorId: number
}

export interface CreateEmployeeMedicalModalProps {
  id?: number,
  enabled: true,
  clientId: string,
  companyName: string,
  date: string,
  designation: string,
  ifAmbulanceService: string,
  emergencyContact: boolean,
  employeeReport: string,
  ifEmergencyContact: string,
  ifMedicalTeamActions: string,
  ambulanceService: boolean,
  isSick: boolean,
  name: string,
  preCautions: string,
  medicalTeamActions: boolean,
}

export interface CreateQuestionnaireRequestProps {
  question: string,
  enabled: true,
  employeeId: string

}

export interface AddEmailRequestProps {
  id: number,
  subject: string,
  text: string,
  to: string,
}

export interface CreateWorkPermitRequestProps {
  endDate: any,
  startDate: any,
  createdBy: number,
  createdDate: number,
  name: string,
  status: any,
  enabled: true,
  configuration:any,
  vendorId:any,
}

export const useClient = () => {
  const apiConfig: AxiosInstance = getApiConfig();
  const {userData} = useUser();


  const [clientList, setClientList] = useState<ClientData[] | null>();
  const [clientQuoteList, setClientQuoteList] = useState<QuotesListProps[] | null>();
  const [clientLoading, setClientLoading] = useState(false);
  const [shouldRefetchQuotes, setShouldRefetchQuotes] = useState<boolean>(false);
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);
  const [employeeRefetch, setEmployeeRefetch] = useState<boolean>(false);
  const [questionnaireRefetch, setQuestionnaireRefetch] = useState<boolean>(false);
  const [workPermitRefetch, setWorkPermitRefetch] = useState<boolean>(false);
  const [vendorRefetch, setVendorRefetch] = useState<boolean>(false);
  const [clientDetails, setClientDetails] = useState<any | {}>();
  const [employeeDetails, setEmployeeDetails] = useState<any | {}>();
  const [questionnaireDetails, setQuestionnaireDetails] = useState<any | {}>();
  const [employeeMedicalDetails, setEmployeeMedicalDetails] = useState<any | {}>();
  const [emailDetails, setEmailDetails] = useState<any | {}>();
  const [roleDropdownValues, setRoleDropdownValues] = useState<[]>([]);
  const [employeeList, setEmployeeList] = useState<[]>([]);
  const [medicalReportList, setMedicalReportList] = useState<[]>([]);
  const [questionnaireList, setQuestionnaireList] = useState<[]>([]);
  const [sendMessageList, setSendMessageList] = useState<[]>([]);
  const [workPermitList, setWorkPermitList] = useState<[]>([]);
  const [projectDocuments, setProjectDocuments] = useState<any | []>();
  const [biddingDetails, setBiddingDetails] = useState<any | []>();
  const [error, setError] = useState(null);


  const fetchClientList = useCallback(() => {
    setShouldRefetch(true);
  }, []);

  const fetchClientQuotesList = useCallback(() => {
    setShouldRefetchQuotes(true);
  }, []);

  const getClientList = useCallback(async () => {
    try {
      setClientLoading(true);
      setShouldRefetch(false);
      const response = await apiConfig.get(clientEndPoint.GET_ALL);
      const userResponse = response?.data;
      setClientList(userResponse);
      setClientLoading(false);
      return userResponse;
    } catch (e) {
      setClientLoading(false);
      setShouldRefetch(false);
      setClientList(null);
      return null;
    }
  }, [apiConfig]);

  const getClientQuotesList = useCallback(async () => {
    try {
      setClientLoading(true);
      setShouldRefetchQuotes(false);
      const response = await apiConfig.get(clientEndPoint.GET_ALL_QUOTES);
      const userResponse = response?.data;
      setClientQuoteList(userResponse);
      setClientLoading(false);
      return userResponse;
    } catch (e) {
      setClientLoading(false);
      setShouldRefetchQuotes(false);
      setClientQuoteList(null);
      return null;
    }
  }, [apiConfig]);

  const acceptTimelineRequest = useCallback(async (payload: TimelineRequestProps) => {
    try {
      const response = await apiConfig.post(clientEndPoint.ACCEPT_TIMELINE, payload);
      return response?.data;
    } catch (e) {
      return null;
    }
  }, [apiConfig]);

  const getClientDetails = useCallback(async (payload: GetClientDetailsProps) => {
    try {
      const response = await apiConfig.post(clientEndPoint.GET_CLIENT_DETAILS, payload);
      const userResponse = response?.data.vendorList;
      localStorage.setItem(STORAGE_KEY.ClientName, response?.data?.name);
      setClientDetails(userResponse);
      return response?.data;
    } catch (e) {
      return null;
    }
  }, [apiConfig]);

  const getProjectDocuments = useCallback(async (projectId: any, vendorId: any) => {
    try {
      const response = await apiConfig.get(clientEndPoint.PROJECT_DOCUMENTS+ "?projectId="+projectId+ "&vendorId="+vendorId);
      const userResponse = response?.data.data;
      setProjectDocuments(userResponse);
      return response?.data;
    } catch (e) {
      return null;
    }
  }, [apiConfig]);


  const getBiddingDetails = useCallback(async (projectId: any) => {
    try {
      const response = await apiConfig.get(clientEndPoint.BIDDING_DETAILS+ "?projectId="+projectId);
      const userResponse = response?.data.data;
      setBiddingDetails(userResponse);
      return response?.data;
    } catch (e) {
      return null;
    }
  }, [apiConfig]);

  const awardProject = useCallback(async (payload: any) => {
    try {
      const response = await apiConfig.post(clientEndPoint.AWARD_PROJECT, payload);
      const userResponse = response?.data.data;
      return response?.data;
    } catch (e) {
      return null;
    }
  }, [apiConfig]);

  const emailProjectVendor = useCallback(async (projectId: any) => {
    try {
      setClientLoading(true);
      const response = await apiConfig.post(clientEndPoint.SEND_EMAIL + projectId);
      setClientLoading(false);
      return response?.data;
    } catch (e) {
      return null;
    }
  }, [apiConfig]);

  const createVendor = useCallback(async (payload: CreateVendorRequestProps) => {
    try {
      setShouldRefetch(false);
      const response = await apiConfig.post(clientEndPoint.CLIENT_CREATE_VENDOR, payload);
      setVendorRefetch(true);
      return response?.data;
    } catch (error : any) {
      setShouldRefetch(false);
      return error;
    }
  }, [apiConfig]);


  const createEmployee = useCallback(async (payload: CreateEmployeeRequestProps) => {
    try {
      setClientLoading(true);
      setShouldRefetch(false);
      const response = await apiConfig.post(clientEndPoint.CLIENT_CREATE_EMPLOYEE, payload);
      setClientLoading(false);
      setEmployeeRefetch(true);
      return response?.data;
    } catch (error : any) {
      setClientLoading(false);
      setShouldRefetch(false);
      return error;
    }
  }, [apiConfig]);


  const getEmployeeList = useCallback(async () => {
    try {
      setClientLoading(true);
      setShouldRefetch(false);
      const response = await apiConfig.get(clientEndPoint.CLIENT_GET_EMPLOYEE);
      setClientLoading(false);
      const userResponse = response?.data;
      setEmployeeList(userResponse);
      return userResponse;
    } catch (e) {
      setClientLoading(false);
      setShouldRefetch(false);
      return null;
    }
  }, [apiConfig]);


  const getEmployeeDetail = useCallback(async (id: any) => {
    try {
      setClientLoading(true);
      setShouldRefetch(false);
      const response = await apiConfig.post(clientEndPoint.CLIENT_GET_EMPLOYEE_DETAILS, {employeeId: id});
      const userResponse = response?.data;
      setEmployeeDetails(userResponse);
      setClientLoading(false);
      return userResponse;
    } catch (e) {
      setClientLoading(false);
      setShouldRefetch(false);
      setEmployeeDetails(null);
      return null;
    }
  }, [apiConfig]);



  const getMedicalReportList = useCallback(async () => {
    try {
      setClientLoading(true);
      const response = await apiConfig.get(clientEndPoint.EMPLOYEE_MEDICAL_LIST);
      setClientLoading(false);
      const medicalReportListResponse = response?.data;
      setMedicalReportList(medicalReportListResponse);
      return medicalReportListResponse;
    } catch (e) {
      setClientLoading(false);
      return null;
    }
  }, [apiConfig]);


  const createEmployeeMedical = useCallback(async (payload: CreateEmployeeMedicalModalProps) => {
    try {
      setClientLoading(true);
      setShouldRefetch(false);
      const response = await apiConfig.post(clientEndPoint.EMPLOYEE_MEDICAL, payload);
      setClientLoading(false);
      return response?.data;
    } catch (error : any) {
      setClientLoading(false);
      setShouldRefetch(false);
      return error;
    }
  }, [apiConfig]);



  const getMedicalReportDetail = useCallback(async (medicalReportId: any) => {
    try {
      setClientLoading(true);
      const response = await apiConfig.post(clientEndPoint.EMPLOYEE_MEDICAL_DETAILS, {medicalReportId: medicalReportId});
      const medicalReportDetails = response?.data;
      setEmployeeMedicalDetails(medicalReportDetails);
      setClientLoading(false);
      return medicalReportDetails;
    } catch (e) {
      setClientLoading(false);
      setEmployeeMedicalDetails(null);
      return null;
    }
  }, [apiConfig]);



  const createQuestionnaire = useCallback(async (payload: CreateQuestionnaireRequestProps) => {
    try {
      setClientLoading(true);
      setShouldRefetch(false);
      const response = await apiConfig.post(clientEndPoint.CREATE_EMPLOYEE_QUESTIONNAIRE, payload);
      setClientLoading(false);
      setQuestionnaireRefetch(true);
      return response?.data;
    } catch (error : any) {
      setClientLoading(false);
      setShouldRefetch(false);
      return error;
    }
  }, [apiConfig]);


  const getQuestionnaireList = useCallback(async () => {
    try {
      setClientLoading(true);
      setShouldRefetch(false);
      const response = await apiConfig.get(clientEndPoint.EMPLOYEE_GET_QUESTIONNAIRE);
      setClientLoading(false);
      const userResponse = response?.data;
      setQuestionnaireList(userResponse);
      return userResponse;
    } catch (e) {
      setClientLoading(false);
      setShouldRefetch(false);
      return null;
    }
  }, [apiConfig]);


  const getQuestionnaireDetail = useCallback(async (id: any) => {
    try {
      setClientLoading(true);
      setShouldRefetch(false);
      const response = await apiConfig.post(clientEndPoint.EMPLOYEE_GET_QUESTIONNAIRE_DETAILS, {id: id});
      const userResponse = response?.data;
      setQuestionnaireDetails(userResponse);
      setClientLoading(false);
      return userResponse;
    } catch (e) {
      setClientLoading(false);
      setShouldRefetch(false);
      setQuestionnaireDetails(null);
      return null;
    }
  }, [apiConfig]);


  const messageList = useCallback(async (questionnaireId: any) => {
    try {
      setClientLoading(true);
      setShouldRefetch(false);

      const response = await apiConfig.get(clientEndPoint.EMAIL_MESSAGE_LIST, {
        params: { questionnaireId: questionnaireId }
      });

      setClientLoading(false);
      const userResponse = response?.data;
      setSendMessageList(userResponse);
      return userResponse;
    } catch (e) {
      setClientLoading(false);
      setShouldRefetch(false);
      return null;
    }
  }, [apiConfig]);



  const sendEmail = useCallback(async (payload: AddEmailRequestProps) => {
    try {
      setClientLoading(true);
      setShouldRefetch(false);
      const response = await apiConfig.post(clientEndPoint.SEND_EMAIL_MESSAGE, payload);
      const userResponse = response?.data;
      setEmailDetails(userResponse);
      setClientLoading(false);
      return userResponse;
    } catch (e) {
      setClientLoading(false);
      setShouldRefetch(false);
      setEmailDetails(null);
      return null;
    }
  }, [apiConfig]);


  const getRoleDropDownValues = useCallback(async () => {
    try {
      setClientLoading(true);
      setShouldRefetchQuotes(false);
      const response = await apiConfig.get(clientEndPoint.GET_ALL_ROLE_DROPDOWN_VALUES );
      const userResponse = response?.data;
      setRoleDropdownValues(userResponse);
      setClientLoading(false);
      return userResponse;
    } catch (e) {
      setClientLoading(false);
      setShouldRefetch(false);
      return null;
    }
  }, [apiConfig]);


  const getWorkPermitList = useCallback(async (clientId : any) => {
    try {
      setClientLoading(true);
      setShouldRefetch(false);
      if(userData.role === UserRole.ROLE_VENDOR) {
        const response = await apiConfig.get(clientEndPoint.VENDOR_FIND_ALL_GET_WORK_PERMIT+ "?clientId="+clientId);
        setClientLoading(false);
        const userResponse = response?.data;
        setWorkPermitList(userResponse);
        return userResponse;
      }  else {
        setClientLoading(true);
        const response = await apiConfig.get(clientEndPoint.CLIENT_GET_WORK_PERMIT+ "?clientId="+clientId);
        setClientLoading(false);
        const userResponse = response?.data;
        setWorkPermitList(userResponse);
        return userResponse;
      }
    } catch (e) {
      setClientLoading(false);
      setShouldRefetch(false);
      return null;
    }
  }, [apiConfig]);

  const getLotoForWorkPermit = useCallback(async (id:any) => {
    try {
      setShouldRefetch(false);
      const response = await apiConfig.get(clientEndPoint.WORK_PERMIT_LOTO_LIST + "/"+ id);
      const userResponse = response?.data;
      return userResponse;
    } catch (e) {
      setShouldRefetch(false);
      return null;
    }
  }, [apiConfig]);



  const getOnboardingCode = useCallback(async () => {
    try {
      const response = await apiConfig.get(clientEndPoint.ONBOARDING_CODE);
      const userResponse = response?.data;
      return userResponse;
    } catch (e) {
      return null;
    }
  }, [apiConfig]);

  const lotoByClient = useCallback(async () => {
    try {
      setShouldRefetch(false);
      const response = await apiConfig.get(clientEndPoint.LOTO_BY_CLIENT);
      return response?.data;
    } catch (e) {
      setShouldRefetch(false);
      return null;
    }
  }, [apiConfig]);

  const fileUpload = useCallback(async (payload: FormData) => {
    try {
      const response = await apiConfig.post(clientEndPoint.UPLOAD_FILE, payload,{headers: {
          'Content-Type': 'multipart/form-data'
        }});
      await getProjectDocuments(payload.get("projectId"), payload.get("vendorId"));
      return response?.data;
    } catch (e) {
      return null;
    }
  }, [apiConfig]);

  const downloadFile = useCallback(async (docId: any, name : any) => {
    try {
      const response = await apiConfig.get(clientEndPoint.DOWNLOAD_FILE+"/"+ docId,{
        responseType: 'blob',
      });
      const filename = name;
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (e) {
      console.log(e)
      return null;
    }
  }, [apiConfig]);

  const rejectTimelineRequest = useCallback(async (payload: TimelineRequestProps) => {
    try {
      const response = await apiConfig.post(clientEndPoint.REJECT_TIMELINE, payload);
      return response?.data;
    } catch (e) {
      return null;
    }
  }, [apiConfig]);

  useEffect(() => {
    if (shouldRefetchQuotes) {
      getClientQuotesList();
      setShouldRefetchQuotes(false);
    }
  }, [shouldRefetchQuotes, getClientQuotesList]);

  useEffect(() => {
    if (vendorRefetch) {
      const clientId = localStorage.getItem(STORAGE_KEY.UserId);
      getClientDetails({clientId : clientId ?? ''});
      setVendorRefetch(false);
    }
  }, [vendorRefetch, getClientDetails]);

  useEffect(() => {
    if (employeeRefetch) {
      const clientId = localStorage.getItem(STORAGE_KEY.UserId);
      getClientDetails({clientId : clientId ?? ''});
      setEmployeeRefetch(false);
    }
  }, [employeeRefetch, getClientDetails]);

  useEffect(() => {
    if (questionnaireRefetch) {
      const clientId = localStorage.getItem(STORAGE_KEY.UserId);
      getClientDetails({clientId : clientId ?? ''});
      setQuestionnaireRefetch(false);
    }
  }, [employeeRefetch, getClientDetails]);

  useEffect(() => {
    if (workPermitRefetch) {
      const clientId = localStorage.getItem(STORAGE_KEY.UserId);
      getClientDetails({clientId : clientId ?? ''});
      setWorkPermitRefetch(false);
    }
  }, [employeeRefetch, getClientDetails]);

  useEffect(() => {
    if (shouldRefetch) {
      getClientList();
      setShouldRefetch(false);
    }
  }, [shouldRefetch, getClientList]);

  return {
    clientList,
    clientQuoteList,
    clientLoading,
    fetchClientList,
    fetchClientQuotesList,
    rejectTimelineRequest,
    acceptTimelineRequest,
    fileUpload,
    getClientDetails,
    clientDetails,
    createVendor,
    createEmployee,
    getEmployeeList,
    employeeList,
    createQuestionnaire,
    questionnaireRefetch,
    getQuestionnaireList,
    questionnaireList,
    getQuestionnaireDetail,
    questionnaireDetails,
    sendEmail,
    emailDetails,
    setEmailDetails,
    messageList,
    sendMessageList,
    setSendMessageList,
    getWorkPermitList,
    getLotoForWorkPermit,
    lotoByClient,
    workPermitList,
    getProjectDocuments,
    projectDocuments,
    getBiddingDetails,
    biddingDetails,
    awardProject,
    getMedicalReportList,
    medicalReportList,
    createEmployeeMedical,
    getMedicalReportDetail,
    emailProjectVendor,
    downloadFile,
    getEmployeeDetail,
    setEmployeeDetails,
    employeeDetails,
    getRoleDropDownValues,
    roleDropdownValues,
    setRoleDropdownValues,
    getOnboardingCode

  };
};
