import React, {useEffect, useState} from 'react';
import { Box } from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import { VendorData, useVendor } from '../../hooks/useVendor';
import { VendorDetailsLayout } from '../../layouts';
import {useUser} from "../../provider/UserProvider";
import {STORAGE_KEY} from "../../constants";
import axios from "axios";
import {useClient} from "../../hooks/useClient";
import {useSnackbar} from "notistack";

interface CreateProps {
  description: string;
  amount: string;
}

const VendorDetails = () => {
  const location = useLocation();
  const vendorDetails: any = location?.state?.data || {};
  const navigate = useNavigate();

  const { createRequest, getDetailByUserStatus ,getDetailsByUser} = useVendor();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { fileUpload } = useClient();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState<CreateProps>({
    description: '',
    amount: '',
  });
  const [open, setOpen] = useState<boolean>(false);

  const onClickRequest = () => {
    setOpen(true);
  };

  useEffect(() => {
    const clientId = localStorage.getItem(STORAGE_KEY.UserId);
    console.log(vendorDetails.data)
    getDetailByUserStatus({clientId: Number(clientId) , vendorId: vendorDetails.data.id});
  }, []);

  const onCloseModal = () => {
    setOpen(false);
  };

  const onClickConfirmRequest = async () => {
    try {
      let quoteCreationData = await createRequest({
        vendorId: vendorDetails?.data.id,
        description: value.description,
        amount: +value.amount,
      });
      enqueueSnackbar("Quote Request Successfully sent", { variant: 'success',autoHideDuration: 3000 } )
      navigate(`/quotes/${quoteCreationData.id}`,
          { state: { data:  {data: quoteCreationData} } });

      setOpen(false);
    } catch (error) {
      setOpen(false);
    }
  };

  const onChangeQuote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      description: event.target.value,
    });
  };

  const onChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      amount: event.target.value,
    });
  };
  const onViewQuote = (id: string) => {
    navigate(`/quotes/${getDetailsByUser.id}`,
        { state: { data:  {data: getDetailsByUser} } });
  };

  return (
    <Box>
      <VendorDetailsLayout
        open={ open }
        onViewQuote={onViewQuote}
        quoteDetails ={getDetailsByUser}
        value={ value }
        vendorDetails={ vendorDetails }
        onChangeAmount={ onChangeAmount }
        onChangeQuote={ onChangeQuote }
        onCloseModal={ onCloseModal }
        onClickConfirmRequest={ onClickConfirmRequest }
        onClickRequest={ onClickRequest } />
    </Box>
  )
}

export default VendorDetails;
