import React, {useRef} from 'react';
import {Box, Button, ButtonGroup} from '@mui/material';
import { RequestVendorModal } from '../../../components';
import DetailView from "../../../components/DetailView";
import Grid from "@mui/material/Grid";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


interface VendorDetailsLayoutProps {
    vendorDetails: any;
    open: boolean;
    quoteDetails: any;
    value: {
      description: string;
      amount: string;
    }
    onChangeQuote: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeAmount: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClickConfirmRequest: () => void;
    onCloseModal: () => void;
    onClickRequest: () => void;
    onViewQuote: (id: any) => void;
}

const VendorDetailsLayout = ({
    open, quoteDetails,
    vendorDetails,
    value,
    onChangeAmount,
    onChangeQuote,
    onCloseModal,
    onClickConfirmRequest,
    onClickRequest,
    onViewQuote,
}: VendorDetailsLayoutProps) => {
    const fileInputRef = useRef(null);

    return (
      <div>
          <Grid container justifyContent="flex-end" spacing={4}>
              <Grid item>
                  <ButtonGroup variant="text" aria-label="text button group"  sx={{padding: 1}}>
                      {quoteDetails === ''  ?  <Button style={{ backgroundColor : "#3361FF"}} variant={'text'} onClick={onClickRequest}>
                          Contact Now
                      </Button> : null}
                      {quoteDetails !== ''  ?  <Button style={{ backgroundColor : "#3361FF"}} variant={'text'} onClick={onViewQuote}>
                          View Quote
                      </Button> : null}
                  </ButtonGroup>
              </Grid>
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

              <DetailView detailData={vendorDetails} entity={'vendor'}/>
              <RequestVendorModal
                  open={ open }
                  value={ value }
                  onChangeQuote={ onChangeQuote }
                  onChangeAmount={ onChangeAmount }
                  handleClose={ onCloseModal }
                  onClickPrimary={ onClickConfirmRequest } />
          </Box>
      </div>

  )
}

export default VendorDetailsLayout;
