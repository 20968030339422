import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Stack, TextField } from '@mui/material';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
};

interface AddQuestionnaireModalProps {
    open: boolean;
    value: {
        question: string,
        employeeId: string
    }
    onClickPrimary: () => void;
    handleClose: () => void;

    onChangeQuestionnaireValue: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    selectedValue: string;
}

const AddQuestionnaireModal = ({ open, value, selectedValue, onChangeQuestionnaireValue, onClickPrimary, handleClose }: AddQuestionnaireModalProps) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} width={400} height={500}>
                <div style={styles.container}>
                    <textarea
                        style={{ ...styles.textarea, width: '300px', height: '300px' } as React.CSSProperties}
                        rows={4}
                        placeholder="Type your message here..."
                        value={value.question}
                        onChange={onChangeQuestionnaireValue}
                    />
                    <br />
                </div>
                <Stack direction="row" spacing={4} style={{ justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: "#3361FF" }} variant="outlined"
                            onClick={handleClose}>Cancel</Button>
                    <Button style={{ backgroundColor: "#3361FF" }} variant="contained"
                            onClick={onClickPrimary}>Create</Button>
                </Stack>
            </Box>
        </Modal>
    );
};

const styles = {
    container: {
        maxWidth: '400px',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#f0f8ff',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 255, 0.2)',
    },
    textarea: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '4px',
        border: '1px solid #6495ED',
        boxSizing: 'border-box',
    },
    sendButton: {
        padding: '12px 20px',
        backgroundColor: '#4169E1',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};

export default AddQuestionnaireModal;
