import React from 'react';
import { Calendar, Event, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { generateEventsInRange } from '../../utils/generateEventInRange';

interface CalendarEvent {
  title: string;
  start: string; // Date in ISO string format (e.g., '2023-07-25T10:00:00')
  end: string;   // Date in ISO string format (e.g., '2023-07-25T12:00:00')
}

interface CalendarContainerProps {
  events: CalendarEvent[];
}

const CalendarContainer = ({ events }: CalendarContainerProps) => {
  const localizer = momentLocalizer(moment);

  // Convert the event data to the format required by react-big-calendar
  const convertedEvents: Event[] = events.reduce((acc: Event[], event) => {
    const { start, end, title } = event;
    const startDate = new Date(start);
    const endDate = new Date(end);
    return acc.concat(generateEventsInRange(startDate, endDate, title));
  }, []);

  return (
    <div style={{ height: 500 }}>
      <Calendar
        localizer={localizer}
        events={convertedEvents}
        startAccessor="start"
        endAccessor="end"
      />
    </div>
  );
};

export default CalendarContainer;
