import React, { useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { QuoteCardContainer, Tab, TabPanel, TimelineModal } from "../../components";
import { QuotesListProps } from "../../hooks/useVendor";
import { QuoteRequestProps } from "../../components/QuoteCardContainer";
import { TimelineInputData } from "../../components/AddMoreTimeline";
import AppTable from "../../components/Table";

interface QuotesLayoutProps {
  pendingData: QuotesListProps[];
  allQuoteData: QuotesListProps[];
  open: boolean;
  value: {
    description: string;
    amount: string;
  }
  timelineInput: TimelineInputData[];
  onChangeTimeline: (index: number, name: keyof TimelineInputData, event: Date | string | null) => void;
  onClickAddMore: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickConfirmTimeline: () => void;
  onCloseModal: () => void;
  onClickAccept: (event: QuoteRequestProps) => void;
  onClickReject: (event: QuoteRequestProps) => void;
  onClickSendTimeline: (event: QuotesListProps) => void;
}

const QuotesLayout = ({
  pendingData,
  allQuoteData,
  open,
  value,
  timelineInput,
  onChangeTimeline,
  onClickAddMore,
  onChange,
  onCloseModal,
  onClickConfirmTimeline,
  onClickAccept,
  onClickReject,
  onClickSendTimeline
}: QuotesLayoutProps) => {
  const [tabValue, setTabValue] = React.useState<number>(0);

  const tabList = useMemo(() => {
    return [ 'Pending', 'All Quote' ];
  }, []);

  const onChangeTab = (event: React.SyntheticEvent, index: number) => {
    setTabValue(index);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tab data={ tabList } value={ tabValue } onChangeTab={ onChangeTab } />
      <TabPanel value={ tabValue } index={ 0 }>
        <AppTable listData={pendingData} hyperlinkPropertyName="id" entity='quotes'/>
      </TabPanel>
      <TabPanel value={ tabValue } index={ 1 }>
        <AppTable listData={allQuoteData} hyperlinkPropertyName="id" entity='quotes'/>
      </TabPanel>
      <TimelineModal
        open={ open }
        value={ value }
        timelineInput={ timelineInput }
        onChangeTimeline={ onChangeTimeline }
        onClickAddMore={ onClickAddMore }
        onChange={ onChange }
        onCloseModal={ onCloseModal }
        onClickConfirmTimeline={ onClickConfirmTimeline } />
    </Box>
  );
};

export default QuotesLayout;
