import React, {useEffect, useState} from 'react';
import {useClient} from 'hooks/useClient';
import ClientQuoteDetailLayout from "../../../layouts/ClientLayouts/ClientQuoteDetailLayout";
import {useLocation, useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";

const ClientQuoteDetail = () => {

    const [quoteDetail, setQuoteDetail] = useState<any | {}>({});
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        if (location.state != null) {
            setQuoteDetail(location.state.data.data);
        }
    }, [location]);


    const {acceptTimelineRequest, rejectTimelineRequest} = useClient();

    const acceptRequest = async () => {
        try {
            await acceptTimelineRequest({
                quoteId: quoteDetail.id,
            }).then(r => {
                enqueueSnackbar(r.message, { variant: 'success',autoHideDuration: 3000 } )
            });
            navigate(-1);
        } catch (error) {
            console.log(error);
        }
    }

    const rejectRequest = async () => {
        try {
            await rejectTimelineRequest({
                quoteId: quoteDetail.id,
            });
            navigate(-1);
        } catch (error) {
            console.log(error);
        }
    }

    return (<div>
            <ClientQuoteDetailLayout onClickAccept={ acceptRequest } onClickReject={ rejectRequest } quoteDetail={ quoteDetail} />
        </div>

    )
}

export default ClientQuoteDetail;
