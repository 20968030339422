import React, {useEffect, useState} from 'react';
import { useClient } from '../../hooks/useClient';
import { QuoteRequestProps } from '../../components/QuoteCardContainer';
import {ClientQuotesLayout} from "../../layouts";
import {useVendor, VendorData} from "../../hooks/useVendor";
import {Box} from "@mui/material";
import AppTable from "../../components/Table";
import {useLocation} from "react-router-dom";

const VendorList = () => {

    const {getFindVendor,vendorFindList } = useVendor();
    const location = useLocation();
    const skillId = location?.state?.data || {};
    const [open, setOpen] = useState<boolean>(false);


    useEffect(() => {
        try {
             getFindVendor({
                skillId: skillId,
            });
            setOpen(false);
        } catch (error) {
            setOpen(false);
        }
    }, []);

    return (

        <div>
            <Box sx={{ width: '100%' }}>
                <AppTable listData={vendorFindList} hyperlinkPropertyName={'id'} entity={'vendor'} actionButtons={'QUOTE_ACCEPT_REJECT'}></AppTable>
            </Box>    </div>

)
}

export default VendorList;
