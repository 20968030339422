import { Navigate, Outlet } from "react-router-dom";

interface ProtectedRouteProps {
    isAllowed: boolean;
    children: JSX.Element;
}

const ProtectedRoute = ({ isAllowed, children }: ProtectedRouteProps) => {
    if (!isAllowed) {
      return <Navigate to="./login" replace />;
    }
  
    return children ? children : <Outlet />;
};

export default ProtectedRoute;