import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useUser} from '../../provider/UserProvider';
import {useLocation, useNavigate,} from "react-router-dom";
import {useClient} from "../../hooks/useClient";
import {useVendor} from "../../hooks/useVendor";
import {STORAGE_KEY} from "../../constants";
import {useSnackbar} from "notistack";
import {BiddingProject} from "../../components/BiddingProject";
import VendorProjectTimeline from "../../layouts/VendorLayouts/VendorQuoteDetailLayout";
import moment from "moment-timezone";


const VendorProjectPage = () => {
    const {userData} = useUser();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [project, setProject] = useState<any | {}>({});
    const location = useLocation();
    const navigate = useNavigate();
    const {sendTimeline} = useVendor();

    const {
        fileUpload, getProjectDocuments, projectDocuments, getBiddingDetails, biddingDetails, awardProject, downloadFile
    } = useClient();
    const {getVendorProjectDocuments, vendorProjectDocument, submitBid} = useVendor();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (location.state !== null) {
            setProject(location.state.data.data);
            getProjectDocuments(location.state.data.data.id, vendorId);
            getBiddingDetails(location.state.data.data.id);
            console.log(location.state.data.data)
        }
    }, [location]);

    useEffect(() => {
        getVendorProjectDocuments(location.state.data.data.id);
    }, [projectDocuments])

    const fileInputRef = useRef(null);

    const events = useMemo(() => {
        const data = [project]?.map((item) => {
            return item?.quoteId?.timelines?.map((item: any) => {
                return {
                    title: item?.title, start: item?.startDate, end: item?.endDate,
                }
            });
        });
        return data?.[0];
    }, [userData, location, project]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleFileUpload = async () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('projectId', project.id);
            await fileUpload(formData).then(r => {
                enqueueSnackbar(r.message, {variant: 'success', autoHideDuration: 3000})
            });
            setSelectedFile(null)
        }
    };

    const acceptRequest = async () => {
        try {
            project.timelines.forEach((x:any) => {
                x.endDate = moment(x.endDate).unix();
                x.startDate = moment(x.startDate).unix();
            })
            await sendTimeline(project);
            enqueueSnackbar("Timeline sent Successfully", {variant: 'success', autoHideDuration: 3000})
            navigate(-1);
        } catch (error) {
            console.log(error);
        }
    }

    function onAwardProject(id: any) {
        const vendorId = localStorage.getItem(STORAGE_KEY.UserId);
        submitBid({
            projectId: project.id, vendorId: vendorId, clientId: project.client.id
        }).then(r => {
            console.log(r)
            if (r?.status === 200) {
                enqueueSnackbar(r.message, {variant: 'success', autoHideDuration: 3000})
            } else {
                enqueueSnackbar(r.message, {variant: 'warning', autoHideDuration: 3000})
            }
            window.history.back();
        });
    }

    if (project == null) return (<div/>)

    const vendorId = localStorage.getItem(STORAGE_KEY.UserId);
    if (project.projectStatus === "BIDDING" || project?.vendor && project?.vendor.id && vendorId != project?.vendor.id)

        return (<BiddingProject
                project={project}
                onClick={() => onAwardProject(null)}
                listData={projectDocuments}
                onClick1={(id: any) => {
                    downloadFile(projectDocuments[id].id, projectDocuments[id].fileName)
                }}
                selectedFile={selectedFile}
                onClick2={handleFileUpload}
                onClick3={() => setSelectedFile(null)}
                ref={fileInputRef}
                onChange={handleFileChange}
                listData1={vendorProjectDocument}
                onClick4={(id: any) => {
                    downloadFile(vendorProjectDocument[id].id, vendorProjectDocument[id].fileName)
                }} events={events}/>)
    return (<VendorProjectTimeline quoteDetail={project}
                                   onDocumentOpen={(id: any) => {
        downloadFile(vendorProjectDocument[id].id, vendorProjectDocument[id].fileName)
    }}       projectDocuments={projectDocuments}
                                   onSendTimeline={acceptRequest}/>)
}

export default VendorProjectPage

