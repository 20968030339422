import React, {useEffect, useMemo, useState} from "react";
import CreateWorkPermitLayout from "layouts/CreateWorkPermitLayout";
import {useWorkPermit, WorkPermitData} from "hooks/useWorkPermit";
import {enqueueSnackbar} from "notistack";
import {useParams} from "react-router-dom";
import {useClient} from "../../hooks/useClient";
import {useUser} from "../../provider/UserProvider";
import {useVendor} from "../../hooks/useVendor";
import moment from "moment-timezone";
import { UserRole } from "constants/enum";
import {STORAGE_KEY} from "../../constants";
import Loader from "../../components/Loader/Loader";
import {workPermitEndpoint} from "../../constants/EndPoint/workPermit";

interface ImageData {
    data: File;
    url: string;
}

const CreateWorkPermitPage = () => {
    const {workPermitList, getWorkPermitList,getLotoForWorkPermit} = useClient();
    const {printWorkPermit,
        fetchWorkPermit, workPermitConfigList, workPermitDocumentsList, createWorkPermit, approveWorkPermit, rejectWorkPermit, completeWorkPermit, closeWorkPermit, uploadWorkPermitImage, getWorkPermitDocuments,workPermitLoading
    } = useWorkPermit();
    const [workPermitDetail, setWorkPermitDetail] = useState<any>({});
    const [date, setDate] = useState({
        startDate: new Date(),
        endDate: new Date(),
    });
    const [vendorSelectedImage, setVendorSelectedImage] = useState<File[]>([]);
    const params = useParams()
    const {userData} = useUser();
    const {vendorList, getVendorList} = useVendor();
    const {clientList, fetchClientList, downloadFile} = useClient();

    const vendorDetails = useMemo(() => {
        const filteredResponse = vendorList?.filter(item => item?.id === workPermitDetail?.vendorId);
        return filteredResponse?.[0];
    }, [vendorList, workPermitDetail?.vendorId]);

    useEffect(() => {
        if (userData.role === UserRole.ROLE_CLIENT) {
            getVendorList();
        }
    }, [userData.role]);

    useEffect(() => {
        fetchClientList();
        fetchWorkPermit();
        if (params.id != null) {
            fetchData();
        }
    }, [params.id]);

    useEffect(() => {
        if (params.id && (userData.role === UserRole.ROLE_CLIENT || userData.role === UserRole.CLIENT_EMPLOYEE) && workPermitDetail.status === "COMPLETED") {
            getWorkPermitDocuments({ workPermitId: params.id });
        }
    }, [params.id, userData.role, workPermitDetail.status]);

    const fetchData = async () => {
        var clientId = localStorage.getItem(STORAGE_KEY.UserId);

        if(userData.role  === UserRole.CLIENT_EMPLOYEE){
            clientId  = localStorage.getItem(STORAGE_KEY.ClientId);
        }
        let list = await getWorkPermitList(clientId);
        let lotoDTOList = await getLotoForWorkPermit(params.id);
        let tempData: any = list.filter((x: any) => x.id == params.id)[0];
        if (tempData != null) {
            tempData.configuration = JSON.parse(tempData.configuration);
            tempData.lotoDTOList = lotoDTOList;
            setWorkPermitDetail({...tempData});
            setDate({
                startDate: tempData?.startDate,
                endDate: tempData?.endDate,
            });
        }
    }

    const onChange = (event: string, index: number, value: WorkPermitData) => {
        const updatedData = {...workPermitDetail};
        updatedData.configuration[index] = workPermitConfigList?.filter((x: any) => x.id == event)[0];
        setWorkPermitDetail({...updatedData});
    }

    const onMetaChange = (event: string, metaId: string) => {
        workPermitDetail[metaId] = event;
        setWorkPermitDetail({...workPermitDetail})
    }

    const onChangeDate = (event: Date, metaId: string) => {
        const parsedDate = moment(event, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)");
        const milliseconds = parsedDate.valueOf();
        setDate(prev => ({
            ...prev,
            [metaId]: event,
        }));
        workPermitDetail[metaId] = milliseconds;
        setWorkPermitDetail({...workPermitDetail});
    }

    const onClickAdd = () => {
        const updatedData = {...workPermitDetail};
        if (updatedData.configuration == null) {
            updatedData.configuration = [];
        }
        updatedData.configuration.push({});
        setWorkPermitDetail({...updatedData});
    };

    const onClickAddLoto = () => {
        const updatedData = {...workPermitDetail};
        if (updatedData.lotoDTOList == null) {
            updatedData.lotoDTOList = [];
        }
        updatedData.lotoDTOList.push({});
        setWorkPermitDetail({...updatedData});
    };

    const uploadImage = async (workPermitId: string) => {
        const uploadPromises = vendorSelectedImage?.map(async (item) => {
            const formData = new FormData();
            formData.append("file", item);
            formData.append('workPermitId', workPermitId);
            await uploadWorkPermitImage(formData);
        });
        await Promise.all(uploadPromises);
    };

    const onClickSave = async () => {
        // Validate required fields
        if (!workPermitDetail.clientId) {
            enqueueSnackbar('Client is required', { variant: 'error', autoHideDuration: 3000 });
            return;
        }

        if (workPermitDetail.name == null) {
            enqueueSnackbar('Title is required', { variant: 'error', autoHideDuration: 3000 });
            return;
        }

        workPermitDetail.id = params.id;

        if (workPermitDetail.status === "APPROVED") {

            await uploadImage(workPermitDetail.id);
            await completeWorkPermit({ workPermitId: workPermitDetail.id, comments: '' }).then((r: any) => {
                if (r?.response && r?.response?.status === 400) {
                    enqueueSnackbar(r?.response?.data, { variant: 'error', autoHideDuration: 3000 });
                } else {
                    enqueueSnackbar(r.message, { variant: 'success', autoHideDuration: 3000 });
                    window.history.back();
                }
            });
        } else {
            var currentDate = Date.now();
            if(workPermitDetail.startDate == null) {
                workPermitDetail.startDate = currentDate.valueOf();
            } if (workPermitDetail.endDate == null) {
                workPermitDetail.endDate = currentDate.valueOf();
            }
            await createWorkPermit(workPermitDetail).then((r: any) => {
                    console.log("workPermitDetail");
                    console.log(workPermitDetail);
                    if (r?.response && r?.response?.status === 400) {
                        enqueueSnackbar(r?.response?.data, { variant: 'error', autoHideDuration: 3000 });
                    } else {
                        enqueueSnackbar(r.message, { variant: 'success', autoHideDuration: 3000 });
                        window.history.back();
                    }
                });


        }
    };
    const onClickPrint = async () => {
        workPermitDetail.id = params.id;
        await printWorkPermit(workPermitDetail.id);
    };

    const onClickApprove = async () => {
        await approveWorkPermit({
            workPermitId: params.id, status: 1
        }).then((r: any) => {
            if (r?.response && r?.response?.status === 400) {
                enqueueSnackbar(r?.response?.data, {variant: 'error', autoHideDuration: 3000})
            } else {
                enqueueSnackbar(r.message, {variant: 'success', autoHideDuration: 3000});
                window.history.back();
            }
        });
    };
    const onClickReject = async () => {
        await rejectWorkPermit({workPermitId: params.id, status: 1}).then((r: any) => {
            if (r?.response && r?.response?.status === 400) {
                enqueueSnackbar(r?.response?.data, {variant: 'error', autoHideDuration: 3000})
            } else {
                enqueueSnackbar(r.message, {variant: 'success', autoHideDuration: 3000});
                window.history.back();
            }
        });
    };

    function onPermitChange(index: number, data: string, metaId: string) {
        const updatedData = {...workPermitDetail};
        if (updatedData.configuration[index] == null) updatedData.configuration[index] = [];
        updatedData.configuration[index].configuration.filter((x: any) => x.name == metaId)[0].value = data;
        setWorkPermitDetail(updatedData);
    }
    function onLotoChange(index: number, data: string, metaId: string) {
        const updatedData = {...workPermitDetail};
        if (updatedData.lotoDTOList[index] == null) updatedData.lotoDTOList[index] = [];
        updatedData.lotoDTOList[index][metaId] = data;
        setWorkPermitDetail({...updatedData});
    }

    const onClickCloseWorkPermit = async () => {
        workPermitDetail.id = params.id;
        await closeWorkPermit({ workPermitId: workPermitDetail.id, comments: '' }).then((r: any) => {
            if (r?.response && r?.response?.status === 400) {
                enqueueSnackbar(r?.response?.data, {variant: 'error', autoHideDuration: 3000})
            } else {
                enqueueSnackbar(r.message, {variant: 'success', autoHideDuration: 3000});
                window.history.back();
            }
        });
    };

    const onChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if(files) {
            setVendorSelectedImage(Array.from(files));
        }
    }

    const handleRemoveSelectedImage = (index: number) => {
        setVendorSelectedImage(prev => {
            const prevImage = [...prev];
            prevImage.splice(index, 1);
            return prevImage;
        });
    }

    const downloadWorkPermitFile = async (id: string, name: string) => {
        await downloadFile(id, name);
    }

    return (
        <div>
            {
                workPermitLoading ? <Loader></Loader> :   <div>
                    <CreateWorkPermitLayout
                        clientList={clientList ?? []}
                        workPermitDetail={workPermitDetail}
                        list={workPermitConfigList ?? []}
                        date={date}
                        vendorSelectedImage={vendorSelectedImage}
                        workPermitDocumentsList={workPermitDocumentsList}
                        vendorDetails={vendorDetails}
                        onChangeDate={onChangeDate}
                        onPermitChange={onPermitChange}
                        onLotoChange={onLotoChange}
                        onClickCloseWorkPermit={onClickCloseWorkPermit}
                        onChange={onChange}
                        onClickSave={onClickSave}
                        onClickPrint={onClickPrint}
                        onClickApprove={onClickApprove}
                        onClickReject={onClickReject}
                        onMetaChange={onMetaChange}
                        onClickAdd={onClickAdd}
                        onClickAddLoto={onClickAddLoto}
                        onChangeImage={onChangeImage}
                        handleRemoveSelectedImage={handleRemoveSelectedImage}
                        downloadWorkPermitFile={downloadWorkPermitFile} />
                </div>
            }
        </div>
      );
};

export default CreateWorkPermitPage;
