import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Stack, TextField } from '@mui/material';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
};

interface AddEmployeeModalProps {
    open: boolean;
    value: {
        roleId: number,
        address: string,
        clientId: string,
        dob: string,
        email: string,
        enabled: true,
        gender: string,
        gstNo: string,
        name: string,
        password: string,
        phoneNumber: string,
        userId: number,
        username: string,
        vendorId: number
    }
    onClickPrimary: () => void;
    handleClose: () => void;

    onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeUserName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangePhoneNumber: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeAddress: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeGstNo: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeGender: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedValue: string;
    handleDropdownChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    roleDropdownValues: { id: number, name: string }[];

}

const AddEmployeeModel = ({ open, value, selectedValue, roleDropdownValues, onClickPrimary, handleClose, onChangeName, onChangeUserName, onChangePhoneNumber, onChangeEmail, onChangeAddress, onChangeGstNo, onChangePassword, onChangeGender, handleDropdownChange }: AddEmployeeModalProps) => {
    return (
        <Modal
            open={ open }
            onClose={ handleClose }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} width={400}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Create Employee
                </Typography>
                <TextField multiline={false} minRows={"1"} placeholder="Name" name="name" value={ value.name } onChange={ onChangeName } />
                <TextField multiline={false} minRows={"1"} placeholder="User Name" name="username" value={ value.username } onChange={ onChangeUserName } />
                <TextField multiline={false} minRows={"1"} placeholder="Phone Number" name="phoneNumber" value={ value.phoneNumber } onChange={ onChangePhoneNumber } />
                <TextField multiline={false} minRows={"1"} placeholder="Email" name="email" value={ value.email } onChange={ onChangeEmail } />
                <TextField multiline={false} minRows={"1"} placeholder="Address" name="address" value={ value.address } onChange={ onChangeAddress } />
                <TextField multiline={false} minRows={"1"} placeholder="Gender" name="gender" value={ value.gender } onChange={ onChangeGender } />
                <TextField multiline={false} minRows={"1"} placeholder="GST No" name="gstNo" value={ value.gstNo } onChange={ onChangeGstNo } />
                <TextField multiline={false} minRows={"1"} placeholder="Password" name="password" value={ value.password } onChange={ onChangePassword } />
                <div>
                    <select
                        value={value.roleId || ''}
                        onChange={handleDropdownChange}
                        aria-placeholder={'Role'}
                        style={{
                            width: '400px',
                            height: '50px',
                            padding: '8px',
                            borderRadius: '4px',
                            border: '1px solid #ccc'
                        }}
                    >
                        <option value="" disabled hidden>
                            Select a role
                        </option>

                        {roleDropdownValues.map((role: any) => (
                            <option key={role.id} value={role.id}>
                                {role.name}
                            </option>
                        ))}
                    </select>
                </div>
                <Stack direction="row" spacing={4}>
                    <Button style={{backgroundColor: "#3361FF"}} variant="outlined"
                            onClick={handleClose}>Cancel</Button>
                    <Button style={{backgroundColor: "#3361FF"}} variant="contained"
                            onClick={onClickPrimary}>Create</Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default AddEmployeeModel;
