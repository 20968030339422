import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../hooks/useClient";
import AppTable from "../../components/Table";
import Loader from "../../components/Loader/Loader";
import { AppRoutes } from "../../constants/EndPoint/route";
import { STORAGE_KEY } from "../../constants";
import QRCode from 'react-qr-code';

const MedicalReportList = () => {
    const { getMedicalReportList, medicalReportList, clientLoading } = useClient();
    let clientId = localStorage.getItem(STORAGE_KEY.UserId);
    const navigate = useNavigate();
    const value = 'http://colobten.com/medicalReport/new?cId=' + clientId;

    useEffect(() => {
        getMedicalReportList();
    }, []);

    const onClickMedicalReport = () => {
        navigate(AppRoutes.MEDICAL_REPORT_CREATE);
    };

    const showAndPrintQr = () => {
        navigate(AppRoutes.APP_QR,
            { state: { data: { data: value } } });
    };

    return (<div>
        {clientLoading ? <Loader></Loader> : <div>
            <Grid container justifyContent="flex-end" spacing={4}>
                <Grid item>
                    <ButtonGroup variant="text" aria-label="text button group" sx={{ padding: 1 }}>
                        <Button style={{ backgroundColor: "#3361FF", marginRight: 10 }} variant={'text'}
                            onClick={showAndPrintQr}>
                            Print QR
                        </Button>
                        <Button style={{ backgroundColor: "#3361FF" }} variant={'text'}
                            onClick={onClickMedicalReport}>
                            Create Medical Report
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <Box sx={{ width: '100%' }}>
                <AppTable listData={medicalReportList} hyperlinkPropertyName={'id'}
                    entity={'medicalReport'}></AppTable>
            </Box>
        </div>}
    </div>
    )
}

export default MedicalReportList;