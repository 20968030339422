import React, {useMemo} from "react";
import {Box, Button, ButtonGroup, Stack, Typography} from "@mui/material";
import { QuotesListProps } from"../../../hooks/useVendor";
import DetailView from "../../../components/DetailView";
import Grid from "@mui/material/Grid";
import {QuoteStatus} from "../../../constants/enum";

interface ClientQuoteDetailProps {
  quoteDetail: any;
  onClickAccept: () => void;
  onClickReject: () => void;
}


const ClientQuoteDetailLayout = ({
  quoteDetail,
  onClickAccept,
  onClickReject,
}: ClientQuoteDetailProps) => {

  return (
    <Box sx={{ width: '100%' }}>
        {quoteDetail.projectStatus === QuoteStatus.TIMELINE_SENT ? <Grid container justifyContent="flex-end" spacing={4}>
            <Grid item>
                <ButtonGroup variant="text" aria-label="text button group"  sx={{padding: 1}}>
                    <Button style={{ backgroundColor : "#3361FF", margin:1}} variant={'text'} onClick={onClickAccept}>
                        Accept Timelines
                    </Button>
                    <Button style={{ backgroundColor : "#3361FF", margin:1}} variant={'text'} onClick={onClickReject}>
                        Reject Timelines
                    </Button>
                </ButtonGroup>
            </Grid>
        </Grid> : <div></div>}
        <Typography  variant="h6"> Project Details</Typography>
      <DetailView detailData={quoteDetail} skipChildren={['user']} skipFields={['projectStatus','quoteId', 'meetingId', 'id']}/>
    </Box>
  );
};

export default ClientQuoteDetailLayout;
