import { Home, ProfilePage} from '../pages';
import WorkPermit from "../pages/WorkPermit";
import CreateWorkPermitPage from "../pages/CreateWorkPermit";
import Questionnaire from "../pages/Questionnaire";
import QuestionnaireDetails from "../pages/QuestionnaireDetails";

export const clientEmployeeRoutes = [
    { path: '/', element: <Home /> },
    { path: '/workPermit', element: <WorkPermit/> },
    { path: '/workPermit/:id', element: <CreateWorkPermitPage/> },
    { path: '/questionnaire', element: <Questionnaire/> },
    { path: '/questionnaireDetails/:id', element: <QuestionnaireDetails/> },
];
