import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {useVendor} from "../../hooks/useVendor";
import moment from "moment-timezone";
import {useClient} from "../../hooks/useClient";
import {STORAGE_KEY} from "../../constants";

const style = {
    bgcolor: 'background.paper', p: 1,
};

interface FormPageProps {
    open: boolean;
    detail: boolean;
    buttonTitle: string;
    title: string;
    onChange: (data: string, metaId: string) => void;
    onClickPrimary: () => void;
    handleClose: () => void;
    meta: any;
    data: any
    readOnly: boolean
}

const renderChild = (m: any, data: any, onChange: any, skillsList: any, clientDetails: any,readOnly:boolean, detail:boolean) => {


    let value = data[m.name] ?? m.value;

    if (m.type === "INPUT") {
        return (
            <>
                {detail && <label>{m.placeholder}</label>}
                <TextField placeholder={detail ? '' :  m.placeholder} disabled={readOnly} fullWidth={true} variant="outlined" label={detail ? '' : m.placeholder}
                           name={m.name} value={value} onChange={(e) => onChange(e.target.value, m.name)}/>
            </>
            )
    } else if (m.type === "DATE") {
        return <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                sx={{width: '100%'}}
                disablePast={true}
                label={m.placeholder}
                value={value ? new Date(value) : Date.now()}
                onChange={(e: any) => {
                    const parsedDate = moment(e, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)");
                    const milliseconds = parsedDate.valueOf();
                    console.log(milliseconds);
                    onChange(milliseconds, m.name)
                }}
                // viewRenderers={(params:any) => <TextField placeholder={m.placeholder} fullWidth={true} variant="outlined" label={m.placeholder} name={m.name} value={ value } onChange={ (e) => onChange(e.target.value, m.name) } />}
            /></LocalizationProvider>
    } else if (m.type === "DROPDOWN") {
        return <FormControl fullWidth style={{width: '400px'}}>
            <InputLabel id="demo-simple-select-label">{m.placeholder}</InputLabel>
            <Select

                fullWidth={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label="m.placeHolder"
                onChange={(e: any) => {
                    onChange(e.target.value.name, m.name);
                    onChange(e.target.value.id, "id");
                    onChange(e.target.value.image, "image");
                }}
            >
                {skillsList && skillsList.map((m: any) => {
                    return <MenuItem value={m}>{m.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    }
    else if(m.type === "OPENCLOSE") {
        return <FormControl fullWidth style={{width: '100%'}}>
            <InputLabel id="demo-simple-select-label">{m.placeholder}</InputLabel>
            <Select

                fullWidth={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label="m.placeHolder"
                onChange={ (e:any) => {
                    console.log(e);
                    onChange(e.target.value, "status");
                } }
            >
                {[{name: 'Open', id: 0},{name: 'Close', id: 1}].map((m:any) => {
                    return <MenuItem value={m.id}>{m.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    }
    else if (m.type === "MULTIDROPDOWN") {
        return <FormControl style={{width: '100%'}}>
            <InputLabel id="demo-simple-select-label">{m.placeholder}</InputLabel>
            <Select
                multiple={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value ?? []}
                label="m.placeHolder"
                onChange={(e: any) => {
                    let selectedValues = value ?? [];
                    console.log(e.target.value)
                    onChange(e.target.value, m.name);
                }}
            >
                {clientDetails && clientDetails.map((m: any) => {
                    return <MenuItem value={m}>{m.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    } else if (m.type === "AMOUNT") {
        return (<TextField type={'number'} placeholder={m.placeholder} fullWidth={true} variant="outlined"
                           label={m.placeholder} name={m.name} value={value}
                           onChange={(e) => onChange(e.target.value, m.name)}/>)

    }
    return (<div></div>)
}
const FormPage = ({open, buttonTitle,detail, title, onChange, onClickPrimary, handleClose, meta, data, readOnly = false}: FormPageProps) => {
    const {fetchSkillsList, skillsList, getFindVendor} = useVendor();
    const {getClientDetails, clientDetails} = useClient();

    useEffect(() => {
        fetchSkillsList();
        const clientId = localStorage.getItem(STORAGE_KEY.UserId);
        getClientDetails({clientId: clientId ?? ''});
    }, [fetchSkillsList])

    return (<Box sx={style}>
            <Typography variant="h6" component="h2">
                {title}
            </Typography>
            <Grid container spacing={4} paddingTop={3}>
                {meta.map((m: any) => {
                    return <Grid key={m.name} item xs={12} sm={12} lg={6} xl={6}>
                        {renderChild(m, data, onChange, skillsList, clientDetails,readOnly,detail)}
                    </Grid>
                })}
            </Grid>
        </Box>);
};

export default FormPage;
