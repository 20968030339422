import React, {useEffect, useMemo, useState} from "react";
import {Box, Button, ButtonGroup, Stack, Typography} from "@mui/material";
import { QuotesListProps } from"../../../hooks/useVendor";
import DetailView from "../../../components/DetailView";
import Grid from "@mui/material/Grid";
import AppTable from "../../../components/Table";
import {QuoteStatus} from "../../../constants/enum";
import TextField from "@mui/material/TextField";

interface VendorQuoteDetailLayoutProps {
  quoteDetail: any;
    projectDocuments: any;
    onSendTimeline: () => void;
    onDocumentOpen: (id: any) => void,
}


const VendorProjectTimeline = ({
  quoteDetail,
  onSendTimeline,
    projectDocuments,
    onDocumentOpen
}: VendorQuoteDetailLayoutProps) => {

    const [description, setDescription] = useState();

    useEffect(() => {
        setDescription(quoteDetail.description)
    },[quoteDetail])

    const canSendTimeline = () => {
        return !(quoteDetail.projectStatus === QuoteStatus.TIMELINE_ACCEPTED || quoteDetail.projectStatus === QuoteStatus.TIMELINE_SENT);
    };

    return (
    <Box sx={{ width: '95%' , padding: 4 }}>
        <AppTable listData={projectDocuments}
                  hiddenFields={["content"]}
                  hyperlinkPropertyName={""} onClick={onDocumentOpen}
                  entity={"project_document"}/>
        {canSendTimeline() ?   <Grid container justifyContent="flex-end" spacing={4}>
            <Grid item>
                <ButtonGroup variant="text" aria-label="text button group"  sx={{padding: 1}}>
                    <Button style={{ backgroundColor : "#3361FF"}} variant={'text'} onClick={onSendTimeline}>
                        Send Timeline
                    </Button>
                </ButtonGroup>
            </Grid>
        </Grid> : <div></div>}
        <Typography  variant="h6"> Project Details</Typography>
      {/*<DetailView detailData={quoteDetail} skipChildren={['vendor', 'user', 'timelines']} skipFields={['status', 'meetingId', 'id']}/>*/}
        <AppTable enabledEdit={canSendTimeline()} onUpdate={(val:any, index: any) => quoteDetail.timelines[index] = val} listData={quoteDetail.timelines} entity={'timelines'} hiddenFields={['id']} hyperlinkPropertyName={''}/>
        <Typography  variant="h6"> Description</Typography>
        <TextField aria-readonly={!canSendTimeline()} multiline={true} minRows={10} sx={{ width: { xs: '100%', sm: '100%', lg: '50%' } }} value={ description } onChange={ (e:any) => {
            if(canSendTimeline()) {
                quoteDetail.description = e.target.value;
                setDescription(e.target.value);
            }
          } } placeholder={ 'Description' } />

    </Box>
  );
};

export default VendorProjectTimeline;
