import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { VendorData } from '../../hooks/useVendor';

interface CardContainerProps {
  data: VendorData;
}

const CardContainer = ({ data }: CardContainerProps) => {
  return (
    <Card sx={{ width: '100%', cursor: 'pointer' }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          { data?.username || '-' }
        </Typography>
        <Typography variant="body2" color="text.secondary">
          First Name: <b>{ data?.firstName || '-' }</b>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Last Name: <b>{ data?.lastName || '-' }</b>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Email: <b>{ data?.email || '-' }</b>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Gender: <b>{ data?.gender || '-' }</b>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Phone: <b>{ data?.phoneNumber || '-' }</b>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardContainer;