import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { WorkPermitData } from 'hooks/useWorkPermit';

interface DropDownProps {
    list: WorkPermitData[];
    value: string;
    label: string;
    onChange: (event: string) => void;
    disabled? :boolean
}

const DropDown = ({ list, value, label, onChange,disabled }: DropDownProps) => {
  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
            disabled={disabled}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={(event) => onChange(event.target.value as string)}
        >
          { list?.map(item => <MenuItem key={item.id} value={item.id}>{ item.name }</MenuItem> ) }
        </Select>
      </FormControl>
    </Box>
  );
}

export default DropDown;
