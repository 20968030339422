// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-container {
    border: 2px solid #333; /* Customize the border color and width */
    padding: 20px;
    margin: 20Px/* Add padding to create space between the border and content */
}
`, "",{"version":3,"sources":["webpack://./src/pages/VendorProfile/VendorProfile.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB,EAAE,yCAAyC;IACjE,aAAa;IACb,WAAW,CAAC,+DAA+D;AAC/E","sourcesContent":[".page-container {\n    border: 2px solid #333; /* Customize the border color and width */\n    padding: 20px;\n    margin: 20Px/* Add padding to create space between the border and content */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
