import React from 'react';

interface AppToggleModalProps {
    handleToggleChange : any;
    isChecked: any;
    toggleValue: any;
}

const AppToggle = ({  handleToggleChange ,isChecked,toggleValue}: AppToggleModalProps) => {
    return (
                            <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                                <label style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    width: '60px',
                                    height: '34px'
                                }}>
                                    <input type="checkbox" value={toggleValue} checked={isChecked} onChange={handleToggleChange}/>
                                    <span style={{
                                        position: 'absolute',
                                        cursor: 'pointer',
                                        top: '0',
                                        left: '0',
                                        right: '0',
                                        bottom: '0',
                                        backgroundColor: isChecked ? '#2196F3' : '#ccc',
                                        transition: '0.4s',
                                        borderRadius: '34px'
                                    }}></span>
                                    <span style={{
                                        position: 'absolute',
                                        content: '""',
                                        height: '26px',
                                        width: '26px',
                                        left: isChecked ? '30px' : '4px',
                                        bottom: '4px',
                                        backgroundColor: 'white',
                                        transition: '0.4s',
                                        borderRadius: '50%',
                                        transform: isChecked ? 'translateX(-0.5px)' : 'none'
                                    }}></span>
                                </label>
                            </div>
    );
};

export default AppToggle;
