import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardListGrid } from '../../layouts';
import { Box } from '@mui/material';
import {VendorData, useVendor, SkillsListProps} from '../../hooks/useVendor';

const Vendor = () => {
  const { vendorList, vendorLoading, fetchVendorList } = useVendor();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    fetchVendorList();
  }, [fetchVendorList]);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }

  const onClickCard = (event: VendorData) => {
    navigate('/vendor/details', { state: { data: event } });
  }

  return (
    <Box sx={{ padding: '30px' }}>
      <h1>Vendor List</h1>
      <CardListGrid
        data={ vendorList || [] }
        value={ search }
        placeholder="Search..."
        onChange={ onChangeSearch }
        onClickCard={ onClickCard } />
    </Box>
  )
}

export default Vendor;
