import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import colorConfigs from "../../config/colorConfigs";
import sizeConfigs from "../../config/sizeConfigs";
import MenuIcon from "@mui/icons-material/Menu";
import colobten from "../../assets/images/colobten.jpeg";
import {AccountCircle, Logout, Person2} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, {useState} from "react";
import {STORAGE_KEY} from "../../constants";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";

const Topbar = (props: {onMenuClick :any, isOpen: boolean}) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchInput, setSearchInput] = useState("");



    const handleClick = (event : any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        localStorage.removeItem(STORAGE_KEY.AuthToken);
        localStorage.removeItem(STORAGE_KEY.UserDetails);
        navigate('/login', { replace: true });
        enqueueSnackbar("Logout Successfully", { variant: 'success',autoHideDuration: 3000 } )
    };

    const onClickProfile = () => {
        handleClose();
        navigate('/vendorProfile', );
    };


  return (
    <AppBar
      position="fixed"
      sx={{
        width: props.isOpen ?  `calc(100% - ${sizeConfigs.sidebar.width})` : null,
        ml: sizeConfigs.sidebar.width,
        boxShadow: "unset",
          backgroundColor : "#3361FF",
        color: colorConfigs.topbar.color
      }}
    >
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <IconButton onClick={props.onMenuClick}>
                <MenuIcon style={{height : '45px',width : '45px', color : "white"}}/>
            </IconButton>
            <div className="material-ui-menu">
                <IconButton onClick={handleClick}>
                    <AccountCircle style={{height : '45px',width : '45px', color : "white"}}/>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={onClickProfile}>
                        <IconButton>
                            <Person2 fontSize="small" />
                        </IconButton>
                        Profile
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                        <IconButton>
                            <Logout fontSize="small" />
                        </IconButton>
                        Logout
                    </MenuItem>
                </Menu>
            </div>
        </Toolbar>
    </AppBar>
  );
};

export default Topbar;
