import React, {useState} from 'react'
import {Avatar, Box, Button, Grid, Stack, Typography} from '@mui/material';
import {CardContainer, FilterContainer, RequestVendorModal} from '../../components';
import {useVendor, VendorData} from '../../hooks/useVendor';
import AppTable from "../../components/Table";
import {LinkedFunction} from "../../constants/LinkedFunction";
import {QuoteStatus} from "../../constants/enum";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Architecture, Home, SocialDistance} from '@mui/icons-material';


interface CardListGridProps {
    value: string;
    placeholder: string;
    data: any[];
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClickCard: (event: any) => void;
}


const getRandomColor = () => {
    const colors = ['#FF5733', '#338DFF', '#9A33FF'];
    return colors[Math.floor(Math.random() * colors.length)];
};


const CardListGrid = ({
    placeholder,
    data,
    onChange,
    onClickCard,
}: CardListGridProps) => {



    return (
    <Box>
        <Grid container spacing={2}>
            {data.map((card) => (
                <Grid item xs={12} sm={2} key={card.id}>
                    <Card onClick={ () =>onClickCard (card.id)}>
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src= {card.image} style={{width: 64, height: 64, marginBottom: 2 }}   alt={'Image'}/>
                            <Typography variant="h5" component="div"  align="center">
                                {card.name}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    </Box>
  )
}

export default CardListGrid;
