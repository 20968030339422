import { RouteType } from "./config";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import {
    BadgeRounded,
    DashboardSharp,
    FindInPageSharp,
    Groups3,
    MedicalServices,
    WorkHistoryRounded
} from "@mui/icons-material";
import ProjectList from "../pages/ProjectList";
import VendorQuotes from "../pages/VendorQuotes";
import FindVendor from "../pages/FindVendor";
import VendorList from "../pages/VendorList";
import EmployeeList from "../pages/EmployeeList";
import MedicalReportList from "../pages/MedicalReportList";
import {AppRoutes} from "../constants/EndPoint/route";

const clientRoute: RouteType[] = [
    {
        index: true,
        element: <FindVendor />,
        state: "findVendor"
    },
    {
        path: "/",
        element: <FindVendor />,
        state: "findVendor",
        sidebarProps: {
            displayText: "Dashboard",
            icon: <DashboardSharp />
        }
    },
    {
        path: "/vendorList",
        element: <VendorList />,
        state: "vendor",
        sidebarProps: {
            displayText: "Vendor",
            icon: <Groups3 />
        }
    },
    {
        path: "/quotes",
        element: <VendorQuotes />,
        state: "quotes",
        sidebarProps: {
            displayText: "Quotes",
            icon: <ArticleOutlinedIcon />
        }
    },
    {
        path: "/projects",
        element: <ProjectList />,
        state: "projects",
        sidebarProps: {
            displayText: "Project",
            icon: <FormatListBulletedOutlinedIcon />
        }
    },
    {
        path: "/employee",
        element: <EmployeeList />,
        state: "employee",
        sidebarProps: {
            displayText: "Employee",
            icon: <BadgeRounded />
        }
    },
    {
        path: "/workPermit",
        element: <EmployeeList />,
        state: "workPermit",
        sidebarProps: {
            displayText: "Work Permit",
            icon: <WorkHistoryRounded />
        }
    },
    {
        path: AppRoutes.MEDICAL_REPORT,
        element: <MedicalReportList />,
        state: "medical-report",
        sidebarProps: {
            displayText: "Medical Report",
            icon: <MedicalServices />
        }
    }
];

export default clientRoute;
