import React, {useMemo} from "react";
import {Box, Button,  Stack} from "@mui/material";
import { Tab, TabPanel} from "../../../components";
import { QuotesListProps } from"../../../hooks/useVendor";
import { QuoteRequestProps } from"../../../components/QuoteCardContainer";
import AppTable from"../../../components/Table";
import {QuoteStatus} from"../../../constants/enum";
import {LinkedFunction} from"../../../constants/LinkedFunction";

interface QuotesLayoutProps {
  clientQuotesList: QuotesListProps[];
  onClickAccept: (event: QuoteRequestProps) => void;
  onClickReject: (event: QuoteRequestProps) => void;
}


const ClientQuotesLayout = ({
  clientQuotesList,
  onClickAccept,
  onClickReject,
}: QuotesLayoutProps) => {

  LinkedFunction.QUOTE_ACCEPT_REJECT = (row: any = {}) => {
    console.log(row)
    if (row?.status === QuoteStatus.PENDING ||
        (row?.status === QuoteStatus.TIMELINE_SENT) ) {
      return (
          <Stack direction="row" spacing={ 2 }>
            <Button style={{ backgroundColor : "#3361FF"}} onClick={ () => onClickAccept(row) }>Accept</Button>
            <Button style={{ backgroundColor : "#3361FF"}} onClick={ () => onClickReject(row) }>Reject</Button>
          </Stack>
      );
    }
    if(row?.status === QuoteStatus.ACCEPTED) {
      return <Button onClick={ () => {} }>Send Timeline</Button>;
    }
  }
  const [tabValue, setTabValue] = React.useState<number>(0);

  const onChangeTab = (event: React.SyntheticEvent, index: number) => {
    setTabValue(index);
  };

  const processData = (data: any[]) => {
      return data.map((d) => {
          if(d.vendor != null) {
              d.companyName = d.vendor.name;
          }
          return d;
      })
    }

  let pendingWithMe = processData(clientQuotesList.filter((q) => q.status === 'TIMELINE_SENT'));
  let pendingWithVendor = clientQuotesList.filter((q) => q.status !== 'TIMELINE_SENT');
  const tabList = useMemo(() => {
    return [ 'Pending with Me', 'Pending with Vendor'];
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Tab data={ tabList } value={ tabValue } onChangeTab={ onChangeTab } />
      <TabPanel value={ tabValue } index={ 0 }>
        <AppTable listData={pendingWithMe} hyperlinkPropertyName="id" entity='quotes' hiddenFields={['meetingId', 'amount', 'status']}/>
      </TabPanel>
      <TabPanel value={ tabValue } index={ 1 }>
        <AppTable listData={pendingWithVendor} hyperlinkPropertyName="id" entity='quotes' />
      </TabPanel>
    </Box>
  );
};

export default ClientQuotesLayout;
