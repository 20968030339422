import React from "react";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import DatePickerContainer from "../DatePickerContainer";

export interface TimelineInputData {
    amount: string;
    description: string;
    title: string;
    startDate: Date | null;
    endDate: Date | null;
}

interface AddMoreTimelineProps {
    timelineInput: TimelineInputData[];
    onChange: (index: number, name: keyof TimelineInputData, event: Date | string | null) => void;
    onClickAddMore: () => void;
}

const AddMoreTimeline = ({ timelineInput, onChange, onClickAddMore }: AddMoreTimelineProps) => {
  return (
    <Box>
      <Typography sx={{ marginBottom: '10px' }}>Enter Timeline:</Typography>
        {timelineInput.map((input, index) => (
          <Grid container spacing={ 4 } key={index}>
            <Grid xs={ 12 } sx={{ display: 'flex', gap: '20px' }} item spacing={ 2 }>
              <TextField
                type="number"
                sx={{ width: '100%' }}
                placeholder="Amount"
                value={input.amount}
                onChange={(e) => onChange(index, "amount", e.target.value)}
              />
              <TextField
                sx={{ width: '100%' }}
                placeholder="description"
                value={input.description}
                onChange={(e) => onChange(index, "description", e.target.value)}
              />
              <TextField
                sx={{ width: '100%' }}
                placeholder="title"
                value={input.title}
                onChange={(e) => onChange(index, "title", e.target.value)}
              />
            </Grid>
            <Grid xs={ 12 } sx={{ display: 'flex', gap: '20px' }} item spacing={ 2 }>
              <DatePickerContainer  selectedDate={ input.startDate } onChangeDate={ (e) => onChange(index, "startDate", e) } />
              <DatePickerContainer selectedDate={ input.endDate } onChangeDate={ (e) => onChange(index, "endDate", e) } />
            </Grid>
          </Grid>
        ))}
      <Stack justifyContent="flex-end" alignItems="flex-end">
        <Button sx={{ marginTop: '20px' }} onClick={ onClickAddMore }>Add More</Button>
      </Stack>
    </Box>
  );
};

export default AddMoreTimeline;
