import React, {useEffect, useState} from 'react';
import {useProject} from '../../hooks/useProject';
import {useUser} from '../../provider/UserProvider';
import {Box, Button, Stack} from '@mui/material';
import {UserRole} from '../../constants/enum';
import {metData} from "../../constants/meta";
import {useSnackbar} from "notistack";
import FormPage from "../../components/FormPage";
import ProjectQuestionnaire from "../../components/ProjectQuestionnaire";

const CreateProject = () => {
    const {userData} = useUser();
    const {getProjectQuesList, projectQuesList} = useProject();
    const {
        fetchClientProjectList, fetchVendorProjectList, createProject
    } = useProject();
    const [open, setOpen] = useState<boolean>(false);
    const {enqueueSnackbar} = useSnackbar();
    const [questionnaire, setQuestionnaire] = useState<any>({});
    const [idValueMap, setIdValueMap] = useState<any>({});

    const [value, setValue] = useState<any>({
        description: '', startDate: Date.now(), endDate: Date.now(), amount: 0,
    });

    useEffect(() => {
        getProjectQuesList();
    }, []);

    useEffect(() => {
        if (userData.role?.includes(UserRole.ROLE_CLIENT)) {
            fetchClientProjectList();
        }
        if (userData.role?.includes(UserRole.ROLE_VENDOR)) {
            fetchVendorProjectList();
        }
    }, [userData, fetchClientProjectList, fetchVendorProjectList]);


    const onClickCreateVendor = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const onQuestChange = (quest: any, map:any) => {
        setQuestionnaire({...quest});
        setIdValueMap({...map})
    }


    const onCreateProject = async (projectData: any) => {
        if (projectData.vendor == null ) {
            enqueueSnackbar("Please select a vendor", {variant: 'error', autoHideDuration: 3000})
            return;
        }
        projectData.vendorId = projectData.vendor?.map((v: any) => v.id);
        projectData.questionnaire = questionnaire;
        await createProject(projectData).then(r => {
            enqueueSnackbar(r.message, {variant: 'success', autoHideDuration: 3000})
        });
        setOpen(false);
    };

    const onCreateFormChange = (data: any, metaId: string) => {
        value[metaId] = data;
        setValue({...value});
    }

    return (<Box>
        <FormPage readOnly={false} buttonTitle={'Create'} title={'Create Project'}
                  detail={false}
                  onChange={onCreateFormChange}
                  data={value}
                  meta={metData.createProject}
                  handleClose={onCloseModal}
                  open={true}
                  onClickPrimary={() => {
                      onCreateProject(value);
                  }}/>

        <ProjectQuestionnaire questionnaire={questionnaire} idValueMap={idValueMap} questList={projectQuesList} onQuestChange={onQuestChange}/>

        <Stack direction="row" spacing={4} paddingTop={3}>
            <Button style={{backgroundColor: "#3361FF"}} variant="text" fullWidth={true}
                    onClick={onCloseModal}>Cancel</Button>
            <Button variant="contained" style={{backgroundColor: "#3361FF"}} fullWidth={true}
                    onClick={() => onCreateProject(value)}>{'Create'}</Button>
        </Stack>
    </Box>)
}

export default CreateProject
