import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AccessDenied, Login, SignUp } from '../pages';
import {clientRoutes} from './ClientRoutes';
import { vendorRoutes } from './VendorRoutes';
import { useUser } from '../provider/UserProvider';
import { UserRole } from '../constants/enum';
import ProtectedRoute from './ProtectedRoute';
import {clientEmployeeRoutes} from "./ClientEmployeeRoutes";
import MedicalReportUpsert from "../pages/MedicalReportUpsert";
import {AppRoutes} from "../constants/EndPoint/route";
import Onboarding from 'pages/Onboarding';

const Routers = () => {
  const { userData } = useUser();
  return (
    <Routes>
      { userData?.role?.includes(UserRole.ROLE_CLIENT) && clientRoutes?.map((route) =>
        <Route
          key={ route.path }
          path={ route.path }
          element={ <ProtectedRoute isAllowed={ !!userData?.role }>{ route.element }</ProtectedRoute> } />
      ) }
      { userData?.role?.includes(UserRole.ROLE_VENDOR) && vendorRoutes?.map((route) =>
        <Route
          key={ route.path }
          path={ route.path }
          element={ <ProtectedRoute isAllowed={ !!userData?.role }>{ route.element }</ProtectedRoute> } />
      ) }
        { userData?.role?.includes(UserRole.CLIENT_EMPLOYEE) && clientEmployeeRoutes?.map((route) =>
            <Route
                key={ route.path }
                path={ route.path }
                element={ <ProtectedRoute isAllowed={ !!userData?.role }>{ route.element }</ProtectedRoute> } />
        ) }
      <Route path="/login" element={<Login />} />
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="/signUp" element={<SignUp />} />
      <Route path={AppRoutes.MEDICAL_REPORT_UPSERT} element={<ProtectedRoute isAllowed={ true }><MedicalReportUpsert /></ProtectedRoute>} />
      <Route path="/accessDenied" element={ <ProtectedRoute isAllowed={ !!userData?.role }><AccessDenied /></ProtectedRoute> } />
      <Route path="*" element={<ProtectedRoute isAllowed={ !!userData?.role }><AccessDenied /></ProtectedRoute>} />
    </Routes>
  )
}

export default Routers;
