import React, { useEffect } from 'react';
import { useClient } from 'hooks/useClient';
import { ClientQuotesLayout } from 'layouts';
import { QuoteRequestProps } from 'components/QuoteCardContainer';

const ClientQuotes = () => {

  const { clientQuoteList, fetchClientQuotesList, acceptTimelineRequest, rejectTimelineRequest } = useClient();

  const acceptRequest = async (event: QuoteRequestProps) => {
    try {
      await acceptTimelineRequest({
        quoteId: event.id,
      });
      fetchClientQuotesList();
    } catch (error) {
      console.log(error);
    }
  }

  const rejectRequest = async (event: QuoteRequestProps) => {
    try {
      await rejectTimelineRequest({
        quoteId: event.id,
      });
      fetchClientQuotesList();
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchClientQuotesList();
  }, [fetchClientQuotesList]);

  return (
    <div>
      <ClientQuotesLayout onClickAccept={ acceptRequest } onClickReject={ rejectRequest } clientQuotesList={ clientQuoteList || [] } />
    </div>

  )
}

export default ClientQuotes;
