import {useCallback, useEffect, useMemo, useState} from 'react';
import {AxiosInstance} from 'axios';
import getApiConfig from '../config/apiConfig';
import {workPermitEndpoint} from "../constants/EndPoint/workPermit";
import {clientEndPoint} from "../constants/EndPoint";
import {CreateWorkPermitRequestProps} from "./useClient";

export interface WorkPermitData {
    name: string;
    value: string;
    id: string;
    createdDate: string;
    configuration: any;
}

export interface Loto {
    sno: string;
    description: string;
    status: number;
}

export interface WorkpermitStatusProps {
    comments: string;
    workPermitId: string;
}

export interface WorkpermitDocumentsProps {
    workPermitId: string;
}

export interface WorkPermitDocumentListProps {
    content: string;
    fileName: string;
    fileType: string;
    id: string;
}

export const useWorkPermit = () => {
    const apiConfig: AxiosInstance = getApiConfig();

    const [workPermitConfigList, setWorkPermitConfigList] = useState<WorkPermitData[]>();
    const [workPermitDocumentsList, setWorkPermitDocumentsList] = useState<WorkPermitDocumentListProps[]>([]);
    const [workPermitLoading, setWorkPermitLoading] = useState<boolean>(false);
    const [workPermitError, setWorkPermitError] = useState<boolean>(false);
    const [isReload, setIsReload] = useState<boolean>(false);

    const getWorkPermit = useCallback(async () => {
        try {
            setWorkPermitLoading(true);
            setWorkPermitError(false);
            const response = await apiConfig.get(workPermitEndpoint.GET_ALL_CONFIG);
            const createWorkPermitResponse = response?.data;
            setWorkPermitConfigList(createWorkPermitResponse.map((x: any) => {
                x.configuration = JSON.parse(x.configuration);
                return x;
            }));
            setWorkPermitLoading(false);
            return createWorkPermitResponse;
        } catch (e) {
            setWorkPermitError(true);
            setWorkPermitLoading(false);
            setWorkPermitConfigList([]);
            return null;
        }
    }, [apiConfig]);

    const fetchWorkPermit = useCallback(() => {
        setIsReload(true);
    }, []);

    const createWorkPermit = useCallback(async (payload: CreateWorkPermitRequestProps) => {
        try {
            setWorkPermitLoading(false);
            let details = {...payload};
            details.configuration = JSON.stringify(details.configuration)
            details.vendorId = Number.parseInt(details.vendorId)
            const response = await apiConfig.post(clientEndPoint.CLIENT_CREATE_WORK_PERMIT, details);
            setWorkPermitLoading(true);
            getWorkPermit();
            return response?.data;
        } catch (error: any) {
            setWorkPermitLoading(false);
            return error;
        }
    }, [apiConfig]);

    const approveWorkPermit = useCallback(async (payload: any) => {
        try {
            setWorkPermitLoading(false);
            let details = {...payload};
            details.configuration = JSON.stringify(details.configuration)
            const response = await apiConfig.post(clientEndPoint.CLIENT_APPROVE_WORK_PERMIT, details);
            setWorkPermitLoading(true);
            getWorkPermit();
            return response?.data;
        } catch (error: any) {
            setWorkPermitLoading(false);
            return error;
        }
    }, [apiConfig]);

    const rejectWorkPermit = useCallback(async (payload: any) => {
        try {
            setWorkPermitLoading(false);
            let details = {...payload};
            details.configuration = JSON.stringify(details.configuration)
            const response = await apiConfig.post(clientEndPoint.CLIENT_REJECT_WORK_PERMIT, details);
            setWorkPermitLoading(true);
            getWorkPermit();
            return response?.data;
        } catch (error: any) {
            setWorkPermitLoading(false);
            return error;
        }
    }, [apiConfig]);

    const completeWorkPermit = useCallback(async (payload: WorkpermitStatusProps) => {
        try {
            setWorkPermitLoading(true);
            const response = await apiConfig.post(workPermitEndpoint.COMPLETE, payload);
            getWorkPermit();
            setWorkPermitLoading(false);
            return response?.data;
        } catch (error: any) {
            setWorkPermitLoading(false);
            return error;
        }
    }, [apiConfig]);

    const closeWorkPermit = useCallback(async (payload: WorkpermitStatusProps) => {
        try {
            setWorkPermitLoading(true);
            const response = await apiConfig.post(workPermitEndpoint.CLOSED, payload);
            getWorkPermit();
            setWorkPermitLoading(false);
            return response?.data;
        } catch (error: any) {
            setWorkPermitLoading(false);
            return error;
        }
    }, [apiConfig]);

    const uploadWorkPermitImage = useCallback(async (payload: FormData) => {
        try {
            setWorkPermitLoading(true);
            const response = await apiConfig.post(workPermitEndpoint.UPLOAD_IMAGE, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setWorkPermitLoading(false);
            return response?.data;
        } catch (error: any) {
            setWorkPermitLoading(false);
            return error;
        }
    }, [apiConfig]);

    const printWorkPermit = useCallback(async (id: any) => {
        try {
            setWorkPermitLoading(true);
            window.open(apiConfig.defaults.baseURL + workPermitEndpoint.PRINT+"/"+id);
            // const response = await apiConfig.get(workPermitEndpoint.PRINT+"/"+id, {
            //     headers: {
            //         'Content-Type': 'application/pdf'
            //     }
            // });
            setWorkPermitLoading(false);

        } catch (error: any) {
            setWorkPermitLoading(false);
            return error;
        }
    }, [apiConfig]);

    const getWorkPermitDocuments = useCallback(async (payload: WorkpermitDocumentsProps) => {
        try {
            setWorkPermitLoading(true);
            setWorkPermitError(false);
            const response = await apiConfig.get(workPermitEndpoint.GET_ALL_DOCUMENT, {
                params: payload,
            });
            const workPermitResponse = response?.data?.data;
            setWorkPermitDocumentsList(workPermitResponse);
            setWorkPermitLoading(false);
            return workPermitResponse;
        } catch (e) {
            setWorkPermitError(true);
            setWorkPermitLoading(false);
            setWorkPermitDocumentsList([]);
            return null;
        }
    }, [apiConfig]);

    useEffect(() => {
        if (isReload) {
            getWorkPermit();
            setIsReload(false);
        }
    }, [isReload, getWorkPermit, createWorkPermit, approveWorkPermit, rejectWorkPermit]);

    const values = useMemo(() => {
        return {
            workPermitConfigList,
            workPermitDocumentsList,
            workPermitLoading,
            workPermitError,
            fetchWorkPermit,
            createWorkPermit,
            approveWorkPermit,
            rejectWorkPermit,
            completeWorkPermit,
            closeWorkPermit,
            uploadWorkPermitImage,
            getWorkPermitDocuments,
            printWorkPermit,
        }
    }, [workPermitConfigList,
        workPermitLoading,
        workPermitError,
        workPermitDocumentsList,
        fetchWorkPermit,
        createWorkPermit,
        approveWorkPermit,
        rejectWorkPermit,
        completeWorkPermit,
        closeWorkPermit,
        uploadWorkPermitImage,
        getWorkPermitDocuments,
        printWorkPermit,
    ]);

    return values;
};
