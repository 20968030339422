import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { QuotesListProps } from '../../hooks/useVendor';
import { Button, Stack } from '@mui/material';
import { QuoteStatus, UserRole } from '../../constants/enum';
import { useUser } from '../../provider/UserProvider';

export interface QuoteRequestProps {
  id: string;
  description: string;
}

interface CardContainerProps {
  data: QuotesListProps;
  showButton?: boolean;
  onClickSendTimeline?: () => void;
  onClickAccept?: (event: QuoteRequestProps) => void;
  onClickReject?: (event: QuoteRequestProps) => void;
}

const QuoteCardContainer = ({ data, showButton, onClickAccept, onClickReject, onClickSendTimeline }: CardContainerProps) => {
  const { userData } = useUser();
  const payload = {
    id: data?.id,
    description: data?.description,
  };
  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Typography variant="h5" color="text.secondary">
          Name: <b>{ `${ data?.user?.firstName } ${ data?.user?.lastName }` }</b>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Email: <b>{ data?.user?.email || '-' }</b>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Gender: <b>{ data?.user?.gender || '-' }</b>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Phone: <b>{ data?.user?.phoneNumber || '-' }</b>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Status: <b>{ data?.status || '-' }</b>
        </Typography>
        { (showButton && onClickAccept && onClickReject &&
          (data?.status === QuoteStatus.PENDING ||
          (data?.status === QuoteStatus.TIMELINE_SENT && !userData.role?.includes(UserRole.ROLE_VENDOR)))) && (
          <Stack direction="row" spacing={ 2 }>
            <Button onClick={ () => onClickAccept(payload) }>Accept</Button>
            <Button onClick={ () => onClickReject(payload) }>Reject</Button>
          </Stack>
        ) }
        { (data?.status === QuoteStatus.ACCEPTED && onClickSendTimeline) && (
          <Button onClick={ onClickSendTimeline }>Send Timeline</Button>
        ) }
      </CardContent>
    </Card>
  );
};

export default QuoteCardContainer;