import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Stack, TextField } from '@mui/material';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
};

interface AddVendorModalProps {
    open: boolean;
    value: {
        address: string;
        email: string;
        gstNo: string;
        name: string;
        password: string;
        phoneNumber: string;
        username: string;
        enabled: boolean;
    }
    onClickPrimary: () => void;
    handleClose: () => void;

    onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeUserName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangePhoneNumber: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeAddress: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeGstNo: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;

}

const AddVendorModal = ({ open, value,  onClickPrimary, handleClose,onChangeName,onChangeUserName,onChangePhoneNumber ,onChangeEmail,onChangeAddress,onChangeGstNo,onChangePassword}: AddVendorModalProps) => {
    return (
        <Modal
            open={ open }
            onClose={ handleClose }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} width={400}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Create Vendor
                </Typography>
                <TextField multiline={false} minRows={"1"} placeholder="Name" name="name" value={ value.name } onChange={ onChangeName } />
                <TextField multiline={false} minRows={"1"} placeholder="User Name" name="username" value={ value.username } onChange={ onChangeUserName } />
                <TextField multiline={false} minRows={"1"} placeholder="Phone Number" name="phoneNumber" value={ value.phoneNumber } onChange={ onChangePhoneNumber } />
                <TextField multiline={false} minRows={"1"} placeholder="Email" name="email" value={ value.email } onChange={ onChangeEmail } />
                <TextField multiline={false} minRows={"1"} placeholder="Address" name="address" value={ value.address } onChange={ onChangeAddress } />
                <TextField multiline={false} minRows={"1"} placeholder="GST No" name="gstNo" value={ value.gstNo } onChange={ onChangeGstNo } />
                <TextField multiline={false} minRows={"1"} placeholder="Default Password" name="password" value={ value.password } onChange={ onChangePassword } />
                <Stack direction="row" spacing={ 4 } >
                    <Button style={{ backgroundColor : "#3361FF"}}  variant="outlined" onClick={ handleClose }>Cancel</Button>
                    <Button style={{ backgroundColor : "#3361FF"}}  variant="contained" onClick={ onClickPrimary }>Create</Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default AddVendorModal;
