import { RouteType } from "./config";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { MessageOutlined, WorkHistoryRounded } from "@mui/icons-material";
import VendorProfile from "../pages/VendorProfile";
import WorkPermit from "../pages/WorkPermit";
import { STORAGE_KEY } from "../constants";
import MessageBox from "../pages/Questionnaire";
import Questionnaire from "../pages/Questionnaire";

const clientEmployeeRoute: RouteType[] = [];


clientEmployeeRoute.push({
    path: "/",
    element: <VendorProfile />,
    state: "home",
    sidebarProps: {
        displayText: "Home",
        icon: <DashboardOutlinedIcon />
    }
});

clientEmployeeRoute.push({
    path: "/workPermit",
    element: <WorkPermit />,
    state: "employee",
    sidebarProps: {
        displayText: "Work Permit",
        icon: <WorkHistoryRounded />
    }
});

const checkLocalStorage = () => {
    const roleName = localStorage.getItem(STORAGE_KEY.RoleName);
    console.log("Raw roleName from localStorage:", roleName);

    const trimmedRoleName = roleName && roleName.replace(/"/g, "").trim();

    console.log("Actual roleName:", roleName);
    console.log("Trimmed roleName:", trimmedRoleName);

    if (trimmedRoleName == "SUPERVISOR") {
        clientEmployeeRoute.push({
            path: "/questionnaire",
            element: <Questionnaire />,
            state: "questionnaireDetails",
            sidebarProps: {
                displayText: "Questionnaire",
                icon: <MessageOutlined />
            }
        });
    } else {
        console.log("Role does not match 'SUPERVISOR' or roleName is null/undefined or not a string");
        if (trimmedRoleName) {
            console.log("Unexpected characters in trimmedRoleName:", Array.from(trimmedRoleName, c => c.charCodeAt(0)));
        }
    }
};

const waitForLocalStorage = () => {
    if (localStorage.getItem(STORAGE_KEY.RoleName)) {
        // Data is available in localStorage, proceed
        checkLocalStorage();
    } else {
        // Wait and try again
        setTimeout(waitForLocalStorage, 100); // Adjust the timeout as needed
    }
};

waitForLocalStorage(); // Start the initial check



export default clientEmployeeRoute;
