import React from 'react';
import { Button, Stack, TextField } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

interface FilterContainerProps {
  value: string;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterContainer = ({ value, placeholder, onChange }: FilterContainerProps) => {
  return (
    <Stack direction={{ xs: 'column', sm: 'column', lg: 'row', xl: 'row' }} spacing={ 4 } justifyContent="space-between">
      <TextField sx={{ width: { xs: '100%', sm: '100%', lg: '50%' } }} value={ value } onChange={ onChange } placeholder={ placeholder } />
      <Stack direction="row" justifyContent="space-between" spacing={ 4 }>
        <Button variant="outlined" startIcon={ <FilterListIcon /> }>Filter</Button>
      </Stack>
    </Stack>
  )
}

export default FilterContainer;