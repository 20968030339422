import React, {useEffect, useState} from 'react';
import { Bar, Line } from 'react-chartjs-2';
import './Profile.css'; // Import the CSS file
import { Chart, registerables } from 'chart.js';
import {useVendor} from "../../hooks/useVendor";
import {useNavigate} from "react-router-dom";
import DetailView from "../../components/DetailView";
import Box from '@mui/material/Box';
import {STORAGE_KEY} from "../../constants";
import {useSnackbar} from "notistack";
import {useClient} from "../../hooks/useClient";
Chart.register(...registerables);

const ProfilePage = () => {

    const { vendorDetail, vendorLoading, getVendorDetail,updateVendorDetail } = useVendor();
    const { getClientDetails } = useClient();
    const loggedInId = localStorage.getItem(STORAGE_KEY.UserId);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();


    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        if(loggedInId != null) {
            getVendorDetail(loggedInId);
            getClientDetails({clientId : loggedInId ?? ''});
        }
    }, []);

    console.log(vendorDetail)

    const onSave = async (data:any) => {
        await
             updateVendorDetail(data).then(r => {
                 enqueueSnackbar("Profile Updated Successfully", { variant: 'success',autoHideDuration: 3000 } )

             });
    }

  return (
      <Box sx={{padding: 3}}>
          <DetailView detailData={vendorDetail} enabledEdit={true} onSave={onSave} loading={vendorLoading}/>
      </Box>
  )
}

export default ProfilePage;
