import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useUser} from '../../provider/UserProvider';
import {Box, Button, Typography} from '@mui/material';
import {CalendarContainer} from '../../components';
import {useLocation, useNavigate,} from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {useClient} from "../../hooks/useClient";
import AppTable from "../../components/Table";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useSnackbar } from 'notistack';
import "./ProjectStyle.css"
import {Delete, Verified} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import './ProjectStyle.css'
import {ClientProjectInprogress} from "../../components/ClientInprogress";
import ClientQuoteDetailLayout from "../../layouts/ClientLayouts/ClientQuoteDetailLayout";
import {useVendor} from "../../hooks/useVendor";


const ProjectPage = () => {
  const { userData } = useUser();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFileBiddingDetails, setSelectedFileBiddingDetails] = useState<File | null>(null);
    const [project, setProject] = useState<any | {}>({});
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const {acceptTimelineRequest,rejectTimelineRequest} = useClient();

    const { clientLoading, fileUpload, getProjectDocuments, projectDocuments, getBiddingDetails,biddingDetails, awardProject, emailProjectVendor, downloadFile } = useClient();

    useEffect(() => {
        if (location.state !== null) {
            setProject(location.state.data.data);
            getProjectDocuments(location.state.data.data.id , project.vendorId);
            getBiddingDetails(location.state.data.data.id);
            console.log(location.state.data.data);
        }
    }, [location]);
    const fileInputRef = useRef(null);

  const events = useMemo(() => {
        const data = [project]?.map((item) => {
            return item?.quoteId?.timelines?.map((item: any) => {
                return {
                    title: item?.title,
                    start: item?.startDate,
                    end: item?.endDate,
                }
            });
        });
        return data?.[0];
  }, [userData, location,project]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleBiddingDetailsFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFileBiddingDetails(file);
        }
    };

    const handleFileUpload = async () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('projectId', project.id);
            await fileUpload(formData).then(r => {
                enqueueSnackbar(r.message, { variant: 'success',autoHideDuration: 3000 } )
                setSelectedFile(null);
                window.history.back();

            });
        }else {
            enqueueSnackbar('Please choose file first !!!', { variant: 'warning',autoHideDuration: 3000 } )
        }
    };


    const handleFileUploadBiddingDetails = async (id:any) => {
        if (selectedFileBiddingDetails) {
            const formData = new FormData();
            formData.append('file', selectedFileBiddingDetails);
            formData.append('projectId', project.id);
            formData.append('vendorId', id);
          
            await fileUpload(formData).then(r => {
                enqueueSnackbar(r.message, { variant: 'success',autoHideDuration: 3000 } )
                setSelectedFile(null);
                window.history.back();
            });
        }else {
            enqueueSnackbar('Please choose file first !!!', { variant: 'warning',autoHideDuration: 3000 } )
        }
    };

    function onAwardProject(id:any) {
        awardProject({
            projectId: project.id,
            vendorId: id,
            timelines: []
        }).then(r => {
            enqueueSnackbar(r.message, { variant: 'success',autoHideDuration: 3000 } )
            window.history.back()
        });
    }

    function emailProjectVendors(id:any) {
        emailProjectVendor(project.id).then(r => {
            enqueueSnackbar(r.message, { variant: 'success',autoHideDuration: 3000 } )
            window.history.back()
        });
    }

    // let getClassName =  (value: any, id: any) => {
    //     if (value?.id && id === value?.id) {
    //         return "selected-card";
    //     } else {
    //         return "unselected-card";
    //     }
    // };

    const acceptRequest = async () => {
        try {
            await acceptTimelineRequest({
                quoteId: project.id,
            }).then(r => {
                enqueueSnackbar('Accepted Successfully', { variant: 'success',autoHideDuration: 3000 } )
            });
            navigate(-1);
        } catch (error) {
            console.log(error);
        }
    }

    const rejectRequest = async () => {
        try {
            await rejectTimelineRequest({
                quoteId: project.id,
            });
            enqueueSnackbar('Rejected', { variant: 'error',autoHideDuration: 3000 } )
            navigate(-1);
        } catch (error) {
            console.log(error);
        }
    }

    if(project.projectStatus === 'TIMELINE_SENT' || project.projectStatus === 'TIMELINE_ACCEPTED')
        return  (<ClientQuoteDetailLayout
            quoteDetail={project}
            onClickAccept={acceptRequest }
            onClickReject={rejectRequest}/>)

    if(project.projectStatus === 'BIDDING' || project.projectStatus === 'IN_PROGRESS')
    return (

    <ClientProjectInprogress selectedFile={selectedFile} onClick={handleFileUpload}
                             onClick1={() => setSelectedFile(null)} ref={fileInputRef} onChange={handleFileChange}
                             onClick2={() => {
                                 if (!clientLoading) emailProjectVendors(project.id)
                             }} clientLoading={clientLoading} listData={projectDocuments} onClick3={(id: any) => {
        downloadFile(projectDocuments[id].id, projectDocuments[id].fileName)
    }} biddingDetails={biddingDetails} element={(b: any) => {
        return (<div style={{paddingBottom: '5px'}}>
                <Card variant="outlined" className="customOutlined">
                    <CardContent>
                        <Typography variant="h6" component="div">
                            {b.name}
                            {
                                project.projectStatus === 'BIDDING' ?
                                    <Button onClick={() => onAwardProject(b.id)} variant="contained"
                                            style={{float: 'right', backgroundColor: "#3361FF"}}>
                                        Award Project
                                    </Button> : <div style={{float: 'right'}}>{
                                        project?.vendor.id && b.id === project?.vendor.id ? <IconButton onClick={() => {
                                        }}>
                                            <div style={{float: "right"}}>
                                                {
                                                    selectedFileBiddingDetails !== null ? <div>
                                                            <Button style={{backgroundColor: "#3361FF"}} variant={"text"} startIcon={<CloudUploadIcon/>}
                                                                    onClick={() => handleFileUploadBiddingDetails(b.id)}>
                                                                Upload File
                                                            </Button>
                                                            <IconButton style={{color: "red"}} onClick={() => setSelectedFileBiddingDetails(null)}>
                                                                <Delete/>
                                                            </IconButton>
                                                        </div>
                                                        : <input
                                                            ref={fileInputRef}
                                                            type="file"
                                                            style={{
                                                                backgroundColor: "transparent",
                                                                color: "transparent",
                                                                padding: "10px",
                                                                borderRadius: "10px",
                                                                width: "80px"
                                                            }}
                                                            onChange={handleBiddingDetailsFileChange}
                                                        />
                                                }
                                            </div>
                                            <Verified style={{color: "green"}}/>
                                        </IconButton> : <div></div>
                                    }
                                    </div>
                            }
                        </Typography>
                        <Typography variant="body2">Status: {b.status}</Typography>
                        <Typography variant="h6">Documents</Typography>
                        <AppTable listData={b.documentList}
                                  hiddenFields={['content']}
                                  onClick={(id: any) => {
                                   downloadFile(b.documentList[id].id, b.documentList[id].fileName)
                               }}
                                  hyperlinkPropertyName={'id'} entity={'project_document'}/>
                    </CardContent>
                </Card>
            </div>
        )
    }} events={events}/>
  )
    return  (<div/>)

}

export default ProjectPage
