export const metData:any = {
    bidding_detail:[
        {
            name: 'id',
            type: 'INPUT',
            placeholder: 'id'
        },
        {
            name: 'name',
            type: 'INPUT',
            placeholder: 'Name'
        },
        {
            name: 'status',
            type: 'DROPDOWN',
            placeholder: 'status'
        }
    ],loto:[
        {
            name: 'sno',
            type: 'INPUT',
            placeholder: 'sno'
        },
        {
            name: 'description',
            type: 'INPUT',
            placeholder: 'description'
        },
        {
            name: 'status',
            type: 'OPENCLOSE',
            placeholder: 'status'
        }
    ],
    project_document:[
        {
            name: 'id',
            type: 'INPUT',
            placeholder: 'id'
        },
        {
            name: 'fileName',
            type: 'INPUT',
            placeholder: 'File Name'
        },
        {
            name: 'fileType',
            type: 'INPUT',
            placeholder: 'File Type'
        }
    ],
    projects:[
        {
            name: 'id',
            type: 'INPUT',
            placeholder: 'id'
        },
        {
            name: 'title',
            type: 'INPUT',
            placeholder: 'Title'
        },
        {
            name: 'description',
            type: 'INPUT',
            placeholder: 'Description'
        },
        {
            name: 'startDate',
            type: 'DATE',
            placeholder: 'Start Date'
        },
        {
            name: 'endDate',
            type: 'DATE',
            placeholder: 'End Date'
        },
        {
            name: 'amount',
            type: 'AMOUNT',
            placeholder: 'Amount'
        }
    ],
    createProject:[
        {
            name: 'title',
            type: 'INPUT',
            placeholder: 'Title'
        },
        {
            name: 'description',
            type: 'INPUT',
            placeholder: 'Description'
        },
        {
            name: 'startDate',
            type: 'DATE',
            placeholder: 'Start Date'
        },
        {
            name: 'endDate',
            type: 'DATE',
            placeholder: 'End Date'
        },
        {
            name: 'amount',
            type: 'AMOUNT',
            placeholder: 'Amount'
        },
        {
            name: 'vendor',
            type: 'MULTIDROPDOWN',
            placeholder: 'Vendor'
        },
    ],
    timelines:[
        {
            name: 'title',
            type: 'INPUT',
            placeholder: 'Item'
        },
        {
            name: 'description',
            type: 'INPUT',
            placeholder: 'Description'
        },
        {
            name: 'startDate',
            type: 'DATE',
            placeholder: 'Start Date'
        },
        {
            name: 'endDate',
            type: 'DATE',
            placeholder: 'End Date'
        },
        {
            name: 'amount',
            type: 'AMOUNT',
            placeholder: 'Amount'
        },
    ],
    quotes: [
        {
            name: 'id',
            type: 'INPUT',
            placeholder: 'id'
        },
        {
            name: 'companyName',
            type: 'INPUT',
            placeholder: 'Vendor Name'
        },
        {
            name: 'status',
            type: 'INPUT',
            placeholder: 'Status'
        },
        {
            name: 'lastUpdated',
            type: 'INPUT',
            placeholder: 'Last Updated'
        },
        {
            name: 'description',
            type: 'INPUT',
            placeholder: 'Description'
        },
    ],
    vendor: [
        {
            name: 'id',
            type: 'INPUT',
            placeholder: 'ID'
        },
        {
            name: 'name',
            type: 'INPUT',
            placeholder: 'Company Name'
        },
        {
            name: 'address',
            type: 'INPUT',
            placeholder: 'Address'
        },
        {
            name: 'phoneNumber',
            type: 'INPUT',
            placeholder: 'Mobile Number'
        },
        {
            name: 'email',
            type: 'INPUT',
            placeholder: 'Email Address'
        }
    ],
    vendorBankDetails : [
        {
            name: 'bankName',
            type: 'INPUT',
            placeholder: 'Bank Name',
        },
        {
            name: 'bankAddress',
            type: 'INPUT',
            placeholder: 'Bank Address',
        },
        {
            name: 'accountNumber',
            type: 'INPUT',
            placeholder: 'Account Number',
        },
        {
            name: 'ifscCode',
            type: 'INPUT',
            placeholder: 'IFSC Code',
        },
        {
            name: 'accountType',
            type: 'INPUT',
            placeholder: 'Account Type',
        }
    ],
    vendorBusiness: [
        {
            name: 'manufacturing',
            type: 'INPUT',
            placeholder: 'Manufacturing',
        },
        {
            name: 'authorisedDealer',
            type: 'INPUT',
            placeholder: 'Authorised Dealer',
        },
        {
            name: 'trader',
            type: 'INPUT',
            placeholder: 'Trader',
        }],
    vendorCompanies: [
        {
            name: 'gstNumber',
            type: 'INPUT',
            placeholder: 'Gst Number',
        },
        {
            name: 'panNumber',
            type: 'INPUT',
            placeholder: 'Pan Number',
        },
        {
            name: 'registeredCompany',
            type: 'INPUT',
            placeholder: 'Registered Company',
        },
        {
            name: 'iecNumber',
            type: 'INPUT',
            placeholder: 'Iec Number',
        },
        {
            name: 'msmeNumber',
            type: 'INPUT',
            placeholder: 'MSME Number',
        },
        {
            name: 'registeredMSME',
            type: 'INPUT',
            placeholder: 'Registered MSME',
        },
    ],
    vendorDesignations: [
        {
            name: 'personName',
            type: 'INPUT',
            placeholder: 'Person Name',
        },
        {
            name: 'designation',
            type: 'INPUT',
            placeholder: 'Designation',
        },
        {
            name: 'contactNumber',
            type: 'INPUT',
            placeholder: 'Contact Number',
        },
        {
            name: 'email',
            type: 'INPUT',
            placeholder: 'Email',
        }
    ],
    vendorFinancialDetails: [
        {
            name: 'particulars',
            type: 'INPUT',
            placeholder: 'Particulars',
        },
        {
            name: 'yearOne',
            type: 'INPUT',
            placeholder: 'Year One',
        },
        {
            name: 'yearTwo',
            type: 'INPUT',
            placeholder: 'Year Two',
        },
        {
            name: 'yearThree',
            type: 'INPUT',
            placeholder: 'Year Three',
        }
    ],
    vendorMainClients: [
        {
            name: 'companyNameAndAddress',
            type: 'INPUT',
            placeholder: 'Company name and Address',
        },
        {
            name: 'referencePersonNameAndDesignation',
            type: 'INPUT',
            placeholder: 'Reference Person Name and Designation',
        },
        {
            name: 'phoneNumber',
            type: 'INPUT',
            placeholder: 'Phone Number',
        },
        {
            name: 'productSupplied',
            type: 'INPUT',
            placeholder: 'Product Supplied',
        },
        {
            name: 'orderValueOrAnnualBusiness',
            type: 'INPUT',
            placeholder: 'Order Value or Annual Business',
        }
    ],
    vendorSkills: [
        {
            name: 'name',
            type: 'DROPDOWN',
            placeholder: 'Name',
        },
        {
            name: 'image',
            type: 'IMG',
            placeholder: 'Image',
            hidden: true
        }
    ],
    employee: [
        {
            name: 'id',
            type: 'INPUT',
            placeholder: 'ID'
        },
        {
            name: 'name',
            type: 'INPUT',
            placeholder: 'Name',
        },
        {
            name: 'email',
            type: 'INPUT',
            placeholder: 'Email',
        },
        {
            name: 'phoneNumber',
            type: 'INPUT',
            placeholder: 'Phone Number',
        }
    ],

    medicalReport: [
        {
            name: 'id',
            type: 'INPUT',
            placeholder: 'ID'
        },
        {
            name: 'name',
            type: 'INPUT',
            placeholder: 'Name',
        },
        {
            name: 'companyName',
            type: 'INPUT',
            placeholder: 'Company Name',
        },
        {
            name: 'designation',
            type: 'INPUT',
            placeholder: 'Designation',
        }
    ],

    questionnaireDetails: [
        {
            name: 'id',
            type: 'INPUT',
            placeholder: 'ID'
        },
        {
            name: 'question',
            type: 'INPUT',
            placeholder: 'Message',
        },
        {
            name: 'employeeId',
            type: 'INPUT',
            placeholder: 'Employee Id',
        },
    ],
    messageList: [
        {
            name: 'id',
            type: 'INPUT',
            placeholder: 'ID'
        },
        {
            name: 'subject',
            type: 'INPUT',
            placeholder: 'Subject',
        },
        {
            name: 'email',
            type: 'INPUT',
            placeholder: 'Email Id',
        },
    ],
    workPermit: [
        {
            name: 'id',
            type: 'INPUT',
            placeholder: 'Id',
        },{
            name: 'name',
            type: 'INPUT',
            placeholder: 'Name',
        },
        {
            name: 'startDate',
            type: 'DATE',
            placeholder: 'Start Date',
        },
        {
            name: 'endDate',
            type: 'DATE',
            placeholder: 'End Date',
        }
    ],
    skills: [
        {
            name: 'id',
            type: 'INPUT',
            placeholder: 'id',
        },
        {
            name: 'name',
            type: 'DROPDOWN',
            placeholder: 'Name',
        },
        {
            name: 'image',
            type: 'IMG',
            placeholder: 'Image',
            hidden: true
        }
    ]
}
