import axios, { AxiosInstance } from "axios";
import { STORAGE_KEY } from "../constants";


const getApiConfig = (): AxiosInstance => {
  const apiConfig = axios.create({
    // baseURL: 'http://192.168.0.246:8081',
    baseURL: 'http://colobten.com:8081',
    // baseURL: 'http://localhost:8081',
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Add an interceptor for responses
  apiConfig.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Session expired or unauthorized, handle accordingly
        localStorage.clear();
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );

  // Get the bearer token from local storage
  const token = localStorage.getItem(STORAGE_KEY.AuthToken);
  if (token != null &&  token !== 'undefined') {
    apiConfig.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(token)}`;
  }

  return apiConfig;
};

export default getApiConfig;
