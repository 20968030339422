import React, {useEffect, useState} from 'react';
import {useProject} from '../../hooks/useProject';
import {useUser} from '../../provider/UserProvider';
import {Box} from '@mui/material';
import {UserRole} from '../../constants/enum';
import AppTable from 'components/Table';
import {STORAGE_KEY} from "../../constants";
import {useVendor} from "../../hooks/useVendor";

const MyClients = () => {
    const {userData} = useUser();
    const {getMyClientList, myClientList} = useVendor();
    const [open, setOpen] = useState<boolean>(false);


    useEffect(() => {
        try {
            const clientId = localStorage.getItem(STORAGE_KEY.UserId);
            getMyClientList({
                vendorId: parseInt(clientId ?? ''),
            });
            setOpen(false);
        } catch (error) {
            setOpen(false);
        }
    }, []);


    return (
        <Box>
            <AppTable listData={myClientList}
                      entity={'vendor'} hyperlinkPropertyName={'id'}/>
        </Box>
    )
}

export default MyClients
