import React from "react";
import {Box, Button, Typography} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";
import AppTable from "./Table";
import {CalendarContainer} from "./index";

export function ClientProjectInprogress(props: {
    selectedFile: File | null,
    onClick: () => Promise<void>,
    onClick1: () => void,
    ref: React.MutableRefObject<null>,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onClick2: () => void,
    clientLoading: boolean,
    listData: any,
    onClick3: (id: any) => void,
    biddingDetails: any,
    element: (b: any) => React.JSX.Element,
    events: any
}) {
    return <Box sx={{padding: 3}}>
        <Typography variant="h5">
            <span style={{textDecoration: "underline"}}>Project Documents</span>
            <div style={{float: "right"}}>
                {
                    props.selectedFile !== null ? <div>
                            <Button style={{backgroundColor: "#3361FF"}} variant={"text"} startIcon={<CloudUploadIcon/>}
                                    onClick={props.onClick}>
                                Upload File
                            </Button>
                            <IconButton style={{color: "red"}} onClick={props.onClick1}>
                                <Delete/>
                            </IconButton>
                        </div>
                        : <input
                            ref={props.ref}
                            type="file"
                            style={{
                                backgroundColor: "transparent",
                                color: "transparent",
                                padding: "10px",
                                borderRadius: "10px",
                                width: "80px"
                            }}
                            onChange={props.onChange}
                        />
                }
            </div>
            <div style={{float: "right"}}>
                <Typography variant="h6" component="div">
                    <Button onClick={props.onClick2} variant="contained"
                            style={{float: "right", backgroundColor: "#3361FF"}}>
                        {props.clientLoading ? "Sending..." : "Notify Vendor"}
                    </Button>
                </Typography>
            </div>
        </Typography>
        <AppTable listData={props.listData}
                  hiddenFields={["content"]}
                  onClick={props.onClick3}
                  hyperlinkPropertyName={""} entity={"project_document"}/>

        <Typography variant="h5" style={{paddingBottom: 10}}>
            <span style={{textDecoration: "underline"}}>Bidding Details</span>
        </Typography>

        {
            props.biddingDetails && props.biddingDetails.map(props.element)
        }
        {props.events && (
            <CalendarContainer events={props.events}/>
        )}
    </Box>;
}