import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {LoginLayout} from '../../layouts';
import {useAuth} from '../../hooks/useAuth';
import {useNavigate} from 'react-router-dom';
import {UserRole} from '../../constants/enum';
import {STORAGE_KEY} from '../../constants';
import {useSnackbar} from "notistack";

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    // Check if the user is already logged in based on userData or the presence of the token
    const isLoggedIn = localStorage.getItem(STORAGE_KEY.AuthToken);

    if (isLoggedIn) {
      // If the user is logged in, redirect them to the desired page
      navigate('/');
    }
  }, [navigate]);

  const onChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  }

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  const onClickLogin = async () => {
    try {
      const role = await login({
        username: userName,
        password: password,
      });
      setUserName('');
      setPassword('');
      if (role?.includes(UserRole.ROLE_CLIENT)) {
        enqueueSnackbar('Login Successfully', { variant: 'success',autoHideDuration: 3000 } )
        navigate('/');
      } else {
        enqueueSnackbar('Login Successfully', { variant: 'success',autoHideDuration: 3000 } )
        navigate('/');
      }
    } catch (error) {
      enqueueSnackbar('Login Failed', { variant: 'error',autoHideDuration: 3000 } )
      console.log(error);
    }
  }

  return (
    <Box>
      <LoginLayout
        userName={ userName }
        password={ password }
        onChangeUserName={ onChangeUserName }
        onChangePassword={ onChangePassword }
        onClickLogin={ onClickLogin } />
    </Box>
  )
}

export default Login;
