import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import VendorProjectTimeline from "../../../layouts/VendorLayouts/VendorQuoteDetailLayout";
import {useVendor} from "../../../hooks/useVendor";
import {useSnackbar} from "notistack";

const VendorQuoteDetail = () => {

    const [quoteDetail, setQuoteDetail] = useState<any | {}>({});
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const {sendTimeline} = useVendor();

    useEffect(() => {
        if (location.state != null) {
            setQuoteDetail(location.state.data.data);
        }
    }, [location]);

    const acceptRequest = async () => {
        try {
            await sendTimeline(quoteDetail);
            enqueueSnackbar("Timeline sent Successfully", { variant: 'success',autoHideDuration: 3000 } )
            navigate(-1);
        } catch (error) {
            console.log(error);
        }
    }


    return (<div>

            <VendorProjectTimeline onSendTimeline={ acceptRequest } quoteDetail={ quoteDetail} projectDocuments={[]}
                                   onDocumentOpen={(id:string) =>  {}} />
        </div>

    )
}

export default VendorQuoteDetail;
