import {VendorDetails, ClientQuotes, ProjectPage, ProfilePage} from '../pages';
import FindVendor from "../pages/FindVendor";
import VendorList from "../pages/VendorList";
import ClientQuoteDetail from "../pages/ClientPages/ClientQuoteDetail";
import ProjectList from "../pages/ProjectList";
import ClientVendorList from "../pages/ClientPages/ClientVendorList";
import CreateProject from "../pages/CreateProject";
import EmployeeList from "../pages/EmployeeList";
import WorkPermit from "../pages/WorkPermit";
import CreateWorkPermitPage from '../pages/CreateWorkPermit';
import EmployeeDetails from "../pages/EmployeeDetails";
import MedicalReportList from "../pages/MedicalReportList";
import MedicalReportUpsert from "../pages/MedicalReportUpsert";
import {AppRoutes} from "../constants/EndPoint/route";
import MedicalQr from 'pages/qr';
import Qr from 'pages/qr';

interface ClientRoutesProps {
    path: string;
    element: JSX.Element;
}

export const clientRoutes: ClientRoutesProps[] = [
    { path: '/', element: <FindVendor /> },
    { path: '/vendor', element: <FindVendor /> },
    { path: '/vendorList', element: <ClientVendorList /> },
    { path: '/vendor/*', element: <VendorDetails /> },
    { path: '/quotes', element: <ClientQuotes /> },
    { path: '/quotes/*', element: <ClientQuoteDetail /> },
    { path: '/projects', element: <ProjectList /> },
    { path: '/projects/*', element: <ProjectPage/> },
    { path: '/profile', element: <ProfilePage /> },
    { path: '/findVendor', element: <FindVendor /> },
    { path: '/vendorList', element: <VendorList /> },
    { path: '/project/create', element: <CreateProject/> },
    { path: '/employee', element: <EmployeeList/> },
    { path: '/employee/:id', element: <EmployeeDetails/> },
    { path: AppRoutes.MEDICAL_REPORT, element: <MedicalReportList/> },
    { path: AppRoutes.MEDICAL_REPORT_UPSERT, element: <MedicalReportUpsert/> },
    { path: AppRoutes.APP_QR, element: <Qr/> },
    { path: '/workPermit', element: <WorkPermit/> },
    { path: '/workPermit/create', element: <CreateWorkPermitPage/> },
    { path: '/workPermit/:id', element: <CreateWorkPermitPage/> },
];
