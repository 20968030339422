import {useCallback, useEffect, useState} from 'react';
import {AxiosInstance} from 'axios';
import getApiConfig from '../config/apiConfig';
import {projectEndPoint} from '../constants/EndPoint';
import {ClientData} from './useClient';

interface TimelineData {
    id: string;
    title: string;
    startDate: string;
    endDate: string;
    amount: string;
    description: string;
    createdAt: string;
}

interface ProjectListProps {
    id: string;
    description: string;
    amount: string;
    projectStatus: string;
    startDate: string;
    endDate: string;
    createdAt: string;
    vendor: ClientData;
    client: ClientData;
    quoteId: {
        id: string;
        description: string;
        amount: string;
        user: ClientData;
        vendor: ClientData;
        status: string;
        timelines: TimelineData[];
    },
}

export const useProject = () => {
    const apiConfig: AxiosInstance = getApiConfig();

    const [clientProjectList, setClientProjectList] = useState<any[] | null>();
    const [vendorProjectList, setVendorProjectList] = useState<any[] | null>();
    const [projectQuesList, setProjectQuesList] = useState<any[] | null>();
    const [projectListLoading, setProjectListLoading] = useState(false);
    const [clientProjectRefetch, setClientProjectRefetch] = useState<boolean>(false);
    const [vendorProjectRefetch, setVendorProjectRefetch] = useState<boolean>(false);

    const fetchClientProjectList = useCallback(() => {
        setClientProjectRefetch(true);
    }, []);

    const fetchVendorProjectList = useCallback(() => {
        setVendorProjectRefetch(true);
    }, []);

    const getClientProject = useCallback(async () => {
        try {
            setProjectListLoading(true);
            setClientProjectRefetch(false);
            const response = await apiConfig.get(projectEndPoint.GET_ALL_PROJECT_CLIENT);
            const userResponse = response?.data;
            setClientProjectList(userResponse);
            setProjectListLoading(false);
            return userResponse;
        } catch (e) {
            setProjectListLoading(false);
            setClientProjectRefetch(false);
            setClientProjectList(null);
            return null;
        }
    }, [apiConfig]);

    const createProject = useCallback(async (projectData: any) => {
        try {
            setProjectListLoading(true);
            setClientProjectRefetch(false);
            const response = await apiConfig.post(projectEndPoint.CREATE_PROJECT, projectData);
            const userResponse = response?.data;
            await getClientProject();
            return userResponse;
        } catch (e) {
            return null;
        }
    }, [apiConfig]);

    const getVendorProject = useCallback(async () => {
        try {
            setProjectListLoading(true);
            setVendorProjectRefetch(false);
            const response = await apiConfig.get(projectEndPoint.GET_ALL_PROJECT_VENDOR);
            const userResponse = response?.data;
            setVendorProjectList(userResponse);
            setProjectListLoading(false);
            return userResponse;
        } catch (e) {
            setProjectListLoading(false);
            setVendorProjectRefetch(false);
            setVendorProjectList(null);
            return null;
        }
    }, [apiConfig]);

    const getProjectQuesList = useCallback(async () => {
        try {
            setProjectListLoading(true);
            const response = await apiConfig.get(projectEndPoint.PROJECT_QUESTIONNAIRE_LIST);
            const list = response?.data;
            setProjectQuesList(list);
            setProjectListLoading(false);
            return list;
        } catch (e) {
            setProjectListLoading(false);
            setProjectQuesList(null);
            return null;
        }
    }, [apiConfig]);

    useEffect(() => {
        if (clientProjectRefetch) {
            getClientProject();
            setClientProjectRefetch(false);
        }
    }, [clientProjectRefetch, getClientProject]);

    useEffect(() => {
        if (vendorProjectRefetch) {
            getVendorProject();
            setVendorProjectRefetch(false);
        }
    }, [vendorProjectRefetch, getVendorProject]);

    return {
        clientProjectList,
        vendorProjectList,
        projectListLoading,
        fetchClientProjectList,
        fetchVendorProjectList,
        createProject,
        getProjectQuesList,
        projectQuesList
    };
};
