import React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DropDown from "components/DropDown";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Loto, WorkPermitData, WorkPermitDocumentListProps} from "hooks/useWorkPermit";
import FormPage from "components/FormPage";
import {UserRole} from "../../constants/enum";
import {useUser} from "../../provider/UserProvider";
import {metData} from "../../constants/meta";
import moment from "moment-timezone";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";


export interface WorkPermitDetails {
    name: string;
    status: string;
    clientId: number;
    configuration: WorkPermitData[]
    lotoDTOList: Loto[]
}

interface CreateWorkPermitLayoutProps {
    clientList: any[];
    list: WorkPermitData[];
    workPermitDetail: WorkPermitDetails;
    date: {
        startDate: Date;
        endDate: Date;
    }
    vendorSelectedImage: File[];
    workPermitDocumentsList: WorkPermitDocumentListProps[];
    vendorDetails: any;
    onClickAdd: () => void;
    onClickAddLoto: () => void;
    onClickSave: () => void;
    onClickPrint: () => void;
    onClickApprove: () => void;
    onClickReject: () => void;
    onChange: (event: string, index: number, item: WorkPermitData) => void;
    onMetaChange: (event: string, metaId: string) => void;
    onPermitChange: (index: number, data: string, metaId: string) => void;
    onLotoChange: (index: number, data: string, metaId: string) => void;
    onClickCloseWorkPermit: () => void;
    onChangeDate: (event: Date, index: string) => void;
    onChangeImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleRemoveSelectedImage: (index: number) => void;
    downloadWorkPermitFile: (id: string, name: string) => void;
}

const CreateWorkPermitLayout = ({
                                    list,
                                    clientList,
                                    workPermitDetail,
                                    date,
                                    vendorSelectedImage,
                                    workPermitDocumentsList,
                                    vendorDetails,
                                    onClickAdd,
                                    onClickAddLoto,
                                    onClickSave,
                                    onClickPrint,
                                    onClickApprove,
                                    onClickReject,
                                    onChange,
                                    onMetaChange,
                                    onPermitChange,
                                    onLotoChange,
                                    onClickCloseWorkPermit,
                                    onChangeDate,
                                    onChangeImage,
                                    handleRemoveSelectedImage,
                                    downloadWorkPermitFile,
                                }: CreateWorkPermitLayoutProps) => {
    const {userData} = useUser();
    return (<div>
        <Stack
            sx={{marginBottom: 2}}
            direction={{xs: "column", sm: "column", md: "row", lg: "row"}}
            display={"flex"}
            justifyContent="flex-end"
        >
            <Button onClick={onClickPrint}>{ 'Print' }</Button>
        </Stack>

        {userData.role === UserRole.ROLE_VENDOR && <Stack
            sx={{marginBottom: 2}}
            direction={{xs: "column", sm: "column", md: "row", lg: "row"}}
            display={"flex"}
            justifyContent="flex-end"
        >
            <Button onClick={onClickSave}>{ workPermitDetail.status === "APPROVED" ? 'Complete' : 'Submit' }</Button>
        </Stack>}
        {(userData.role === UserRole.ROLE_CLIENT || userData.role === UserRole.CLIENT_EMPLOYEE) && workPermitDetail.status === "COMPLETED" && <Stack
            sx={{marginBottom: 2}}
            direction={{xs: "column", sm: "column", md: "row", lg: "row"}}
            display={"flex"}
            justifyContent="flex-end"
        >
            <Button onClick={onClickCloseWorkPermit}>Close work permit</Button>
        </Stack>
        }
        {(userData.role === UserRole.ROLE_CLIENT || userData.role === UserRole.CLIENT_EMPLOYEE) && workPermitDetail.status === 'INPROGRESS' && <Stack
        sx={{marginBottom: 2}}
        direction="row"
        display={"flex"}
        justifyContent="space-evenly"
    >
        <Button onClick={onClickApprove}>Approve</Button>
        <Button onClick={onClickReject}>Reject</Button>
    </Stack>}
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={2} marginBottom="16px" direction={{xs: "column", sm: "column", md: "row", lg: "row"}}>
            <DatePicker
                sx={{width: '100%'}}
                disablePast={true}
                label="Start date"
                value={ date.startDate ? new Date(date.startDate) : Date.now()}
                onChange={ (e: any) => {
                    const parsedDate = moment(e, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)");
                    const milliseconds = parsedDate.valueOf();
                    console.log(milliseconds);
                    onChangeDate(e, 'startDate')
                }}
            />
            <DatePicker
                sx={{width: '100%'}}
                disablePast={true}
                label="End date"
                value={ date.endDate ? new Date(date.endDate) : Date.now() }
                onChange={ (e: any) => {
                    const parsedDate = moment(e, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)");
                    const milliseconds = parsedDate.valueOf();
                    console.log(milliseconds);
                    onChangeDate(e, 'endDate')
                }}
            />
        </Stack>
    </LocalizationProvider>
    { (userData.role === UserRole.ROLE_VENDOR ) && workPermitDetail.status === "APPROVED" && (
        <>
            <label>
                <Box sx={{ width: { xs: '90%', sm: '90%', md: '97.5%', lg: '97.5%' }, padding: '16px', border: '1px solid #00000061', marginBottom: '16px', cursor: 'pointer' }}>
                    Choose images
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={onChangeImage} />
                </Box>
            </label>
            <Box sx={{ display: 'flex', gap: '16px', flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' }, marginBottom: '24px' }}>
                {vendorSelectedImage?.map((item, index) => (
                    <Box key={item.name + index} sx={{ height: { xs: 'auto', sm: 'auto', md: '200px', lg: '200px' }, width: { xs: 'auto', sm: 'auto', md: '200px', lg: '200px' }, position: 'relative', marginBottom: '12px' }}>
                        <img src={URL.createObjectURL(item)} alt="upload-image" style={{ width: '100%', height: '100%' }} />
                        <IconButton
                            sx={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'white' }}
                            onClick={() => handleRemoveSelectedImage(index)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                ))}
            </Box>
        </>
    )
    }
    { (userData.role === UserRole.ROLE_CLIENT || userData.role === UserRole.CLIENT_EMPLOYEE) && workPermitDetail.status === "COMPLETED" && (
        <Accordion sx={{ marginBottom: '24px', background: '#f5f5f5', boxShadow: 'none', border: '1px solid #00000061' }}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
            Vendor Documents
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2} sx={{ marginBottom: '24px' }}>
                    { workPermitDocumentsList?.map((item, index) => (
                        <Grid key={item.id} item xs={12} sm={12} md={3} lg={3}>
                            <Box onClick={() => downloadWorkPermitFile(item.id, item.fileName)} sx={{ border: '1px solid #00000061', borderRadius: '6px', padding: '12px', cursor: 'pointer' }}>
                                <Typography sx={{ wordWrap: 'break-word' }}>
                                    { item?.fileName }
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </AccordionDetails>
        </Accordion>
    ) }
        <Typography sx={{ marginBottom: '5px' }}>Title</Typography>
        <TextField placeholder={'Title'} fullWidth={true} disabled={userData.role === UserRole.ROLE_CLIENT}
                   variant="outlined"
                   name={'name'} value={workPermitDetail.name}
                   onChange={(e) => onMetaChange(e.target.value, 'name')} />
        <Typography fontSize={20} fontWeight={800} sx={{marginTop: 2, marginBottom: 2}}></Typography>

        <DropDown
            disabled={userData.role === UserRole.ROLE_CLIENT}
            label="Client"
            list={clientList}
            value={String(workPermitDetail?.clientId)}
            onChange={(event) => onMetaChange(event, 'clientId')}
        />
        { userData.role === UserRole.ROLE_CLIENT && (
            <>
                <Typography sx={{ marginBottom: '5px', marginTop: '16px' }}>Vendor Name</Typography>
                <TextField fullWidth={true} disabled variant="outlined" value={vendorDetails?.name} />
            </>
        ) }
        <Typography fontSize={20} fontWeight={800} sx={{marginTop: 2, marginBottom: 2}}>Loto List</Typography>
        {workPermitDetail.lotoDTOList?.map((item, index) => (<
            div key={'id-' + index} style={{marginBottom: "14px", background: 'white', padding: 10}}>
            {(<FormPage
                detail={true}
                buttonTitle={"Create"}
                title={''}
                onChange={(data: any, metaId: string) => {
                    onLotoChange(index, data, metaId);
                }}
                data={item}
                meta={metData.loto}
                handleClose={() => {
                }}
                open={true}
                onClickPrimary={() => {
                }}
                readOnly={userData.role === UserRole.ROLE_CLIENT}/>)}
        </div>))}
        {userData.role === UserRole.ROLE_VENDOR && <Stack
            sx={{marginBottom: 2, marginTop: 3}}
            direction={{xs: "row", sm: "row", md: "row", lg: "row"}}
            display={"flex"}
            justifyContent="flex-end"
        >
            <Button onClick={onClickAddLoto}>Add</Button>
        </Stack>}

        <Typography fontSize={20} fontWeight={800} sx={{marginTop: 2, marginBottom: 2}}>Work permit
            checklist</Typography>


        {workPermitDetail.configuration?.map((item, index) => (<
            div key={'id-' + index} style={{marginBottom: "14px", background: 'white', padding: 10}}>
            {userData.role === UserRole.ROLE_VENDOR && <DropDown
                key={index}
                label="Permit Type"
                list={list}
                value={item.id}
                onChange={(event) => onChange(event, index, item)}
            />}
            {item?.configuration && (<FormPage
                detail={true}
                buttonTitle={"Create"}
                title={item.name}
                onChange={(data: any, metaId: string) => {
                    onPermitChange(index, data, metaId);
                }}
                data={item}
                meta={item?.configuration}
                handleClose={() => {
                }}
                open={true}
                onClickPrimary={() => {
                }}
                readOnly={userData.role === UserRole.ROLE_CLIENT}/>)}
        </div>))}
        {userData.role === UserRole.ROLE_VENDOR && <Stack
            sx={{marginBottom: 2, marginTop: 3}}
            direction={{xs: "row", sm: "row", md: "row", lg: "row"}}
            display={"flex"}
            justifyContent="flex-end"
        >
            <Button onClick={onClickAdd}>Add</Button>
        </Stack>}
    </div>);
};

export default CreateWorkPermitLayout;
