import React, {useEffect, useState} from 'react';
import {Box, Button, ButtonGroup} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useClient} from "../../hooks/useClient";
import {STORAGE_KEY} from "../../constants";
import AppTable from "../../components/Table";
import AddEmployeeModel from "../../components/AddEmployeeModal";
import Loader from "../../components/Loader/Loader";


 interface AddEmployeeRequestProps {
    roleId: number,
    address: string,
    clientId: string,
    dob: string,
    email: string,
    enabled: true,
    gender: string,
    gstNo: string,
    name: string,
    password: string,
    phoneNumber: string,
    userId: number,
    username: string,
    vendorId: number
}

const EmployeeList = () => {
    const {getEmployeeList, createEmployee, employeeList, clientLoading, roleDropdownValues, getRoleDropDownValues} = useClient();
    const [open, setOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedValue, setSelectedValue] = useState('');
    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>)  => {
        // setSelectedValue(event.target.value);
        setValue({ ...value, roleId: Number(event.target.value) });
    };


    const [value, setValue] = useState<AddEmployeeRequestProps>({
        roleId: 0,
        address: "",
        clientId: "",
        dob: "",
        email: "",
        enabled: true,
        gender: "",
        gstNo: "",
        name: "",
        password: "",
        phoneNumber: "",
        userId: 0,
        username: "",
        vendorId: 0

    });


    useEffect(() => {
        try {
            const clientId = localStorage.getItem(STORAGE_KEY.UserId);

            getEmployeeList();
            getRoleDropDownValues();
            setOpen(false);
        } catch (error) {
            setOpen(false);
        }
    }, []);

    const onClickCreateEmployee = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const onChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            address: event.target.value,
        });
    };
    const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            email: event.target.value,
        });
    };
    const onChangeGstNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            gstNo: event.target.value,
        });
    };
    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            name: event.target.value,
        });
    };
    const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            password: event.target.value,
        });
    };
    const onChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            phoneNumber: event.target.value,
        });
    };
    const onChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            username: event.target.value,
        });
    };
    const onChangeGender = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            gender: event.target.value,
        });
    };


    const onClickConfirm = async () => {
        if(value.password === '' && value.username === '' && value.email === '' && value.phoneNumber === ''){
            enqueueSnackbar("Please enter mandatory field", { variant: 'error',autoHideDuration: 3000 } )
            return true;
        } else if(value.password.length < 8){
            enqueueSnackbar("Password Need at least 8 characters", { variant: 'error',autoHideDuration: 3000 } )
            return true;

        }else if(value.username.length < 5){
            enqueueSnackbar("Username Need at least 5 characters", { variant: 'error',autoHideDuration: 3000 } )
            return true;
        }else {
            try {
                const clientId = localStorage.getItem(STORAGE_KEY.UserId);
                await createEmployee({
                    roleId: value.roleId,
                    address: value.address,
                    clientId: clientId!,
                    dob: value.dob,
                    email: value.email,
                    enabled: true,
                    gender: value.gender,
                    gstNo: value.gstNo,
                    name: value.name,
                    password: value.password,
                    phoneNumber: value.phoneNumber,
                    userId: value.userId,
                    username: value.username,
                    vendorId: value.vendorId
                }).then(r => {
                    console.log(r);
                    if(r?.response && r?.response?.status === 400){
                        enqueueSnackbar(r?.response?.data, { variant: 'error',autoHideDuration: 3000 } )
                    }else {
                        console.log(getEmployeeList);
                        enqueueSnackbar(r.message, { variant: 'success',autoHideDuration: 3000 } )
                        getEmployeeList();
                        window.history.back();
                    }
                });
                setOpen(false);
            } catch (e : any) {
                console.log(e)
                setOpen(false);
            }
        }
    };


    // @ts-ignore
    return (
        <div>
            {
                clientLoading ? <Loader></Loader> : <div>
                    <Grid container justifyContent="flex-end" spacing={4}>
                        <Grid item>
                            <ButtonGroup variant="text" aria-label="text button group"  sx={{padding: 1}}>
                                <div >
                                    <Button style={{ backgroundColor : "#3361FF"}} variant={'text'} onClick={onClickCreateEmployee} >
                                        Create Employee
                                    </Button>
                                </div>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <AddEmployeeModel
                            open={ open }
                            value={ value }
                            handleClose={ onCloseModal }
                            onClickPrimary={ onClickConfirm }
                            onChangeAddress={onChangeAddress}
                            onChangeEmail={onChangeEmail}
                            onChangeGstNo={onChangeGstNo}
                            onChangeName={onChangeName}
                            onChangePassword={onChangePassword}
                            onChangePhoneNumber={onChangePhoneNumber}
                            onChangeUserName={onChangeUserName} onChangeGender={onChangeGender}
                            handleDropdownChange={handleDropdownChange} roleDropdownValues={roleDropdownValues} selectedValue={selectedValue}/>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <AppTable listData={employeeList} hyperlinkPropertyName={'id'} entity={'employee'} ></AppTable>
                    </Box>
                </div>
            }
        </div>

    )
}

export default EmployeeList;
