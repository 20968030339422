// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add some basic styles to the table */
.table-container {
    max-width: 100%;
    overflow-x: auto;
    padding: 16px;
}

.table {
    width: 100%;
    border-collapse: collapse;
    background-color: #f8f8f8;
}

.table th,
.table td {
    padding: 12px;
    border: 1px solid #ccc;
}

.table th {
    font-weight: bold;
    background-color: #f2f2f2;
    text-align: center; /* Center align the first column header */

}

.table tbody tr:nth-child(even) {
    background-color: #f5f5f5;
}

/* Style the hyperlinks */
.table a {
    color: #007bff;
    text-decoration: none;
}

.table a:hover {
    text-decoration: underline;
}

/* Apply some hover effect to the table rows */
.table tbody tr:hover {
    background-color: #e0e0e0;
}
/* Center align the first column cells */
.table td:first-child {
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Table/Table.css"],"names":[],"mappings":"AAAA,uCAAuC;AACvC;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;;IAEI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB,EAAE,yCAAyC;;AAEjE;;AAEA;IACI,yBAAyB;AAC7B;;AAEA,yBAAyB;AACzB;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA,8CAA8C;AAC9C;IACI,yBAAyB;AAC7B;AACA,wCAAwC;AACxC;IACI,kBAAkB;AACtB","sourcesContent":["/* Add some basic styles to the table */\n.table-container {\n    max-width: 100%;\n    overflow-x: auto;\n    padding: 16px;\n}\n\n.table {\n    width: 100%;\n    border-collapse: collapse;\n    background-color: #f8f8f8;\n}\n\n.table th,\n.table td {\n    padding: 12px;\n    border: 1px solid #ccc;\n}\n\n.table th {\n    font-weight: bold;\n    background-color: #f2f2f2;\n    text-align: center; /* Center align the first column header */\n\n}\n\n.table tbody tr:nth-child(even) {\n    background-color: #f5f5f5;\n}\n\n/* Style the hyperlinks */\n.table a {\n    color: #007bff;\n    text-decoration: none;\n}\n\n.table a:hover {\n    text-decoration: underline;\n}\n\n/* Apply some hover effect to the table rows */\n.table tbody tr:hover {\n    background-color: #e0e0e0;\n}\n/* Center align the first column cells */\n.table td:first-child {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
