import React from "react";
import {Box, Button, TableBody, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import AppTable from "./Table";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";
import {CalendarContainer} from "./index";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";

export function BiddingProject(props: {
    project:any,
    onClick: () => void,
    listData: any,
    onClick1: (id: any) => void,
    selectedFile: File | null,
    onClick2: () => Promise<void>,
    onClick3: () => void,
    ref: React.MutableRefObject<null>,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    listData1: any,
    onClick4: (id: any) => void,
    events: any
}) {
    return <Box sx={{padding: 3}}>
        <Typography variant="h5" style={{paddingBottom: 10}}>
            <span style={{textDecoration: "underline"}}>Questionnaire</span>
            <Button onClick={props.onClick} variant="contained" style={{backgroundColor: "#3361FF", float: "right"}}>
                Submit Bid
            </Button>
        </Typography>
        {
            props.project != null && props.project.rfq != null
            ?  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                            {Object.keys(props.project.rfq).map((key) => (
                                <TableRow
                                    key={key}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {key}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {props.project.rfq[key]}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer> : <div></div>
        }

        <Typography variant="h5">
            <span style={{textDecoration: "underline"}}>Project Documents</span>

        </Typography>
        <AppTable listData={props.listData}
                  hiddenFields={["content"]}
                  onClick={props.onClick1}
                  hyperlinkPropertyName={""} entity={"project_document"}/>
        <Typography variant="h5" style={{paddingBottom: 10}}>
            <span style={{textDecoration: "underline"}}>My Documents</span>
            <div style={{float: "right"}}>
                {
                    props.selectedFile !== null ? <div>
                            <Button style={{backgroundColor: "#3361FF"}} variant={"text"} startIcon={<CloudUploadIcon/>}
                                    onClick={props.onClick2}>
                                Upload File
                            </Button>
                            <IconButton style={{color: "red"}} onClick={props.onClick3}>
                                <Delete/>
                            </IconButton>
                        </div>
                        : <input
                            ref={props.ref}
                            type="file"
                            style={{
                                backgroundColor: "transparent",
                                color: "transparent",
                                padding: "10px",
                                borderRadius: "10px",
                                width: "80px"
                            }}
                            onChange={props.onChange}
                        />
                }
            </div>
        </Typography>
        <AppTable listData={props.listData1}
                  hiddenFields={["content"]}
                  hyperlinkPropertyName={""} onClick={props.onClick4}
                  entity={"project_document"}/>
        {props.events && (
            <CalendarContainer events={props.events}/>
        )}
    </Box>;
}