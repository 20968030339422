import React, {useEffect, useState} from 'react';
import {useProject} from '../../hooks/useProject';
import {useUser} from '../../provider/UserProvider';
import {Box, Button, ButtonGroup} from '@mui/material';
import {UserRole} from '../../constants/enum';
import AppTable from 'components/Table';
import Grid from "@mui/material/Grid";
import FromModal from "../../components/Form";
import {metData} from "../../constants/meta";
import project from "../Project";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";

const ProjectList = () => {
    const {userData} = useUser();
    const {clientProjectList, vendorProjectList, fetchClientProjectList, fetchVendorProjectList, createProject} = useProject();
    const [open, setOpen] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [value, setValue] = useState<any>({
        description: '',
        startDate: Date.now() ,
        endDate: Date.now(),
        amount: 0,
    });

    useEffect(() => {
        if (userData.role?.includes(UserRole.ROLE_CLIENT)) {
            fetchClientProjectList();
        }
        if (userData.role?.includes(UserRole.ROLE_VENDOR)) {
            fetchVendorProjectList();
        }
    }, [userData, fetchClientProjectList, fetchVendorProjectList]);


    const onClickCreateVendor = () => {
        navigate('/project/create');
    };

    const onCloseModal = () => {
        setOpen(false);
    };


    const onCreateProject = async (projectData: any) => {
        projectData.vendorId = projectData.vendor.map((v:any) => v.id);
        await createProject(projectData).then(r => {
            enqueueSnackbar(r.message, { variant: 'success',autoHideDuration: 3000 } )
            window.history.back();
        });
        setOpen(false);
    };

    const onCreateFormChange = (data: any, metaId: string) => {
        value[metaId] = data;
        setValue({...value});
    }

    return (
        <Box>{
            userData.role  === UserRole.ROLE_CLIENT ?
            <Grid container justifyContent="flex-end" spacing={4}>
            <Grid item>
            <ButtonGroup variant="text" aria-label="text button group"  sx={{padding: 1}}>
            <Button style={{ backgroundColor : "#3361FF"}} variant={'text'} onClick={onClickCreateVendor} >
            Create Project
            </Button>
            </ButtonGroup>
            </Grid>
            </Grid> : <div></div>
        }

            <AppTable listData={userData.role === UserRole.ROLE_CLIENT ? clientProjectList ?? [] : vendorProjectList ?? []}
                      entity={'projects'} hyperlinkPropertyName={'id'}/>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <FromModal buttonTitle={'Create'} title={'Create Project'}
                           onChange={onCreateFormChange}
                           data={value}
                           meta={metData.createProject}
                           handleClose={onCloseModal}
                           open={open}
                           onClickPrimary={() => {onCreateProject(value);}}/>
            </Box>
        </Box>
    )
}

export default ProjectList
