import { Event } from "react-big-calendar";

// Function to generate events for each day in the date range
export const generateEventsInRange = (start: Date, end: Date, title: string): Event[] => {
    const eventsInRange: Event[] = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      eventsInRange.push({
        title,
        start: new Date(currentDate),
        end: new Date(currentDate),
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return eventsInRange;
};