import React, {useEffect, useState} from 'react';
import Loader from "../../components/Loader/Loader";
import Grid from "@mui/material/Grid";
import {Box, Button, ButtonGroup} from "@mui/material";
import AddEmployeeModel from "../../components/AddEmployeeModal";
import AppTable from "../../components/Table";
import AddQuestionnaireModal from "../../components/AddQuestionnaireModal";
import {STORAGE_KEY} from "../../constants";
import {useSnackbar} from "notistack";
import {useClient} from "../../hooks/useClient";


interface AddQuestionnaireRequestProps {
    question: string,
    status: any,
    enabled: true,
    employeeId: string
}

const Questionnaire = () => {
    const {getQuestionnaireList, createQuestionnaire, questionnaireList, clientLoading, roleDropdownValues, getRoleDropDownValues} = useClient();
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    const { enqueueSnackbar } = useSnackbar();


    const [value, setValue] = useState<AddQuestionnaireRequestProps>({

        status: undefined,
        enabled: true,
        question: '',
        employeeId: ''
    });

    const handleMessageChange = (e: any) => {
        setMessage(e.target.value);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const onChangeQuestionnaire = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue({
            ...value,
            question: event.target.value,
        });
    };


    useEffect(() => {
        try {
            const clientId = localStorage.getItem(STORAGE_KEY.UserId);

            getQuestionnaireList();
            setOpen(false);
        } catch (error) {
            setOpen(false);
        }
    }, []);

    useEffect(() => {
        console.log("questionnaireList:", questionnaireList);
    }, [questionnaireList]);


    const onClickCreateQuestionnaire = () => {
        setOpen(true);
    };

    const handleSendClick = () => {
        // Handle sending the message (you can add your logic here)
        console.log('Message sent:', message);

        // Clear the message box after sending
        setMessage('');
    };


    const onClickConfirm = async () => {
        if(value.question === '' ){
            enqueueSnackbar("Please enter mandatory field", { variant: 'error',autoHideDuration: 3000 } )
            return true;
        } else {
            try {
                const employeeId = localStorage.getItem(STORAGE_KEY.UserId);
                await createQuestionnaire({
                    question: value.question,
                    enabled: true,
                    employeeId: employeeId!
                }).then(r => {
                    console.log(r);
                    if(r?.response && r?.response?.status === 400){
                        enqueueSnackbar(r?.response?.data, { variant: 'error',autoHideDuration: 3000 } )
                    }else {
                        console.log(getQuestionnaireList);
                        enqueueSnackbar(r.message, { variant: 'success',autoHideDuration: 3000 } )
                        getQuestionnaireList();
                        window.history.back();
                    }
                });
                setOpen(false);
            } catch (e : any) {
                console.log(e)
                setOpen(false);
            }
        }
    };

    return (
        <div>
            {
                 <div>
                    <Grid container justifyContent="flex-end" spacing={4}>
                        <Grid item>
                            <ButtonGroup variant="text" aria-label="text button group"  sx={{padding: 1}}>
                                <div >
                                    <Button style={{ backgroundColor : "#3361FF"}} variant={'text'} onClick={onClickCreateQuestionnaire} >
                                        Create Questionnaire
                                    </Button>
                                </div>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
               <AddQuestionnaireModal open={open} value={value}  onClickPrimary={onClickConfirm} handleClose={onCloseModal} onChangeQuestionnaireValue={onChangeQuestionnaire} selectedValue={message} ></AddQuestionnaireModal>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <AppTable listData={questionnaireList} hyperlinkPropertyName={'id'} entity={'questionnaireDetails'} ></AppTable>
                    </Box>
                </div>
            }
        </div>

    )
};


const styles = {
    container: {
        maxWidth: '400px',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#f0f8ff', // Light blue background
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 255, 0.2)', // Blue shadow
    },
    textarea: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '4px',
        border: '1px solid #6495ED', // Cornflower blue border
        boxSizing: 'border-box', // Include padding and border in width/height calculation
    },
    sendButton: {
        padding: '12px 20px', // Adjusted padding for the button
        backgroundColor: '#4169E1', // Royal blue button color
        color: '#fff', // White text
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};

export default Questionnaire;
