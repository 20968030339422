import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerContainerProps {
  selectedDate: Date | null;
  onChangeDate: (event: Date | null) => void;
}

const DatePickerContainer = ({ selectedDate, onChangeDate }: DatePickerContainerProps) => {
  return (
    <DatePicker
      selected={ selectedDate }
      onChange={ onChangeDate }
      dateFormat="dd/MM/yyyy" // Customize the date format as needed
      placeholderText="Select a date" />
  )
}

export default DatePickerContainer;