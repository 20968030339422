import React from 'react';

const SignUp = () => {
  return (
    <div>
        <h1>SignUp</h1>
    </div>
  )
}

export default SignUp;