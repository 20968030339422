import {useCallback, useEffect, useState} from 'react';
import {AxiosInstance} from 'axios';
import getApiConfig from '../config/apiConfig';
import {clientEndPoint, vendorEndPoint} from '../constants/EndPoint';
import {STORAGE_KEY} from "../constants";

export interface VendorData {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    gender: string;
    phoneNumber: string;
    enabled: boolean;
    lastPasswordResetDate: string;
    authorities?: {
        authority: string;
    }[];
}

interface UserDetailsProps {
    authorities: [{
        authority: string;
    }],
    email: string;
    enabled: boolean;
    firstName: string;
    gender: string;
    id: string;
    lastName: string;
    lastPasswordResetDate: {
        date: number;
        day: number;
        hours: number;
        minutes: number;
        month: number;
        nanos: number;
        seconds: number;
        time: number;
        timezoneOffset: number;
        year: 0
    },
    phoneNumber: string;
    username: string;
}

export interface QuotesListProps {
    description: string;
    id: string;
    status: string;
    user: UserDetailsProps;
    vendor: UserDetailsProps;
}

export interface SkillsListProps {
    id: number;
    name: string;
    image: string;
}

export interface CreateRequestQuoteProps {
    vendorId: string;
    description: string;
    amount: number;
}

export interface FindVendorProps {
    skillId: number;
}

export interface GetDetailByClient {
    vendorId: number;
    clientId: number;
}

export interface GetMyClientList {
    vendorId: number;
}

export interface AcceptRequestQuoteProps {
    quoteId: string;
    description: string;
}

export interface AcceptTimelineProps {
    quoteId: string;
}

export interface SendTimelineProps {
    amount: number;
    description: string;
    quoteId: string;
    timelines: {
        amount: number; description: string; endDate: number; startDate: number; title: string;
    }[];
    vendorId: string;
}

export const useVendor = () => {
    const apiConfig: AxiosInstance = getApiConfig();

    const [vendorList, setVendorList] = useState<VendorData[] | null>();
    const [vendorDetail, setVendorDetail] = useState<any | {}>();
    const [findVendorSkills, setFindVendorSkills] = useState<any | {}>();
    const [myClientList, setMyClientList] = useState<any | {}>();
    const [vendorQuoteList, setVendorQuoteList] = useState<QuotesListProps[] | null>();
    const [skillsList, setSkillsList] = useState<SkillsListProps[] | []>();
    const [vendorPendingQuoteList, setVendorPendingQuoteList] = useState<QuotesListProps[] | null>();
    const [vendorLoading, setVendorLoading] = useState<boolean>(false);
    const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);
    const [shouldRefetchPending, setShouldRefetchPending] = useState<boolean>(false);
    const [shouldRefetchQuotes, setShouldRefetchQuotes] = useState<boolean>(false);
    const [vendorFindList, setVendorFindList] = useState<any | {}>();
    const [getDetailsByUser, setGetDetailsByUser] = useState<any | {}>();
    const [vendorProjectDocument, setProjectDocuments] = useState<any | []>();

    const fetchVendorList = useCallback(() => {
        setShouldRefetch(true);
    }, []);


    const fetchSkillsList = useCallback(() => {
        setShouldRefetch(true);
    }, []);

    const fetchVendorQuotesList = useCallback(() => {
        setShouldRefetchQuotes(true);
    }, []);

    const fetchVendorPendingQuotesList = useCallback(() => {
        setShouldRefetchPending(true);
    }, []);

    const getVendorList = useCallback(async () => {
        try {
            setVendorLoading(true);
            setShouldRefetch(false);
            const response = await apiConfig.get(vendorEndPoint.GET_ALL);
            const userResponse = response?.data;
            setVendorList(userResponse);
            setVendorLoading(false);
            return userResponse;
        } catch (e) {
            setVendorLoading(false);
            setShouldRefetch(false);
            setVendorList(null);
            return null;
        }
    }, [apiConfig]);

    const getVendorDetail = useCallback(async (id: string) => {
        try {
            setVendorLoading(true);
            setShouldRefetch(false);
            const response = await apiConfig.post(vendorEndPoint.DETAILS, {vendorId: id});
            const userResponse = response?.data;
            localStorage.setItem(STORAGE_KEY.VendorName, userResponse?.name);
            setVendorDetail(userResponse);
            setVendorLoading(false);
            return userResponse;
        } catch (e) {
            setVendorLoading(false);
            setShouldRefetch(false);
            setVendorDetail(null);
            return null;
        }
    }, [apiConfig]);


    const getSkillsList = useCallback(async () => {
        try {
            setVendorLoading(true);
            setShouldRefetch(false);
            const response = await apiConfig.get(vendorEndPoint.GET_SKILLS_LIST);
            const userResponse = response?.data;
            setSkillsList(userResponse);
            setVendorLoading(false);
            return userResponse;
        } catch (e) {
            setVendorLoading(false);
            setShouldRefetch(false);
            setSkillsList([]);
            return null;
        }
    }, [apiConfig]);


    const updateVendorDetail = useCallback(async (data: any) => {
        try {
            setVendorLoading(true);
            setShouldRefetch(false);
            const response = await apiConfig.post(vendorEndPoint.UPDATE, data);
            const userResponse = response?.data;
            setVendorDetail(userResponse);
            setVendorLoading(false);
            return userResponse;
        } catch (e) {
            setVendorLoading(false);
            setShouldRefetch(false);
            setVendorDetail(null);
            return null;
        }
    }, [apiConfig]);

    const createRequest = useCallback(async (payload: CreateRequestQuoteProps) => {
        try {
            const response = await apiConfig.post(vendorEndPoint.CREATE_REQUEST, payload);
            return response?.data;
        } catch (e) {
            return null;
        }
    }, [apiConfig]);


    const getFindVendor = useCallback(async (payload: FindVendorProps) => {
        try {
            setVendorLoading(true);
            setShouldRefetchQuotes(false);
            const response = await apiConfig.post(vendorEndPoint.FIND_VENDOR, payload);
            const userResponse = response?.data;
            setVendorFindList(userResponse);
            setVendorLoading(false);
            return userResponse;
        } catch (e) {
            setVendorLoading(false);
            setShouldRefetchQuotes(false);
            setVendorFindList(null);
            return null;
        }
    }, [apiConfig]);

    const getDetailByUserStatus = useCallback(async (payload: GetDetailByClient) => {
        try {
            setVendorLoading(true);
            setShouldRefetchQuotes(false);
            const response = await apiConfig.post(vendorEndPoint.GET_DETAIL_BY_USER, payload);
            const userResponse = response?.data;
            setGetDetailsByUser(userResponse);
            setVendorLoading(false);
            return userResponse;
        } catch (e) {
            setVendorLoading(false);
            setShouldRefetchQuotes(false);
            setGetDetailsByUser(null);
            return null;
        }
    }, [apiConfig]);

    const acceptRequest = useCallback(async (payload: AcceptRequestQuoteProps) => {
        try {
            const response = await apiConfig.post(vendorEndPoint.ACCEPT_QUOTE, payload);
            return response?.data;
        } catch (e) {
            return null;
        }
    }, [apiConfig]);

    const getMyClientList = useCallback(async (payload: GetMyClientList) => {
        try {
            const response = await apiConfig.post(vendorEndPoint.MY_CLIENT_LIST, payload);
            const userResponse = response?.data;
            setMyClientList(userResponse);
            return userResponse;
        } catch (e) {
            return null;
        }
    }, [apiConfig]);

    const rejectRequest = useCallback(async (payload: AcceptRequestQuoteProps) => {
        try {
            const response = await apiConfig.post(vendorEndPoint.REJECT_QUOTE, payload);
            return response?.data;
        } catch (e) {
            return null;
        }
    }, [apiConfig]);

    const acceptTimeline = useCallback(async (payload: AcceptTimelineProps) => {
        try {
            const response = await apiConfig.post(vendorEndPoint.ACCEPT_TIMELINE, payload);
            return response?.data;
        } catch (e) {
            return null;
        }
    }, [apiConfig]);

    const sendTimeline = useCallback(async (payload: any) => {
        try {
            let payloadDTO = {
                projectId: payload.id,
                vendorId: payload.vendor.id,
                description: payload.description,
                timelines: payload.timelines
            }
            const response = await apiConfig.post(vendorEndPoint.SEND_TIMELINE, payloadDTO);
            return response?.data;
        } catch (e) {
            return null;
        }
    }, [apiConfig]);

    const getVendorProjectDocuments = useCallback(async (projectId: any) => {
        try {
            const response = await apiConfig.get(vendorEndPoint.DOCUMENTS);
            const userResponse = response?.data.data;
            setProjectDocuments(userResponse);
            return response?.data;
        } catch (e) {
            return null;
        }
    }, [apiConfig]);

    const getVendorQuotesList = useCallback(async () => {
        try {
            setVendorLoading(true);
            setShouldRefetchQuotes(false);
            const response = await apiConfig.get(vendorEndPoint.GET_QUOTE_LIST);
            const userResponse = response?.data;
            setVendorQuoteList(userResponse);
            setVendorLoading(false);
            return userResponse;
        } catch (e) {
            setVendorLoading(false);
            setShouldRefetchQuotes(false);
            setVendorQuoteList(null);
            return null;
        }
    }, [apiConfig]);

    const getVendorPendingQuotesList = useCallback(async () => {
        try {
            setVendorLoading(true);
            setShouldRefetchPending(false);
            const response = await apiConfig.get(vendorEndPoint.GET_PENDING_QUOTE);
            const userResponse = response?.data;
            setVendorPendingQuoteList(userResponse);
            setVendorLoading(false);
            return userResponse;
        } catch (e) {
            setVendorLoading(false);
            setShouldRefetchPending(false);
            setVendorPendingQuoteList(null);
            return null;
        }
    }, [apiConfig]);

    const submitBid = useCallback(async (payload: any) => {
        try {
            const response = await apiConfig.post(clientEndPoint.SUBMIT_BID, payload);
            const userResponse = response?.data.data;
            return response?.data;
        } catch (e) {
            return null;
        }
    }, [apiConfig]);

    useEffect(() => {
        if (shouldRefetch) {
            getVendorList();
            setShouldRefetch(false);
        }
    }, [shouldRefetch, getVendorList]);

    useEffect(() => {
        if (shouldRefetch) {
            getSkillsList();
            setShouldRefetch(false);
        }
    }, [shouldRefetch, getSkillsList]);


    useEffect(() => {
        if (shouldRefetchQuotes) {
            getVendorQuotesList();
            setShouldRefetchQuotes(false);
        }
    }, [shouldRefetchQuotes, getVendorQuotesList]);

    useEffect(() => {
        if (shouldRefetchPending) {
            getVendorPendingQuotesList();
            setShouldRefetchPending(false);
        }
    }, [shouldRefetchPending, getVendorPendingQuotesList]);

    return {
        vendorList,
        vendorLoading,
        vendorQuoteList,
        findVendorSkills,
        vendorPendingQuoteList,
        fetchVendorList,
        fetchSkillsList,
        fetchVendorQuotesList,
        fetchVendorPendingQuotesList,
        createRequest,
        acceptRequest,
        rejectRequest,
        sendTimeline,
        acceptTimeline,
        getVendorDetail,
        getDetailsByUser,
        getFindVendor,
        getDetailByUserStatus,
        vendorDetail,
        skillsList,
        vendorFindList,
        updateVendorDetail,
        getMyClientList,
        myClientList,
        vendorProjectDocument,
        getVendorProjectDocuments,
        submitBid,
        getVendorList
    };
};
