import React from "react";
import { Box, Tabs, Tab } from "@mui/material";

interface TabSwitcherProps {
    data: string[];
    value: number;
    onChangeTab: (event: React.SyntheticEvent, index: number) => void;
}

const TabSwitcher = ({ data, value, onChangeTab }: TabSwitcherProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={ value }
        variant={'scrollable'}
        onChange={ onChangeTab }
        style={{backgroundColor: 'primary'}}

      >
        { data?.map((item) => {
            return (
                <Tab key={ item } label={ item } />
            );
        }) }
      </Tabs>
    </Box>
  );
};

export default TabSwitcher;
