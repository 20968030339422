import AppTable, {TableProps} from "components/Table";
import React, {useState} from "react";
import {List, ListAltRounded, ViewModule} from '@mui/icons-material';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {ButtonGroup, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";

interface DataDisplayProps {
    listData: any;
    hyperlinkPropertyName: string;
    skipFields: any;
    cardView: any;
    entity: string;
    onClick: any;
}

const DataDisplay: React.FC<DataDisplayProps> = ({listData, hyperlinkPropertyName, entity,onClick,skipFields, cardView}) => {
    const [viewMode, setViewMode] = useState('list');

    const renderWidget = () => {
        if (viewMode === 'list') {
            return (<AppTable listData={listData} hyperlinkPropertyName={hyperlinkPropertyName}
                              entity={entity} onClick={onClick} hiddenFields={skipFields}/>);
        }
        return (<div className="card-container">
            {cardView()}

        </div>);
    }

    return (<div>
        <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
                <ButtonGroup variant="text" aria-label="text button group">
                    <IconButton onClick={() => setViewMode('list')}>
                        <ListAltRounded />
                    </IconButton>
                    <IconButton onClick={() => setViewMode('cards')}>
                        <ViewModule />
                    </IconButton>
                </ButtonGroup>
            </Grid>
        </Grid>
        {renderWidget()}
    </div>)
};

export default DataDisplay;

