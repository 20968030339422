import { Home, ProfilePage} from '../pages';
import VendorQuoteList from "../pages/VendorPages/VendorQuoteList";
import VendorQuoteDetail from "../pages/VendorPages/VendorQuoteDetail";
import ProjectList from "../pages/ProjectList";
import MyClients from "../pages/MyClients";
import VendorProjectPage from 'pages/VendorProject';
import VendorProfile from "../pages/VendorProfile";
import CreateProject from "../pages/CreateProject";
import EmployeeList from "../pages/EmployeeList";
import WorkPermit from "../pages/WorkPermit";
import CreateWorkPermitPage from 'pages/CreateWorkPermit';
import EmployeeDetails from "../pages/EmployeeDetails";

export const vendorRoutes = [
    { path: '/', element: <Home /> },
    { path: '/quotes', element: <VendorQuoteList /> },
    { path: '/quotes/*', element: <VendorQuoteDetail /> },
    { path: '/projects', element: <ProjectList /> },
    { path: '/myClients', element: <MyClients /> },
    { path: '/projects/*', element: <VendorProjectPage/> },
    { path: '/profile', element: <ProfilePage /> },
    { path: '/vendorProfile', element: <VendorProfile /> },
    { path: '/employee', element: <EmployeeList/> },
    { path: '/employee/:id', element: <EmployeeDetails/> },
    { path: '/workPermit', element: <WorkPermit/> },
    { path: '/workPermit/:id', element: <CreateWorkPermitPage/> },
    { path: '/workPermit/create', element: <CreateWorkPermitPage/> },
];
