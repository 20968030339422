import { RouteType } from "./config";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {Home, ProfilePage} from "../pages";
import VendorProfile from "../pages/VendorProfile";
import {BadgeRounded, Person2, WorkHistoryRounded} from "@mui/icons-material";
import MyClients from "../pages/MyClients";
import ProjectList from "../pages/ProjectList";
import VendorQuotes from "../pages/VendorQuotes";
import ProfilePageLayout from "../layouts/ProfilePageLayout";
import EmployeeList from "pages/EmployeeList";
import WorkPermit from "../pages/WorkPermit";


const vendorRoute: RouteType[] = [
  {
    path: "/",
    element: <VendorProfile />,
    state: "home",
    sidebarProps: {
      displayText: "Home",
      icon: <DashboardOutlinedIcon />
    }
  },
  {
    element: <ProfilePageLayout />,
    state: "profile",
    sidebarProps: {
      displayText: "Profile",
      icon: <Person2 />
    },
    child: [
      {
        path: "/vendorProfile",
        element: <VendorProfile />,
        state: "profile.myProfile",
        sidebarProps: {
          displayText: "My Profile"
        },
      },
      {
        path: "/myClients",
        element: <MyClients />,
        state: "profile.myClients",
        sidebarProps: {
          displayText: "My Client"
        }
      },
    ]
  },
  {
    path: "/quotes",
    element: <VendorQuotes />,
    state: "quotes",
    sidebarProps: {
      displayText: "Quotes",
      icon: <ArticleOutlinedIcon />
    }
  },
  {
    path: "/projects",
    element: <ProjectList />,
    state: "projects",
    sidebarProps: {
      displayText: "Project",
      icon: <FormatListBulletedOutlinedIcon />
    }
  },
  {
    path: "/employee",
    element: <EmployeeList />,
    state: "employee",
    sidebarProps: {
      displayText: "Employee",
      icon: <BadgeRounded />
    }
  },
  {
    path: "/workPermit",
    element: <WorkPermit />,
    state: "employee",
    sidebarProps: {
      displayText: "Work Permit",
      icon: <WorkHistoryRounded />
    }
  }
];

export default vendorRoute;
