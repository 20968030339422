import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Stack, TextField } from '@mui/material';
import AddMoreTimeline, { TimelineInputData } from '../AddMoreTimeline';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: 'calc(100% - 100px)',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
};

interface TimelineModalProps {
  open: boolean;
  value: {
    description: string;
    amount: string;
  }
  timelineInput: TimelineInputData[];
  onChangeTimeline: (index: number, name: keyof TimelineInputData, event: Date | string | null) => void;
  onClickAddMore: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickConfirmTimeline: () => void;
  onCloseModal: () => void;
}

const TimelineModal = ({
  open, value, timelineInput, onChange, onClickConfirmTimeline, onCloseModal, onChangeTimeline, onClickAddMore,
}: TimelineModalProps) => {
  return (
    <Modal
      open={ open }
      onClose={ onCloseModal }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Add Timeline
        </Typography>
        <Stack direction="row" spacing={ 2 }>
          <TextField placeholder="Amount" name="amount" value={ value.amount } onChange={ onChange } />
          <TextField name="description" placeholder="Description" value={ value.description } onChange={ onChange } />
        </Stack>
        <AddMoreTimeline onChange={ onChangeTimeline } onClickAddMore={ onClickAddMore } timelineInput={ timelineInput } />
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={ 4 }>
          <Button variant="contained" onClick={ onClickConfirmTimeline }>Send Timeline</Button>
          <Button variant="outlined" onClick={ onCloseModal }>Cancel</Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default TimelineModal;