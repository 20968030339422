import React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import colobten from "../../assets/images/colobten.jpeg";
import loginBg from "../../assets/images/login-bg.png";
import './Login.css';
import * as url from "url";

interface LoginLayoutProps {
    userName: string;
    password: string;
    onChangeUserName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClickLogin: () => void;
}

const LoginLayout = ({ userName, password, onChangePassword, onChangeUserName, onClickLogin }: LoginLayoutProps) => {
  return (
      <Grid container>
              <Grid item xs={6} sm={6}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px', flexDirection: 'column', minHeight: '100Vh'}}>
                      <img src={loginBg} alt="Colobten" />
                  </Box>
              </Grid>
          <Grid item xs={6} sm={6}>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px', flexDirection: 'column', minHeight: '100Vh'}}>
                  <img src={colobten} alt="Colobten" className="login-logo-img" />
                  <TextField placeholder="user name" value={ userName } onChange={ onChangeUserName } />
                  <TextField       type="password"
                                   placeholder="password name"  value={ password } onChange={ onChangePassword } />
                  <Button sx={{width:250}} style={{ width: 200, backgroundColor : "#3361FF"}} onClick={ onClickLogin }>Login</Button>
              </Box>
      </Grid>
      </Grid>

  )
}

export default LoginLayout;
