// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Dashboard.css */
.dashboard-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 20px; /* Add padding around the entire dashboard */
}
.chart {
    padding: 20px;
    background-color: #f0f0f0; /* Optional: Add a background color to the charts */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Profile/Profile.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;IACI,aAAa;IACb,8BAA8B;IAC9B,cAAc;IACd,aAAa,EAAE,4CAA4C;AAC/D;AACA;IACI,aAAa;IACb,yBAAyB,EAAE,mDAAmD;AAClF","sourcesContent":["/* src/components/Dashboard.css */\n.dashboard-container {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 20px;\n    padding: 20px; /* Add padding around the entire dashboard */\n}\n.chart {\n    padding: 20px;\n    background-color: #f0f0f0; /* Optional: Add a background color to the charts */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
