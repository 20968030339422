import React, {useEffect, useMemo, useState} from 'react';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Divider, FormControl,
    Grid,
    InputLabel, MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import {Tab, TabPanel} from "../../components";
import "./VendorProfile.css"
import {useVendor} from "../../hooks/useVendor";
import {STORAGE_KEY} from "../../constants";
import {LinkedFunction} from "../../constants/LinkedFunction";
import Button from "@mui/material/Button";
import {useSnackbar} from "notistack";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment-timezone";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import AppTable from "../../components/Table";
import {useClient} from "../../hooks/useClient";


const VendorProfile = () => {
    const [tabValue, setTabValue] = React.useState<number>(0);
    const { vendorDetail, vendorLoading, getVendorDetail,updateVendorDetail } = useVendor();
    const { getClientDetails } = useClient();
    const loggedInId = localStorage.getItem(STORAGE_KEY.UserId);
    const dateTime = new Date(Date.now());

    // Format the date as a string with only the date component
    const formattedDate = dateTime.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });


    const [value, setValue] = useState<any>({
        email: '',
        name: '',
        natureOfJob: '',
        panNumber: '',
        companyWebsite: '',
        estdYear: '',
        companyCIN: '',
        phoneNumber: '',
        companyType: '',
        id : 3
    });
    const { enqueueSnackbar } = useSnackbar();

    const tabList = useMemo(() => {
        return ['Company Information', 'Category Mapping', 'Vendor Main Clients', 'Financial Information', 'Tax Information', 'Vendor Designations' , 'Vendor Bank Details'];
    }, []);

    useEffect(() => {
        if(loggedInId != null) {
            getClientDetails({clientId:  loggedInId});
            getVendorDetail(loggedInId);
        }
    }, [loggedInId]);

    useEffect(() => {
        setValue({
            ...vendorDetail,
            email: vendorDetail?.email,
            name: vendorDetail?.name,
            natureOfJob: vendorDetail?.natureOfJob,
            companyWebsite: vendorDetail?.companyWebsite,
            estdYear: vendorDetail?.estdYear,
            companyCIN: vendorDetail?.companyCIN,
            phoneNumber: vendorDetail?.phoneNumber,
            companyType: vendorDetail?.companyType,
            panNumber: vendorDetail?.panNumber,
        })
    }, [vendorDetail])


    const onChangeTab = (event: React.SyntheticEvent, index: number) => {
        setTabValue(index);
    };
    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            name: event.target.value,
        });
    };
    const onChangePan = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            panNumber: event.target.value.toUpperCase(),
        });
    };
    const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            email: event.target.value,
        });
    };
    const onChangeJob = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            natureOfJob: event.target.value,
        });
    };
    const onChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            phoneNumber: event.target.value,
        });
    };
    const onChangeWebsite = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            companyWebsite: event.target.value,
        });
    };
    const onChangeEstdYear = (data: any, metaId: string) => {

        setValue({
            ...value,
            estdYear: metaId
        });
    }
    const onChangeCompanyCIN = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            companyCIN: event.target.value,
        });
    };
    const onChangeCompanyType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            companyType: event.target.value,
        });
    };
    const onSave = async (data:any) => {
        await
            updateVendorDetail(value).then(r => {
                enqueueSnackbar("Profile Updated Successfully", { variant: 'success',autoHideDuration: 3000 } )
            });
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' ,paddingTop: '30px',paddingRight : '40px', float: 'right'}}>
                <div>
                    <Button style={{backgroundColor : "#3361FF"}} onClick={onSave} >
                        Save
                    </Button>
                </div>
            </div>
            <Box sx={{padding: 3}}>
                <Tab  data={tabList} value={tabValue} onChangeTab={onChangeTab}/>
                <TabPanel value={tabValue} index={0}>
                    <Card style={{padding : '20px'}}>
                        <CardContent>
                            <Typography style={{ fontWeight: 'bold' }} variant="h6">Vendor Registration No. V0000{vendorDetail?.id}</Typography>
                            <Typography variant="body2">
                                Date of Registration {formattedDate}
                            </Typography>
                        </CardContent>
                        <Divider/>
                        <Grid style={{padding : '20px'}} container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography style={{ fontWeight: 'bold' }} variant="body2">Company Name :</Typography>
                                <TextField style={{paddingTop : '10PX',height : 30}} multiline={false} minRows={"1"} placeholder="Name" name="name" value={ value.name} onChange={ onChangeName }  />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography style={{ fontWeight: 'bold' }} variant="body2">Company Pan Number :</Typography>
                                <TextField style={{paddingTop : '10PX'}} multiline={false} minRows={"1"} placeholder="Pan Number" name="panNumber" value={ value.panNumber} inputProps={{ maxLength: 10 }} onChange={ onChangePan }  />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography style={{ fontWeight: 'bold' }} variant="body2">Company Email :</Typography>
                                <TextField style={{paddingTop : '10PX'}} multiline={false} minRows={"1"} placeholder="Email" name="email" value={ value.email} onChange={ onChangeEmail }  />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography style={{ fontWeight: 'bold' }} variant="body2">Nature of job :</Typography>
                                <TextField style={{paddingTop : '10PX'}} multiline={false} minRows={"1"} placeholder="Job" name="natureOfJob" value={ value.natureOfJob} onChange={ onChangeJob }  />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography style={{ fontWeight: 'bold' }} variant="body2">Company Type :</Typography>
                                <TextField style={{paddingTop : '10PX'}} multiline={false} minRows={"1"} placeholder="Company Type" name="companyType" value={ value.companyType} onChange={ onChangeCompanyType }  />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography style={{ fontWeight: 'bold' }} variant="body2">Company phone No :</Typography>
                                <TextField style={{paddingTop : '10PX'}} multiline={false} inputProps={{ maxLength: 10 }}  minRows={"1"} placeholder="Phone Number" name="phoneNumber" value={ value.phoneNumber} onChange={ onChangePhoneNumber }  />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography style={{ fontWeight: 'bold' }} variant="body2">Company website :</Typography>
                                <TextField style={{paddingTop : '10PX'}} multiline={false} minRows={"1"} placeholder="Company Website" name="companyWebsite" value={ value.companyWebsite} onChange={ onChangeWebsite }  />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography style={{ fontWeight: 'bold' }} variant="body2">Estd. Year :</Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        sx={{width: '100%',paddingTop : '10PX',maxWidth : '205PX'}}
                                        disablePast={false}
                                        value={ value.estdYear ? new Date(value.estdYear) : Date.now() }
                                        onChange={ (e:any) => {
                                            const parsedDate = moment(e, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)");
                                            const milliseconds = parsedDate.valueOf();
                                            console.log(milliseconds);
                                            onChangeEstdYear(milliseconds, e)
                                        }}
                                    /></LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography style={{ fontWeight: 'bold' }} variant="body2">Company CIN :</Typography>
                                <TextField style={{paddingTop : '10PX'}} multiline={false} minRows={"1"} placeholder=" Company CIN" name="companyCIN" value={ value.companyCIN} onChange={ onChangeCompanyCIN }  />
                            </Grid>
                        </Grid>
                    </Card>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <AppTable listData={vendorDetail?.vendorSkills} hyperlinkPropertyName="" entity='vendorSkills' enabledEdit={true} onUpdate={
                        (val:any, index: any)=>  {
                            value.vendorSkills[index] = val;
                            setValue({
                                ...value
                            })
                        }
                    } />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <AppTable listData={vendorDetail?.vendorMainClients} hyperlinkPropertyName="" entity='vendorMainClients' enabledEdit={true} onUpdate={
                        (val:any, index: any)=>  {
                            value.vendorMainClients[index] = val;
                            setValue({
                                ...value
                            })
                        }
                    }/>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <AppTable listData={vendorDetail?.vendorFinancialDetails} hyperlinkPropertyName="" entity='vendorFinancialDetails' enabledEdit={true} onUpdate={
                        (val:any, index: any)=>  {
                            value.vendorFinancialDetails[index] = val;
                            setValue({
                                ...value
                            })
                        }
                    }/>
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                    <AppTable listData={vendorDetail?.vendorCompanies} hyperlinkPropertyName="" entity='vendorCompanies' enabledEdit={true} onUpdate={
                        (val:any, index: any)=>  {
                            value.vendorCompanies[index] = val;
                            setValue({
                                ...value
                            })
                        }
                    }/>
                </TabPanel>
                <TabPanel value={tabValue} index={5}>
                    <AppTable listData={vendorDetail?.vendorDesignations} hyperlinkPropertyName="" entity='vendorDesignations' enabledEdit={true} onUpdate={
                        (val:any, index: any)=>  {
                            value.vendorDesignations[index] = val;
                            setValue({
                                ...value
                            })
                        }
                    }/>
                </TabPanel>
                <TabPanel value={tabValue} index={6}>
                    <AppTable listData={vendorDetail?.vendorBankDetails} hyperlinkPropertyName="" entity='vendorBankDetails' enabledEdit={true} onUpdate={
                        (val:any, index: any)=>  {
                            value.vendorBankDetails[index] = val;
                            setValue({
                                ...value
                            })
                        }
                    }/>
                </TabPanel>
            </Box>
        </div>
    )
}


export default VendorProfile
