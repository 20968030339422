import React, {useEffect, useState} from 'react';
import {Box, Button, ButtonGroup} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useClient} from "../../hooks/useClient";
import {STORAGE_KEY} from "../../constants";
import AppTable from "../../components/Table";
import AddWorkPermitModal from "../../components/AddWorkPermitModal";
import {UserRole} from "../../constants/enum";
import {useUser} from "../../provider/UserProvider";
import Loader from "../../components/Loader/Loader";


interface AddWorkPermitRequestProps {
    endDate: any,
    startDate: any,
    createdBy: number,
    createdDate: number,
    name: string,
    status: any,
    enabled: true,
}

const WorkPermit = () => {
    const {getWorkPermitList, workPermitList, clientLoading} = useClient();
    const [open, setOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const {userData} = useUser();
    const [value, setValue] = useState<AddWorkPermitRequestProps>({
        createdBy: 0,
        createdDate: 0,
        endDate: undefined,
        startDate: undefined,
        status: undefined,
        enabled: true,
        name: ""

    });


    useEffect(() => {
        try {
            var clientId = localStorage.getItem(STORAGE_KEY.UserId);

            if(userData.role  === UserRole.CLIENT_EMPLOYEE){
                clientId  = localStorage.getItem(STORAGE_KEY.ClientId);
            }

            getWorkPermitList(clientId);
            setOpen(false);
        } catch (error) {
            setOpen(false);
        }
    }, []);

    const onClickCreateEmployee = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };


    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value, name: event.target.value,
        });
    };

    const onChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value, endDate: event.target.value,
        });
    };

    const onChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value, startDate: event.target.value,
        });
    };


    const onClickConfirm = async () => {
        if (value.name === '' && value.startDate === '' && value.endDate === '') {
            enqueueSnackbar("Please enter mandatory field", {variant: 'error', autoHideDuration: 3000})
            return true;
        } else if (value.name.length < 5) {
            enqueueSnackbar("Username Need at least 5 characters", {variant: 'error', autoHideDuration: 3000})
            return true;
        } else {
            try {
            } catch (e: any) {
                console.log(e)
                setOpen(false);
            }
        }
    };

    const onClickCreateWorkPermit = () => {
        navigate('/workPermit/create');
    }

    // @ts-ignore
    return (
        <div>
            {clientLoading ? <Loader></Loader> :  <div>
                <Grid container justifyContent="flex-end" spacing={4}>
                    <Grid item>
                        <ButtonGroup variant="text" aria-label="text button group" sx={{padding: 1}}>
                            <div>
                                {userData.role === UserRole.ROLE_VENDOR &&
                                    <Button style={{backgroundColor: "#3361FF"}} variant={'text'}
                                            onClick={onClickCreateWorkPermit}>
                                        Create work permit
                                    </Button>}
                            </div>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                    <AddWorkPermitModal
                        open={open}
                        value={value}
                        handleClose={onCloseModal}
                        onClickPrimary={onClickConfirm}
                        onChangeEndDate={onChangeEndDate}
                        onChangeName={onChangeName}
                        onChangeStartDate={onChangeStartDate}/>
                </Box>
                <Box sx={{width: '100%'}}>
                    <AppTable listData={workPermitList} hyperlinkPropertyName={'id'} entity={'workPermit'}></AppTable>
                </Box>
            </div>}
        </div>
       )
}

export default WorkPermit;
