import React, {useEffect, useState} from 'react';
import {Box, Button, ButtonGroup, Stack, TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useLocation, useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useClient} from "../../hooks/useClient";
import {STORAGE_KEY} from "../../constants";


const style = {
    position: 'absolute' as 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
};


interface AddEmployeeRequestProps {
    id: number,
    roleId: number,
    address: string,
    clientId: string,
    dob: string,
    email: string,
    enabled: true,
    gender: string,
    gstNo: string,
    name: string,
    password: string,
    phoneNumber: string,
    userId: number,
    username: string,
    vendorId: number
}

const EmployeeDetails = () => {
    const {getEmployeeList, createEmployee,getEmployeeDetail, roleDropdownValues, setRoleDropdownValues, getRoleDropDownValues} = useClient();
    const [open, setOpen] = useState<boolean>(false);
    const location = useLocation();
    const employeeDetails: any = location?.state?.data || {};
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [test, setTest] = useState('');
    console.log('test', test);
    const [dropdownValues, setDropdownValues] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>)  => {
        // setSelectedValue(event.target.value);
        setValue({ ...value, roleId: Number(event.target.value) });
    };


    const [value, setValue] = useState<AddEmployeeRequestProps>({
        roleId: 0,
        id: 0,
        address: "",
        clientId: "",
        dob: "",
        email: "",
        enabled: true,
        gender: "",
        gstNo: "",
        name: "",
        password: "",
        phoneNumber: "",
        userId: 0,
        username: "",
        vendorId: 0,

    });

    useEffect(() => {
        setTest('testing');
        if (employeeDetails) {
            setValue({
                ...value,
                phoneNumber: employeeDetails.data.phoneNumber,
                address: employeeDetails.data.address,
                email: employeeDetails.data.email,
                gstNo: employeeDetails.data.gstNo,
                name: employeeDetails.data.name,
                password: employeeDetails.data.password,
                username: employeeDetails.data.username,
                gender: employeeDetails.data.gender,
                roleId: employeeDetails.data.roleId,
            });
        }
    }, [employeeDetails]);


    useEffect(() => {
        try {
            const clientId = localStorage.getItem(STORAGE_KEY.UserId);

            getEmployeeList();
            getEmployeeDetail(employeeDetails.data.id);
            getRoleDropDownValues();
            console.log(getRoleDropDownValues);
            console.log("getRoleDropDownValues");

            setOpen(false);
        } catch (error) {
            setOpen(false);
        }
    }, []);

    const onClickCreateEmployee = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const onChange = (event: string, values: string) => {
        setValue({
            ...value,
            [values]: event,
        });
    };


    const onClickConfirm = async () => {
        if( value.phoneNumber === ''){
            enqueueSnackbar("Please enter mandatory field", { variant: 'error',autoHideDuration: 3000 } )
            return true;
        } else {
            try {
                const clientId = localStorage.getItem(STORAGE_KEY.UserId);
                await createEmployee({
                    roleId: value.roleId,
                    id: employeeDetails.data.id,
                    address: value.address,
                    clientId: clientId!,
                    dob: value.dob,
                    email: value.email,
                    enabled: true,
                    gender: value.gender,
                    gstNo: value.gstNo,
                    name: value.name,
                    password: value.password,
                    phoneNumber: value.phoneNumber,
                    userId: value.userId,
                    username: value.username,
                    vendorId: value.vendorId
                }).then(r => {
                    console.log(r);
                    if(r?.response && r?.response?.status === 400){
                        enqueueSnackbar(r?.response?.data, { variant: 'error',autoHideDuration: 3000 } )
                    }else {
                        console.log(getEmployeeList);
                        enqueueSnackbar(r.message, { variant: 'success',autoHideDuration: 3000 } )
                        getEmployeeList();
                        window.history.back();
                    }
                });
                setOpen(false);
            }  catch (e : any) {
                console.log(e)
                setOpen(false);
            }
        }
    };

    console.log(value.roleId);
    console.log(value);
    console.log("value.roleId");


    // @ts-ignore
    return (
        <Box sx={style} width={400}>
            <TextField multiline={false} minRows={"1"} placeholder="Name" name="name" value={ value.name} onChange={(e) => onChange(e.target.value, 'name')} />
            <TextField multiline={false} minRows={"1"} placeholder="User Name" name="username" value={ value.username } onChange={ (e) => onChange(e.target.value, 'username') } />
            <TextField multiline={false} minRows={"1"} placeholder="Phone Number" name="phoneNumber" value={ value.phoneNumber } onChange={ (e) => onChange(e.target.value, 'phoneNumber')} />
            <TextField multiline={false} minRows={"1"} placeholder="Email" name="email" value={ value.email } onChange={ (e) => onChange(e.target.value, 'email') } />
            <TextField multiline={false} minRows={"1"} placeholder="Address" name="address" value={ value.address} onChange={ (e) => onChange(e.target.value, 'address') } />
            <TextField multiline={false} minRows={"1"} placeholder="Gender" name="gender" value={ value.gender } onChange={ (e) => onChange(e.target.value, 'gender') } />
            {/*<TextField multiline={false} minRows={"1"} placeholder="GST No" name="gstNo" value={ value.gstNo } onChange={ (e) => onChange(e.target.value, 'gstNo') } />*/}
            {/*<TextField multiline={false} minRows={"1"} placeholder="Password" name="password" value={ value.password } onChange={(e) => onChange(e.target.value, 'password')} />*/}
            <div>
                <select value={value.roleId} onChange={handleDropdownChange} aria-placeholder={'Role'}  style={{ width: '400px',height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                >
                    {
                        roleDropdownValues.map((role: any) => (

                        <option key={role.id} value={role.id}>
                            {role.name}
                        </option>
                    ))}
                </select>
            </div>
            <Stack direction="row" spacing={ 4 } >
                <Button style={{ backgroundColor : "#3361FF"}}  variant="outlined" onClick={ onCloseModal }>Cancel</Button>
                <Button style={{ backgroundColor : "#3361FF"}}  variant="contained" onClick={ onClickConfirm }>Create</Button>
            </Stack>
        </Box>
    )
}

export default EmployeeDetails;
