import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../hooks/useAuth';
import {useNavigate} from 'react-router-dom';
import {UserRole} from '../../constants/enum';
import {STORAGE_KEY} from '../../constants';
import {useSnackbar} from "notistack";
import { useUser } from 'provider/UserProvider';
import OnboardingLayout from 'layouts/OnboardingLayout';

const Onboarding = () => {
  const { getClientByOnboardingCode, sendOtp, verifyOtp } = useAuth();
  const { onboardingClient} = useUser();
  const navigate = useNavigate();
  const [otpSent, setOtpSent] = useState<boolean>(false);

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    const isLoggedIn = localStorage.getItem(STORAGE_KEY.AuthToken);
    const onboardingCode = window.location.search.split('=')[1];
    getClientByOnboardingCode(onboardingCode);
  
    if (isLoggedIn) {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    console.log(onboardingClient);
  } ,[onboardingClient]);

  const onChangePhonenNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  }

  const onChangeOtp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(event.target.value);
  }

  const onClickVerify = async () => {

    try {
      const response = await verifyOtp({
        clientId: onboardingClient?.id,
        mobileNumber: phoneNumber,
        otp: otp
      });
      if (response?.includes(UserRole.ROLE_CLIENT)) {
        enqueueSnackbar('Login Successfully', { variant: 'success',autoHideDuration: 3000 } )
        navigate('/');
      } else {
        enqueueSnackbar('Login Successfully', { variant: 'success',autoHideDuration: 3000 } )
        navigate('/');
      }
    } catch (error) {
      enqueueSnackbar('OTP Sent Failed', { variant: 'error',autoHideDuration: 3000 } )
      console.log(error);
    }

  }

  const onClickSendOtp = async () => {
    try {
      const response = await sendOtp({
        clientId: onboardingClient?.id,
        mobileNumber: phoneNumber,
      });
      if(response.status === 200) {
        setOtpSent(true);
        enqueueSnackbar('OTP Sent Successfully', { variant: 'success',autoHideDuration: 3000 } )
      }
      else {
        enqueueSnackbar('OTP Sent Failed', { variant: 'error',autoHideDuration: 3000 } );
      }
    } catch (error) {
      enqueueSnackbar('OTP Sent Failed', { variant: 'error',autoHideDuration: 3000 } )
      console.log(error);
    }
  }

  return (
    <Box>
      <OnboardingLayout
        phoneNumber={phoneNumber}
        otp={otp}
        isOtpSent={otpSent}
        clientId={onboardingClient?.id}
        onChangePhonenNumber={onChangePhonenNumber}
        onChangeOtp={onChangeOtp}
        onVerifyOtp={onClickVerify}
        onSendOtp={onClickSendOtp} />
    </Box>
  )
}

export default Onboarding;

