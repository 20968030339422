import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, ButtonGroup, Divider, Grid, Typography } from '@mui/material';
import { useVendor } from '../../hooks/useVendor';
import DataDisplay from "../../components/DataDisplay";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from '@mui/material/Stack';
import { useClient } from "../../hooks/useClient";
import { AppRoutes } from 'constants/EndPoint/route';


const FindVendor = () => {
    const { fetchSkillsList, skillsList, getFindVendor } = useVendor();
    const { lotoByClient, getOnboardingCode } = useClient();
    const [lotoList, setLotoList] = useState<any[]>([]);

    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);


    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        fetchSkillsList();
        getData();
    }, [fetchSkillsList]);

    async function getData() {
        let data = await lotoByClient();
        setLotoList([...data]);
    }


    const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const onClickCard = async (event: number) => {
        navigate('/vendorList', { state: { data: event } });
    }

    const renderCardView = () => {
        return <Grid container spacing={2}>
            {skillsList?.map((card) => (<Grid item xs={12} sm={2} key={card.id}>
                <Card onClick={() => onClickCard(card.id)}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={card.image} style={{ width: 30, height: 30, marginBottom: 2 }} alt={'Image'} />
                        <Typography variant="body1" component="div" align="center">
                            {card.name}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>))}
        </Grid>
    }

    const showQr = async () => {
        const code = await getOnboardingCode();
        console.log(code);
        const value = 'http://colobten.com/onboarding?code=' + code;
        navigate(AppRoutes.APP_QR,
            { state: { data: { data: value } } });
    }

    return (<Box sx={{ padding: '30px' }}>
        <Typography variant="h4" component="div" align="center">
            Dashboard
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonGroup sx={{ alignItems: 'right' }} variant="text" color="primary" aria-label="text primary button group" style={{ marginTop: 10 }}>
                <Button variant="contained" color="primary" onClick={showQr}>
                    Show QR
                </Button>
            </ButtonGroup>
        </Box>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />

        <Card sx={{ width: '250px', cursor: 'pointer' }}>
            <Box sx={{ p: 2, display: 'flex' }}>
                <Stack spacing={0.5} style={{ flexGrow: 1 }}>
                    <Stack direction={"row"} style={{ justifyContent: "space-between" }}>
                        <Typography fontWeight={700} fontSize={16}>Open Loto</Typography>
                    </Stack>
                    <Typography fontWeight={700} fontSize={"42px"}>
                        {lotoList.filter((x) => x.status == 0).length}
                    </Typography>
                </Stack>
            </Box>
            <Divider />
        </Card>
        <Card sx={{ width: '250px', cursor: 'pointer', marginTop: 2 }}>
            <Box sx={{ p: 2, display: 'flex' }}>
                <Stack spacing={0.5} style={{ flexGrow: 1 }}>
                    <Stack direction={"row"} style={{ justifyContent: "space-between" }}>
                        <Typography fontWeight={700} fontSize={16}>Closed Loto</Typography>
                    </Stack>
                    <Typography fontWeight={700} fontSize={"42px"}>
                        {lotoList.filter((x) => x.status == 1).length}
                    </Typography>
                </Stack>
            </Box>
            <Divider />
        </Card>
        {skillsList != null && skillsList.length > 0 && <>
            <Typography variant="body1" component="div" align="center">
                Vendor Category
            </Typography>

            <DataDisplay listData={skillsList} hyperlinkPropertyName={''} onClick={(index: any) => {
                if (skillsList) {
                    onClickCard(skillsList[index].id)
                }
            }} entity={'skills'} cardView={renderCardView} skipFields={['image']} /></>}

    </Box>)
}

export default FindVendor;
