import React, { useEffect } from 'react';
import {Box, Toolbar, Typography, Stack, colors} from '@mui/material';
import { STORAGE_KEY } from '../../constants';
import { useUser } from '../../provider/UserProvider';
import {Outlet, useLocation} from 'react-router-dom';
import Topbar from "../../components/Topbar/Topbar";
import sizeConfigs from "../../config/sizeConfigs";
import colobten from "../../assets/images/colobten.jpeg";
import Sidebar from "../common/Sidebar";
import colorConfigs from "../../config/colorConfigs";
import "../../components/Topbar/Topbar.css"
import {red} from "@mui/material/colors";

interface AppLayoutProps {
    children: React.ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { setUserData, userData } = useUser();
  const location = useLocation();

  const onClickDrawerMenu = () => {
    setIsOpen(!isOpen);
  };
  const isLoginPage =  localStorage.getItem(STORAGE_KEY.AuthToken) ==   null;;


  console.log("userData", userData);

  useEffect(() => {
    const userDetails = localStorage.getItem(STORAGE_KEY.UserDetails);
    if (userDetails) {
      const userDataFromStorage = JSON.parse(userDetails);
      setUserData((prevUserData) => ({
        ...prevUserData,
        role: userDataFromStorage,
      }));
    }
  }, [setUserData]);
  return (
      <Box sx={{ display: "flex", backgroundColor : isLoginPage ? colors.grey["100"] : "#233044" }}>
        {!isLoginPage && <Topbar onMenuClick={onClickDrawerMenu} isOpen={isOpen}/>}
          {!isLoginPage && isOpen &&  <Box
              component="nav"
              sx={{
                  width: sizeConfigs.sidebar.width,
                  flexShrink: 0,
                  backgroundColor : "#233044",
              }}
          >
              <Typography sx={{ flexGrow: 1, textAlign: 'center',  paddingTop : "10Px" , width : "200px"}} variant="h6">
                  <img src={colobten} alt="Colobten" className="logo-img" style={{ position : "fixed"}}/>
              </Typography>
              <Sidebar isOpen={isOpen} setOpen={(val:any)=> setIsOpen(!isOpen)}/>
          </Box>}

        <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: `calc(100% - ${sizeConfigs.sidebar.width})`,
              minHeight: "100vh",
              marginTop :"50px",
              backgroundColor: colorConfigs.mainBg
            }}
        >
          <div>{ children }</div>
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
  )
}

export default AppLayout;
