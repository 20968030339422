import {Avatar, Drawer, List, Stack, Toolbar, Typography} from "@mui/material";
import colorConfigs from "../../config/colorConfigs";
import sizeConfigs from "../../config/sizeConfigs";
import vendorRoute from "../../routers/VendorRoute";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import React, {useEffect, useState} from "react";
import {UserRole} from "../../constants/enum";
import {useUser} from "../../provider/UserProvider";
import clientRoute from "../../routers/ClientRoute";
import {STORAGE_KEY} from "../../constants";
import {useVendor} from "../../hooks/useVendor";
import {useClient} from "../../hooks/useClient";
import clientEmployeeRoute from "../../routers/ClientEmployeeRoute";


const Sidebar = (props: {isOpen: boolean, setOpen: any}) => {
    const { userData } = useUser();
    const [open, setOpen] = useState<boolean>(false);
    const loggedInId = localStorage.getItem(STORAGE_KEY.UserId);
    const vendorName = localStorage.getItem(STORAGE_KEY.VendorName);
    const clientName = localStorage.getItem(STORAGE_KEY.ClientName);
    const { getVendorDetail, vendorDetail } = useVendor();
    const { getClientDetails , clientDetails } = useClient();


    useEffect(() => {
        if(loggedInId != null) {
            getClientDetails({clientId:  loggedInId});
            getVendorDetail(loggedInId);
        }
    }, []);

    function getName() {
        return userData.role?.includes(UserRole.ROLE_VENDOR) ? localStorage.getItem(STORAGE_KEY.VendorName) : localStorage.getItem(STORAGE_KEY.ClientName);

    }

    function getSideNavItem() {
        if(userData === undefined) return (<div/>);
        if(userData.role?.includes(UserRole.ROLE_VENDOR)) {
            return vendorRoute.map((route, index) => (
                route.sidebarProps ? (
                    route.child ? (
                        <SidebarItemCollapse item={route} key={index} />
                    ) : (
                        <SidebarItem item={route} key={index} onClick={() => props.setOpen(!open)} />
                    )
                ) : null
            ));
        }
        if(userData.role.includes(UserRole.CLIENT_EMPLOYEE)) {
            return clientEmployeeRoute.map((route, index) => (
                route.sidebarProps ? (
                    route.child ? (
                        <SidebarItemCollapse item={route} key={index} />
                    ) : (
                        <SidebarItem item={route} key={index}  onClick={() => props.setOpen(!open)}
                        />
                    )
                ) : null
            ))
        }
        return clientRoute.map((route, index) => (
            route.sidebarProps ? (
                route.child ? (
                    <SidebarItemCollapse item={route} key={index} />
                ) : (
                    <SidebarItem item={route} key={index}  onClick={() => props.setOpen(!open)}
                    />
                )
            ) : null
        ))
    }

    return (
      <Drawer
          variant="temporary"
          open={props.isOpen}
          onClose={() => props.setOpen(!open)}
          sx={{
              width: sizeConfigs.sidebar.width,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                  width: sizeConfigs.sidebar.width,
                  boxSizing: "border-box",
                  borderRight: "0px",
                  backgroundColor: "#233044",
                  color: colorConfigs.sidebar.color
              }
          }}
      >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', marginTop : "20px" }}>
              <span style={{ marginRight: '10px', fontWeight : 'bold', fontSize : 25, marginLeft : '20PX' }}>Welcome :</span>
              <span style={{fontSize : 20}}>{getName()}</span>
          </div>
          <List disablePadding>
                  <Stack
                      sx={{ width: "100%", marginTop : "25PX"}}
                      direction="row"
                      justifyContent="center"
                  >
                  </Stack>
              {getSideNavItem()}
          </List>
      </Drawer>
  );
};

export default Sidebar;
