import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {useSnackbar} from "notistack";
import {useClient} from "../../hooks/useClient";
import {STORAGE_KEY} from "../../constants";
import MedicalReportUpsertComponent from "../../components/AddEmployeeMedicalModal";
import {useLocation} from "react-router-dom";

interface MedicalReportUpsertModalProps {
    id?: number,
    enabled: true,
    companyName: string,
    clientId: string,
    date: string,
    designation: string,
    ifAmbulanceService: string,
    emergencyContact: boolean,
    employeeReport: string,
    ifEmergencyContact: string,
    ifMedicalTeamActions: string,
    ambulanceService: boolean,
    isSick: boolean,
    name: string,
    preCautions: string,
    medicalTeamActions: boolean,
}

const MedicalReportUpsert = () => {
    const { createEmployeeMedical, clientLoading, getMedicalReportList, getMedicalReportDetail} = useClient();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const employeeMedicalDetails: any = location?.state?.data || null;

    const [value, setValue] = useState<MedicalReportUpsertModalProps>({
        id: undefined,
        ambulanceService: false,
        companyName: "",
        clientId: "",
        date: "",
        designation: "",
        emergencyContact: false,
        employeeReport: "",
        enabled: true,
        ifAmbulanceService: "",
        ifEmergencyContact: "",
        ifMedicalTeamActions: "",
        isSick: false,
        medicalTeamActions: false,
        name: "",
        preCautions: ""

    });

    const handleMedicalTeamActionsToggleChange = (event: any) => {
        setValue({...value, medicalTeamActions : event.target.checked})
    };

    const ambulanceServiceToggleChange = (event: any) => {
        setValue({...value, ambulanceService : event.target.checked})
    };

    const emergencyContactToggleChange = (event: any) => {
        setValue({...value, emergencyContact : event.target.checked})
    };

    const sickToggleChange = (event: any) => {
        setValue({...value, isSick : event.target.checked})
    };

    const onChangeCompanyName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            companyName: event.target.value,
        });
    };

    const onChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            date: event.target.value,
        });
    };

    const onChangeDesignation = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            designation: event.target.value,
        });
    };


    const onChangeEmployeeReport = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            employeeReport: event.target.value,
        });
    };

    const onChangeIfAmbulanceService = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            ifAmbulanceService: event.target.value,
        });
    };

    const onChangeIfEmergencyContact = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            ifEmergencyContact: event.target.value,
        });
    };


    const onChangeIfMedicalTeamActions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            ifMedicalTeamActions : event.target.value,
        });
    };

    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            name: event.target.value,
        });
    };


    const onChangePrecautions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            preCautions : event.target.value,
        });
    };

    const onCancel = () =>  {
        window.history.back();
    }

    const onSubmit = async () => {
        if (value.name.length < 3) {
            enqueueSnackbar("Name is mandatory", { variant: 'error', autoHideDuration: 3000 });
            return true;
        } else if (value.companyName.length < 5) {
            enqueueSnackbar("Company name is mandatory", { variant: 'error', autoHideDuration: 3000 });
            return true;
        } else {
            try {
                let clientId = localStorage.getItem(STORAGE_KEY.UserId);
                if (clientId == null) {
                    const params = new URLSearchParams(window.location.search);
                    clientId = params.get('cId');
                }
                if (!clientId) {
                    return;
                }
                await createEmployeeMedical({
                    clientId: clientId,
                    ambulanceService: value.ambulanceService,
                    companyName: value.companyName,
                    date: value.date,
                    designation: value.designation,
                    emergencyContact: value.emergencyContact,
                    employeeReport: value.employeeReport,
                    enabled: true,
                    ifAmbulanceService: value.ifAmbulanceService,
                    ifEmergencyContact: value.ifEmergencyContact,
                    ifMedicalTeamActions: value.ifMedicalTeamActions,
                    isSick: value.isSick,
                    medicalTeamActions: value.medicalTeamActions,
                    name: value.name,
                    preCautions: value.preCautions
                }).then(r => {
                    console.log(r);
                    if (r?.response && r?.response?.status === 400) {
                        enqueueSnackbar(r?.response?.data, { variant: 'error', autoHideDuration: 3000 });
                    } else {
                        enqueueSnackbar(r.message, { variant: 'success', autoHideDuration: 3000 });
                        getMedicalReportList();
                        window.history.back();
                    }
                });
            } catch (e) {
                console.log(e);
            }
        }
    };

    useEffect(() => {
        if (employeeMedicalDetails) {
            setValue({
                ...value,
                id: employeeMedicalDetails.data.id,
                ambulanceService: employeeMedicalDetails.data.ambulanceService,
                companyName: employeeMedicalDetails.data.companyName,
                date: employeeMedicalDetails.data.date,
                designation: employeeMedicalDetails.data.designation,
                emergencyContact: employeeMedicalDetails.data.emergencyContact,
                employeeReport: employeeMedicalDetails.data.employeeReport,
                enabled: true,
                ifAmbulanceService: employeeMedicalDetails.data.ifAmbulanceService,
                ifEmergencyContact: employeeMedicalDetails.data.ifEmergencyContact,
                ifMedicalTeamActions: employeeMedicalDetails.data.ifMedicalTeamActions,
                isSick: employeeMedicalDetails.data.isSick,
                medicalTeamActions: employeeMedicalDetails.data.medicalTeamActions,
                name: employeeMedicalDetails.data.name,
                preCautions: employeeMedicalDetails.data.preCautions
            });
        }
    }, [employeeMedicalDetails]);


    useEffect(() => {
        if(employeeMedicalDetails != null) {
            getMedicalReportDetail(employeeMedicalDetails.data.id);
        }
    }, []);

    return (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <MedicalReportUpsertComponent
                        value={value}
                        onSubmit={onSubmit}
                        onChangeCompanyName={onChangeCompanyName}
                        onChangePrecautions={onChangePrecautions}
                        onChangeDate={onChangeDate}
                        onChangeName={onChangeName}
                        onChangeIfMedicalTeamActions={onChangeIfMedicalTeamActions}
                        onChangeIfEmergencyContact={onChangeIfEmergencyContact}
                        onChangeDesignation={onChangeDesignation}
                        onChangeIfAmbulanceService={onChangeIfAmbulanceService}
                        onChangeEmployeeReport={onChangeEmployeeReport}
                        onChangeMedicalTeamActionsToggle={handleMedicalTeamActionsToggleChange}
                        onChangeAmbulanceServiceToggle={ambulanceServiceToggleChange}
                        onChangeEmergencyContactToggle={emergencyContactToggleChange}
                        onChangeSickToggle={sickToggleChange}></MedicalReportUpsertComponent>
                </Box>
    )
}

export default MedicalReportUpsert;

