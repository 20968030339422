import { ChangeEvent, useState } from "react";

export interface SendTimelineProps {
    amount: string;
    description: string;
}

export const useTimelineForm = (initialState: SendTimelineProps) => {
    const [timelineValue, setTimelineValue] = useState<SendTimelineProps>(initialState);
  
    const onChangeTimelineForm = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setTimelineValue((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    };
  
    const resetForm = () => {
      setTimelineValue(initialState);
    };
  
    return [timelineValue, onChangeTimelineForm, resetForm] as const;
};