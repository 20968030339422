// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-logo-img{
    width: 200px;
    height: 100px;
}
.login-side-img{
    flex: 1 1;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/OnboardingLayout/Login.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;AACA;IACI,SAAO;AACX","sourcesContent":[".login-logo-img{\n    width: 200px;\n    height: 100px;\n}\n.login-side-img{\n    flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
