import { projectEndPoint } from './project';
import { clientEndPoint } from "./client";
import { vendorEndPoint } from "./vendor";
import { authEndPoint } from "./auth";

export {
    clientEndPoint,
    vendorEndPoint,
    projectEndPoint,
    authEndPoint,
}