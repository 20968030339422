import React, {useMemo} from "react";
import {Box, Button,  Stack} from "@mui/material";
import { Tab, TabPanel} from "../../../components";
import { QuotesListProps } from"../../../hooks/useVendor";
import AppTable from"../../../components/Table";
import noData from "../../../assets/images/no-data.png";

interface QuotesLayoutProps {
    vendorQuotesList: QuotesListProps[];
    onClickAccept: () => void;
    onClickReject: () => void;
}


const VendorQuotesLayout = ({
                                vendorQuotesList,
                                onClickAccept,
                                onClickReject,
                            }: QuotesLayoutProps) => {

    const [tabValue, setTabValue] = React.useState<number>(0);
    const onChangeTab = (event: React.SyntheticEvent, index: number) => {
        setTabValue(index);
    };

    const processData = (data: any[]) => {
        return data.map((d) => {
            if(d.client != null) {
                d.companyName = d.client.name;
            }
            return d;
        })
    }

    let pendingWithMe = processData(vendorQuotesList.filter((q) => q.status !== 'TIMELINE_SENT' && q.status !== 'TIMELINE_ACCEPTED'));
    let pendingWithClient = vendorQuotesList.filter((q) => q.status === 'TIMELINE_SENT');
    const tabList = useMemo(() => {
        return [ 'Pending with Me', 'Pending with Client'];
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <Tab data={ tabList } value={ tabValue } onChangeTab={ onChangeTab } />
            <TabPanel value={ tabValue } index={ 0 }>
                <AppTable listData={pendingWithMe} hyperlinkPropertyName="id" entity='quotes' hiddenFields={['meetingId', 'amount']}/>
            </TabPanel>
            <TabPanel value={ tabValue } index={ 1 }>
                <AppTable listData={pendingWithClient} hyperlinkPropertyName="id" entity='quotes' />
            </TabPanel>
        </Box>
    );
};

export default VendorQuotesLayout;
