// Popup.js
import React, {useState, CSSProperties, useEffect} from 'react';
import {useClient} from "../../hooks/useClient";
import {useLocation} from "react-router-dom";
import {STORAGE_KEY} from "../../constants";
import {useSnackbar} from "notistack";
import employeeDetails from "../../pages/EmployeeDetails";

const styles: { [key: string]: CSSProperties } = {
    popupContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#f0f8ff', // Light blue background
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 255, 0.2)', // Blue shadow
        padding: '20px',
        width: '400px',
    },
    inputField: {
        margin: '10px 0',
        padding: '8px',
        width: '100%',
        boxSizing: 'border-box',
    },
    messageInputField: {
        margin: '10px 0',
        padding: '8px',
        width: '100%',
        height: '150px',
        boxSizing: 'border-box',
    },
    button: {
        margin: '10px 0',
        padding: '10px',
        width: '100%',
        backgroundColor: '#6495ED', // Cornflower Blue
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};


interface AddEmailRequestProps {
    status: undefined,
    enabled: true,
    id: number,
    subject: string,
    text: string,
    to: string,
}

interface PopupProps {
    onClose: () => void;
}


const MessageSendPopup: React.FC<PopupProps> = ({ onClose }) => {
    const {sendEmail } = useClient();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState<boolean>(false);
    const location = useLocation();
    const emailDetails: any = location?.state?.data || {};
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [test, setTest] = useState('');


    const [value, setValue] = useState<AddEmailRequestProps>({

        status: undefined,
        enabled: true,
        id: 0,
        subject: '',
        text: '',
        to: '',
    });

    const handleSubmit = async () => {
        if (value.subject === '') {
            enqueueSnackbar("Please enter a mandatory Subject field", { variant: 'error', autoHideDuration: 3000 });
            return;
        } else if(value.to === '') {
            enqueueSnackbar("Please enter a Email", {variant: 'error', autoHideDuration: 3000})
            return true;
        }
        try {
            const clientId = localStorage.getItem(STORAGE_KEY.UserId);
            await sendEmail({
                id: emailDetails.data.id,
                text: value.text,
                to: value.to,
                subject: value.subject,
            }).then(r => {
                console.log(r);
                if (r?.response && r?.response?.status === 400) {
                    enqueueSnackbar(r?.response?.data, { variant: 'error', autoHideDuration: 3000 });
                } else {
                    window.history.back();
                    enqueueSnackbar(r?.response?.data, { variant: 'success', autoHideDuration: 3000 });
                }
            });
            setOpen(false);
        } catch (e: any) {
            console.log(e);
            setOpen(false);
        }
    };



    const onChange = (event: string, values: string) => {
        setValue({
            ...value,
            [values]: event,
        });
    };

    return (
        <div style={styles.popupContainer}>
            <h2>MessageBox Popup</h2>
            <label>
                Message:
                <textarea
                    name='subject'
                    style={styles.messageInputField}
                    value={value.subject}
                    onChange={(e) => onChange(e.target.value, 'subject')                }
                />
            </label>
            <label>
                Email:
                <input
                    name= 'to'
                    style={styles.inputField}
                    type="email"
                    value={value.to}
                    onChange={(e) => onChange(e.target.value, 'to')                }
                />
            </label>
            <button style={styles.button} onClick={handleSubmit}>
                Submit
            </button>
            <button style={styles.button} onClick={onClose}>
                Cancel
            </button>
        </div>
    );
};

export default MessageSendPopup;
