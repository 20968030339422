import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Stack, TextField } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
};

interface RequestVendorModalProps {
  open: boolean;
  value: {
    description: string;
    amount: string;
  }
  onChangeQuote: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeAmount: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickPrimary: () => void;
  handleClose: () => void;
}

const RequestVendorModal = ({ open, value, onChangeQuote, onChangeAmount, onClickPrimary, handleClose }: RequestVendorModalProps) => {
  return (
    <Modal
      open={ open }
      onClose={ handleClose }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} width={400}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Contact Vendor
        </Typography>
        <TextField multiline={true} minRows={"10"} placeholder="Description" name="quote" value={ value.description } onChange={ onChangeQuote } />
        <Stack direction="row" spacing={ 4 } >
          <Button variant="contained" onClick={ onClickPrimary }>Request</Button>
          <Button variant="outlined" onClick={ handleClose }>Cancel</Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default RequestVendorModal;
